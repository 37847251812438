import { nanoid } from "nanoid";
import { Object3D, PerspectiveCamera, WebGLRenderer } from "three";
import { BaseDocument, IScenePiece, ISceneRoom } from "../core/Document";
import { PieceTypes, Prefab } from "../core/Prefabs";
import { createRenderer } from "../core/Scene";

export class EditorDocument extends BaseDocument {
  public renderer: WebGLRenderer;
  public camera: PerspectiveCamera;
  constructor(public name: string = "untitled") {
    super(name, { gridSize: 16 }, true);
    const [renderer, camera] = createRenderer(640, 480);
    this.renderer = renderer;
    this.camera = camera;
  }

  /** Reset the scene camera to the default */
  public resetCamera(): void {
    this.camera.position.set(20, 35, 20);
    this.camera.zoom = 1.5;
    this.camera.lookAt(0, 0, 0);
    this.events.dispatchEvent({ type: "change", document: this });
  }

  /**
   * Find all the objects that would collide with the given prefab's bounding
   * box, if it were placed at the given grid coordinates.
   */
  findCollisions(prefab: Prefab, gridX: number, gridY: number): Object3D[] {
    const hitPieces = this.pieces.filter((p) => {
      this._collideBox.min.set(gridX, gridY);
      this._collideBox.max.set(gridX + prefab.width, gridY + prefab.height);
      // Don't use intersectBox because edges touching is considered an overlap
      //
      // https://github.com/mrdoob/three.js/issues/9137#issuecomment-226296659
      const i = this._collideBox.intersect(p.box);
      i.getSize(this._collideSize);
      const collide = this._collideSize.x * this._collideSize.y > 0;
      return collide;
    });
    return hitPieces.map((p) => p.instance);
  }

  /**
   * Determine if placing a prefab at a given x/y spot in the grid would
   * be contained within the grid.
   *
   * Returns true if the piece would fit in the grid.
   */
  prefabWillBeInBounds(prefab: Prefab, gridX: number, gridY: number): boolean {
    const maxX = gridX + prefab.width - 1;
    const maxY = gridY + prefab.height - 1;
    return this.pointInGrid(gridX, gridY) && this.pointInGrid(maxX, maxY);
  }

  /**
   * Determine if placing a prefab at a given x/y/width/height in the grid would
   * be contained within the grid.
   *
   * Returns true if the piece would fit in the grid.
   */
  boxWillBeInBounds(
    gridX: number,
    gridY: number,
    gridWidth: number,
    gridHeight: number
  ): boolean {
    const maxX = gridX + gridWidth - 1;
    const maxY = gridY + gridHeight - 1;
    return this.pointInGrid(gridX, gridY) && this.pointInGrid(maxX, maxY);
  }

  /**
   * Determine if placing a prefab at a given x/y/width/height in the grid would
   * overlap with other existing pieces.
   *
   * Returns the pieces that would be overlapped.
   */
  boxCollidesWith(
    gridX: number,
    gridY: number,
    gridWidth: number,
    gridHeight: number
  ): IScenePiece[] {
    return this.pieces.filter((p) => {
      this._collideBox.min.set(gridX, gridY);
      this._collideBox.max.set(gridX + gridWidth, gridY + gridHeight);
      // Don't use intersectBox because edges touching is considered an overlap
      //
      // https://github.com/mrdoob/three.js/issues/9137#issuecomment-226296659
      const i = this._collideBox.intersect(p.box);
      i.getSize(this._collideSize);
      const collide = this._collideSize.x * this._collideSize.y > 0;
      return collide ? p : null;
    });
  }

  /**
   * Determine if placing a prefab at a given x/y spot in the grid would
   * overlap with other existing pieces.
   *
   * Returns the pieces that would be overlapped.
   */
  prefabCollidesWith(
    prefab: Prefab,
    gridX: number,
    gridY: number
  ): IScenePiece[] {
    return this.boxCollidesWith(gridX, gridY, prefab.width, prefab.height);
  }

  /**
   * Make a guess at where room boundaries should be based on the prefabs
   * used in a map. Returns the resulting rooms but does not update the document
   * with them.
   */
  suggestRoomLayout(): ISceneRoom[] {
    const rooms: ISceneRoom[] = [];
    const prefabNames: { [ofType: string]: number } = {};
    for (const piece of this.pieces) {
      // If there are any seats, create a room around it.
      if (piece.seats.children.length > 0) {
        if (prefabNames[piece.prefab.ofType] === undefined) {
          prefabNames[piece.prefab.ofType] = 1;
        } else {
          prefabNames[piece.prefab.ofType] += 1;
        }
        const prefabNum = prefabNames[piece.prefab.ofType];
        const label = this.generateRoomName(piece.prefab.ofType, prefabNum);
        const room: ISceneRoom = {
          id: nanoid(),
          label,
          x: piece.box.min.x,
          y: piece.box.min.y,
          width: piece.prefab.width,
          height: piece.prefab.height,
          fromPieceId: piece.id,
        };
        rooms.push(room);
      }
    }
    return rooms;
  }

  /**
   * Generate a room name based on the room type and how many other instances
   * of the same type already exist.
   */
  public generateRoomName(prefabType: PieceTypes, prefabCount: number): string {
    const spaces = prefabType.split("_");
    const last = spaces.length - 1;
    if (!isNaN(parseInt(spaces[last], 10))) {
      spaces.pop();
    }
    if (prefabCount > 1) {
      spaces.push(`${prefabCount}`);
    }
    spaces[0] = `${spaces[0][0].toUpperCase()}${spaces[0].slice(1)}`;
    return spaces.join(" ");
  }
}
