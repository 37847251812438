import { ISceneSerialized } from "../core/Document";

export const ISLAND_MAP: ISceneSerialized = {
  name: "Island Office",
  args: { backgroundColor: 16764057, gridSize: 32 },
  rooms: [],
  pieces: [
    { prefab: "trees/tree_3", rotation: 0, x: -6, y: -2 },
    { prefab: "grass_tile", rotation: 0, x: 9, y: 3 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -1, y: 3 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 0, y: 3 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 1, y: 3 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 2, y: 3 },
    { prefab: "walls/wall_2_se", rotation: 90, x: -2, y: 8 },
    { prefab: "cactus", rotation: 0, x: 3, y: -4 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: -4 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 6, y: 4 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 7, y: 4 },
    { prefab: "walls/wall_2_sew", rotation: 270, x: 8, y: -1 },
    { prefab: "trees/tree_1", rotation: 0, x: 10, y: 3 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 6, y: -1 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 5, y: -1 },
    { prefab: "cactus", rotation: 0, x: 4, y: 4 },
    { prefab: "conference_1", rotation: 0, x: -1, y: 4 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -2, y: 7 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -2, y: 6 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -2, y: 5 },
    { prefab: "grass_tile", rotation: 0, x: -15, y: -1 },
    { prefab: "trees/tree_2", rotation: 0, x: -15, y: 6 },
    { prefab: "trees/tree_2", rotation: 0, x: -14, y: 10 },
    { prefab: "trees/tree_2", rotation: 0, x: -14, y: 15 },
    { prefab: "trees/tree_2", rotation: 0, x: -12, y: 10 },
    { prefab: "trees/tree_2", rotation: 0, x: -15, y: 13 },
    { prefab: "trees/tree_3", rotation: 0, x: -14, y: 13 },
    { prefab: "trees/tree_3", rotation: 0, x: -11, y: 14 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 12, y: -11 },
    { prefab: "trees/tree_3", rotation: 0, x: 14, y: -16 },
    { prefab: "grass_tile", rotation: 0, x: 15, y: -16 },
    { prefab: "walls/wall_2_se", rotation: 0, x: 12, y: -12 },
    { prefab: "walls/wall_2_se", rotation: 270, x: 12, y: -16 },
    { prefab: "trees/tree_2", rotation: 0, x: 13, y: -16 },
    { prefab: "cactus", rotation: 0, x: 12, y: -15 },
    { prefab: "floor_tile", rotation: 0, x: 12, y: -14 },
    { prefab: "floor_tile", rotation: 0, x: 12, y: -13 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 1, y: 11 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 0, y: 11 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -1, y: 11 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -2, y: 11 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -3, y: 11 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -4, y: 11 },
    { prefab: "walls/wall_2_sew", rotation: 90, x: 2, y: 9 },
    { prefab: "walls/wall_2_se", rotation: 90, x: -5, y: 11 },
    { prefab: "walls/wall_2_sew", rotation: 0, x: 2, y: -5 },
    { prefab: "lounge", rotation: 0, x: 0, y: 9 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 5, y: 3 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 5, y: 2 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 5, y: 1 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 7, y: -1 },
    { prefab: "cactus", rotation: 0, x: -1, y: 10 },
    { prefab: "grass_tile", rotation: 0, x: -14, y: -1 },
    { prefab: "bushes_2", rotation: 0, x: -9, y: 0 },
    { prefab: "bushes", rotation: 0, x: -7, y: 1 },
    { prefab: "bushes", rotation: 0, x: -16, y: -1 },
    { prefab: "grass_tile", rotation: 0, x: -14, y: -16 },
    { prefab: "grass_tile", rotation: 0, x: -16, y: -12 },
    { prefab: "grass_tile", rotation: 0, x: -16, y: -16 },
    { prefab: "grass_tile", rotation: 0, x: -10, y: -16 },
    { prefab: "grass_tile", rotation: 0, x: -9, y: -16 },
    { prefab: "grass_tile", rotation: 0, x: -8, y: -16 },
    { prefab: "grass_tile", rotation: 0, x: 15, y: -11 },
    { prefab: "trees/tree_2", rotation: 0, x: -14, y: 11 },
    { prefab: "walls/wall_2_se", rotation: 0, x: -2, y: 3 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -1, y: -5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -2, y: -5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -3, y: -5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -4, y: -5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 0, y: -5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 1, y: -5 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -5, y: -4 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -5, y: -3 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -5, y: -2 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -5, y: -1 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -5, y: 0 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -5, y: 1 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -5, y: 2 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -5, y: 7 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -5, y: 8 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -5, y: 9 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -5, y: 10 },
    { prefab: "bushes", rotation: 90, x: 9, y: 1 },
    { prefab: "walls/wall_2_se", rotation: 270, x: 8, y: -5 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 8, y: -3 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 8, y: -4 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 8, y: -2 },
    { prefab: "bushes", rotation: 0, x: 9, y: -1 },
    { prefab: "bushes_3", rotation: 0, x: 9, y: 0 },
    { prefab: "trees/tree_1", rotation: 90, x: 9, y: 2 },
    { prefab: "walls/wall_2_se", rotation: 270, x: -5, y: 6 },
    { prefab: "trees/tree_2", rotation: 0, x: -12, y: 12 },
    { prefab: "trees/tree_3", rotation: 0, x: -12, y: 15 },
    { prefab: "lounge", rotation: 90, x: 6, y: 2 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 2, y: -3 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 2, y: -4 },
    { prefab: "trees/tree_3", rotation: 0, x: -15, y: 10 },
    { prefab: "trees/tree_1", rotation: 0, x: 4, y: -13 },
    { prefab: "trees/tree_1", rotation: 90, x: 4, y: -14 },
    { prefab: "trees/tree_1", rotation: 270, x: 4, y: -16 },
    { prefab: "trees/tree_1", rotation: 0, x: 3, y: -15 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 5, y: -15 },
    { prefab: "walls/wall_2_se", rotation: 0, x: 5, y: -16 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 4, y: -5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 3, y: -5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 5, y: -5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 6, y: -5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 7, y: -5 },
    { prefab: "water_tile", rotation: 0, x: -13, y: -3 },
    { prefab: "water_tile", rotation: 0, x: -13, y: -4 },
    { prefab: "water_tile", rotation: 0, x: -13, y: -5 },
    { prefab: "water_tile", rotation: 0, x: -14, y: -5 },
    { prefab: "water_tile", rotation: 0, x: -14, y: -4 },
    { prefab: "water_tile", rotation: 0, x: -14, y: -3 },
    { prefab: "water_tile", rotation: 0, x: -15, y: -3 },
    { prefab: "water_tile", rotation: 0, x: -15, y: -4 },
    { prefab: "water_tile", rotation: 0, x: -15, y: -5 },
    { prefab: "water_tile", rotation: 0, x: -16, y: -5 },
    { prefab: "water_tile", rotation: 0, x: -16, y: -4 },
    { prefab: "water_tile", rotation: 0, x: -16, y: -3 },
    { prefab: "trees/tree_1", rotation: 0, x: -14, y: -7 },
    { prefab: "trees/tree_3", rotation: 0, x: -6, y: -13 },
    { prefab: "trees/tree_3", rotation: 0, x: -6, y: -14 },
    { prefab: "trees/tree_3", rotation: 0, x: -6, y: -16 },
    { prefab: "trees/tree_3", rotation: 0, x: -7, y: -16 },
    { prefab: "trees/tree_3", rotation: 0, x: -5, y: -14 },
    { prefab: "trees/tree_3", rotation: 0, x: -5, y: -12 },
    { prefab: "trees/tree_3", rotation: 0, x: -4, y: -13 },
    { prefab: "trees/tree_3", rotation: 0, x: -4, y: -14 },
    { prefab: "trees/tree_3", rotation: 0, x: -4, y: -15 },
    { prefab: "trees/tree_3", rotation: 0, x: -6, y: -11 },
    { prefab: "trees/tree_3", rotation: 0, x: -12, y: -16 },
    { prefab: "trees/tree_3", rotation: 0, x: -13, y: -16 },
    { prefab: "trees/tree_3", rotation: 0, x: -15, y: -16 },
    { prefab: "trees/tree_3", rotation: 0, x: -16, y: -15 },
    { prefab: "trees/tree_3", rotation: 0, x: -16, y: -14 },
    { prefab: "trees/tree_3", rotation: 0, x: -16, y: -13 },
    { prefab: "trees/tree_3", rotation: 0, x: -15, y: -7 },
    { prefab: "trees/tree_3", rotation: 0, x: -16, y: -8 },
    { prefab: "trees/tree_3", rotation: 0, x: -16, y: -9 },
    { prefab: "trees/tree_3", rotation: 0, x: -16, y: -11 },
    { prefab: "trees/tree_3", rotation: 0, x: -11, y: -16 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 7, y: -16 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 6, y: -16 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 8, y: -16 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 9, y: -16 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 10, y: -16 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 11, y: -16 },
    { prefab: "grass_tile", rotation: 0, x: 4, y: -12 },
    { prefab: "grass_tile", rotation: 0, x: 3, y: -13 },
    { prefab: "grass_tile", rotation: 0, x: 3, y: -14 },
    { prefab: "grass_tile", rotation: 0, x: 3, y: -16 },
    { prefab: "grass_tile", rotation: 0, x: 10, y: 2 },
    { prefab: "grass_tile", rotation: 0, x: 1, y: 12 },
    { prefab: "grass_tile", rotation: 0, x: -9, y: 6 },
    { prefab: "walls/wall_2_se", rotation: 0, x: -5, y: -5 },
    { prefab: "water_tile", rotation: 0, x: -16, y: -2 },
    { prefab: "water_tile", rotation: 0, x: -15, y: -2 },
    { prefab: "water_tile", rotation: 0, x: -14, y: -2 },
    { prefab: "grass_tile", rotation: 0, x: -13, y: -7 },
    { prefab: "grass_tile", rotation: 0, x: -14, y: -6 },
    { prefab: "grass_tile", rotation: 0, x: -15, y: -6 },
    { prefab: "grass_tile", rotation: 0, x: -16, y: -6 },
    { prefab: "grass_tile", rotation: 0, x: -16, y: -7 },
    { prefab: "grass_tile", rotation: 0, x: -6, y: -9 },
    { prefab: "grass_tile", rotation: 0, x: -6, y: -10 },
    { prefab: "grass_tile", rotation: 0, x: -5, y: -10 },
    { prefab: "grass_tile", rotation: 0, x: -5, y: -11 },
    { prefab: "grass_tile", rotation: 0, x: -4, y: -11 },
    { prefab: "grass_tile", rotation: 0, x: -4, y: -12 },
    { prefab: "grass_tile", rotation: 0, x: -4, y: -16 },
    { prefab: "grass_tile", rotation: 0, x: -5, y: -16 },
    { prefab: "walls/wall_2_se", rotation: 270, x: 2, y: 8 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 1, y: 8 },
    { prefab: "floor_tile", rotation: 0, x: -2, y: 9 },
    { prefab: "floor_tile", rotation: 0, x: -2, y: 10 },
    { prefab: "floor_tile", rotation: 0, x: -1, y: 9 },
    { prefab: "floor_tile", rotation: 0, x: -1, y: 8 },
    { prefab: "floor_tile", rotation: 0, x: 0, y: 8 },
    { prefab: "lounge", rotation: 90, x: -4, y: 9 },
    { prefab: "grass_tile", rotation: 0, x: -12, y: 9 },
    { prefab: "grass_tile", rotation: 0, x: -13, y: 9 },
    { prefab: "grass_tile", rotation: 0, x: -13, y: 10 },
    { prefab: "grass_tile", rotation: 0, x: -13, y: 12 },
    { prefab: "grass_tile", rotation: 0, x: -13, y: 15 },
    { prefab: "grass_tile", rotation: 0, x: -15, y: 15 },
    { prefab: "grass_tile", rotation: 0, x: -16, y: 15 },
    { prefab: "grass_tile", rotation: 0, x: -16, y: 13 },
    { prefab: "grass_tile", rotation: 0, x: -16, y: 11 },
    { prefab: "grass_tile", rotation: 0, x: -16, y: 10 },
    { prefab: "grass_tile", rotation: 0, x: -16, y: 9 },
    { prefab: "grass_tile", rotation: 0, x: -15, y: 9 },
    { prefab: "grass_tile", rotation: 0, x: -14, y: 9 },
    { prefab: "grass_tile", rotation: 0, x: -15, y: 12 },
    { prefab: "grass_tile", rotation: 0, x: -14, y: 12 },
    { prefab: "grass_tile", rotation: 0, x: -12, y: 14 },
    { prefab: "grass_tile", rotation: 0, x: -12, y: 13 },
    { prefab: "grass_tile", rotation: 0, x: -12, y: 11 },
    { prefab: "grass_tile", rotation: 0, x: -14, y: 4 },
    { prefab: "grass_tile", rotation: 0, x: -15, y: 2 },
    { prefab: "grass_tile", rotation: 0, x: -16, y: 3 },
    { prefab: "grass_tile", rotation: 0, x: -16, y: 4 },
    { prefab: "grass_tile", rotation: 0, x: -16, y: 5 },
    { prefab: "grass_tile", rotation: 0, x: -16, y: 6 },
    { prefab: "trees/tree_3", rotation: 0, x: -15, y: 3 },
    { prefab: "grass_to_water_tile", rotation: 180, x: -8, y: -3 },
    { prefab: "grass_to_water_tile", rotation: 0, x: -5, y: -9 },
    { prefab: "grass_to_water_tile", rotation: 0, x: -4, y: -10 },
    { prefab: "grass_to_water_tile", rotation: 270, x: 7, y: -8 },
    { prefab: "grass_to_water_tile", rotation: 90, x: -11, y: 12 },
    { prefab: "grass_to_water_tile", rotation: 90, x: -10, y: 15 },
    { prefab: "grass_to_water_tile", rotation: 90, x: 10, y: 1 },
    { prefab: "grass_to_water_tile", rotation: 270, x: 12, y: -4 },
    { prefab: "grass_to_water_tile", rotation: 270, x: 11, y: -7 },
    { prefab: "grass_to_water_tile", rotation: 180, x: -5, y: -6 },
    { prefab: "grass_to_water_tile", rotation: 180, x: -6, y: -5 },
    { prefab: "grass_tile", rotation: 0, x: 9, y: -2 },
    { prefab: "grass_tile", rotation: 0, x: 9, y: -3 },
    { prefab: "grass_tile", rotation: 0, x: 9, y: -4 },
    { prefab: "grass_tile", rotation: 0, x: 9, y: -5 },
    { prefab: "grass_tile", rotation: 0, x: -6, y: 7 },
    { prefab: "grass_tile", rotation: 0, x: -6, y: 8 },
    { prefab: "grass_tile", rotation: 0, x: -6, y: 9 },
    { prefab: "grass_tile", rotation: 0, x: -6, y: 10 },
    { prefab: "grass_tile", rotation: 0, x: -6, y: 11 },
    { prefab: "grass_tile", rotation: 0, x: -5, y: 12 },
    { prefab: "grass_tile", rotation: 0, x: -4, y: 12 },
    { prefab: "grass_tile", rotation: 0, x: -3, y: 12 },
    { prefab: "grass_tile", rotation: 0, x: -2, y: 12 },
    { prefab: "grass_tile", rotation: 0, x: -1, y: 12 },
    { prefab: "grass_tile", rotation: 0, x: 0, y: 12 },
    { prefab: "grass_to_water_tile", rotation: 270, x: -6, y: 12 },
    { prefab: "grass_to_water_tile", rotation: 90, x: 9, y: -6 },
    { prefab: "dirt_to_grass_tile_2", rotation: 90, x: -10, y: 2 },
    { prefab: "dirt_to_grass_tile_2", rotation: 270, x: -14, y: 2 },
    { prefab: "dirt_to_grass_tile_2", rotation: 270, x: -13, y: 3 },
    { prefab: "dirt_to_grass_tile_2", rotation: 180, x: -13, y: 5 },
    { prefab: "dirt_to_grass_tile_2", rotation: 180, x: -14, y: 6 },
    { prefab: "dirt_to_grass_tile_2", rotation: 0, x: -12, y: 8 },
    { prefab: "dirt_to_grass_tile_2", rotation: 90, x: -9, y: 3 },
    { prefab: "trees/tree_1", rotation: 0, x: -9, y: 1 },
    { prefab: "trees/tree_1", rotation: 0, x: -15, y: 5 },
    { prefab: "trees/tree_2", rotation: 0, x: -15, y: 4 },
    { prefab: "trees/tree_2", rotation: 0, x: -14, y: 5 },
    { prefab: "trees/tree_2", rotation: 0, x: -14, y: 3 },
    { prefab: "trees/tree_2", rotation: 0, x: -16, y: 2 },
    { prefab: "trees/tree_3", rotation: 0, x: -13, y: 11 },
    { prefab: "trees/tree_3", rotation: 0, x: -13, y: 13 },
    { prefab: "trees/tree_3", rotation: 0, x: -15, y: 11 },
    { prefab: "trees/tree_3", rotation: 0, x: -16, y: 12 },
    { prefab: "trees/tree_3", rotation: 0, x: -16, y: 14 },
    { prefab: "trees/tree_3", rotation: 0, x: -11, y: 15 },
    { prefab: "trees/tree_3", rotation: 0, x: -13, y: 14 },
    { prefab: "grass_to_water_tile", rotation: 180, x: -7, y: -4 },
    { prefab: "grass_to_water_tile", rotation: 270, x: -7, y: 7 },
    { prefab: "walls/wall_2_s_to_e", rotation: 0, x: 5, y: 4 },
    { prefab: "walls/wall_2_s_to_e", rotation: 0, x: 4, y: 5 },
    { prefab: "ls_3", rotation: 0, x: -14, y: -14 },
    { prefab: "walls/wall_2_se", rotation: 0, x: -15, y: -15 },
    { prefab: "walls/wall_2_se", rotation: 270, x: -7, y: -15 },
    { prefab: "walls/wall_2_se", rotation: 180, x: -7, y: -8 },
    { prefab: "walls/wall_2_se", rotation: 90, x: -15, y: -8 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -14, y: -15 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -13, y: -15 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -12, y: -15 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -11, y: -15 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -10, y: -15 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -9, y: -15 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -8, y: -15 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -7, y: -14 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -7, y: -13 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -7, y: -12 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -7, y: -11 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -7, y: -10 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -7, y: -9 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -8, y: -8 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -9, y: -8 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -10, y: -8 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -14, y: -8 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -13, y: -8 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -15, y: -9 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -15, y: -10 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -15, y: -11 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -15, y: -12 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -15, y: -13 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -15, y: -14 },
    { prefab: "floor_tile", rotation: 0, x: -8, y: -13 },
    { prefab: "floor_tile", rotation: 0, x: -8, y: -12 },
    { prefab: "floor_tile", rotation: 0, x: -8, y: -11 },
    { prefab: "floor_tile", rotation: 0, x: -8, y: -10 },
    { prefab: "floor_tile", rotation: 0, x: -12, y: -8 },
    { prefab: "floor_tile", rotation: 0, x: -11, y: -8 },
    { prefab: "grass_to_water_tile", rotation: 180, x: -13, y: -2 },
    { prefab: "dirt_to_grass_tile_2", rotation: 180, x: -13, y: -1 },
    { prefab: "grass_to_water_tile", rotation: 0, x: -13, y: -6 },
    { prefab: "cactus", rotation: 0, x: -8, y: -14 },
    { prefab: "cactus", rotation: 0, x: -8, y: -9 },
    { prefab: "ls_1", rotation: 0, x: -4, y: -4 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 5, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 0 },
    { prefab: "grass_tile", rotation: 0, x: -5, y: -15 },
    { prefab: "grass_tile", rotation: 0, x: -5, y: -13 },
    { prefab: "grass_tile", rotation: 0, x: -6, y: -12 },
    { prefab: "grass_tile", rotation: 0, x: -6, y: -15 },
    { prefab: "grass_tile", rotation: 0, x: -16, y: -10 },
    { prefab: "trees/tree_2", rotation: 0, x: -6, y: -1 },
    { prefab: "trees/tree_3", rotation: 0, x: -7, y: -1 },
    { prefab: "grass_tile", rotation: 0, x: -11, y: 13 },
    { prefab: "grass_tile", rotation: 0, x: -14, y: 14 },
    { prefab: "grass_tile", rotation: 0, x: -15, y: 14 },
    { prefab: "grass_tile", rotation: 0, x: 15, y: -10 },
    { prefab: "grass_tile", rotation: 0, x: 14, y: -10 },
    { prefab: "grass_tile", rotation: 0, x: 4, y: -15 },
    { prefab: "workstation_1", rotation: 0, x: 5, y: -4 },
    { prefab: "floor_tile", rotation: 0, x: -5, y: 4 },
    { prefab: "floor_tile", rotation: 0, x: -5, y: 5 },
    { prefab: "floor_tile", rotation: 0, x: -4, y: 4 },
    { prefab: "floor_tile", rotation: 0, x: -3, y: 4 },
    { prefab: "floor_tile", rotation: 0, x: -3, y: 8 },
    { prefab: "floor_tile", rotation: 0, x: -4, y: 8 },
    { prefab: "floor_tile", rotation: 0, x: -2, y: 2 },
    { prefab: "floor_tile", rotation: 0, x: -1, y: 2 },
    { prefab: "floor_tile", rotation: 0, x: 0, y: 2 },
    { prefab: "floor_tile", rotation: 0, x: 1, y: 2 },
    { prefab: "floor_tile", rotation: 0, x: 2, y: 2 },
    { prefab: "floor_tile", rotation: 0, x: 2, y: 1 },
    { prefab: "floor_tile", rotation: 0, x: 2, y: 0 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: -1 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: 0 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: 1 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: 2 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: 4 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: 5 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: 6 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: 7 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: 2 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: 1 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: 0 },
    { prefab: "floor_tile", rotation: 0, x: 5, y: 0 },
    { prefab: "floor_tile", rotation: 0, x: 6, y: 0 },
    { prefab: "floor_tile", rotation: 0, x: 6, y: 1 },
    { prefab: "floor_tile", rotation: 0, x: 7, y: 1 },
    { prefab: "walls/wall_2_se", rotation: 180, x: -5, y: 3 },
    { prefab: "entrance", rotation: 180, x: -8, y: 3 },
    { prefab: "trees/tree_2", rotation: 0, x: -13, y: 4 },
    { prefab: "grass_to_water_tile", rotation: 0, x: -11, y: 8 },
    { prefab: "dirt_to_grass_tile_2", rotation: 0, x: -11, y: 7 },
    { prefab: "grass_to_water_tile", rotation: 0, x: -10, y: 7 },
    { prefab: "dirt_to_grass_tile_2", rotation: 0, x: -10, y: 6 },
    { prefab: "dirt_tile_2", rotation: 0, x: -11, y: 6 },
    { prefab: "dirt_tile_2", rotation: 0, x: -11, y: 5 },
    { prefab: "dirt_tile_2", rotation: 0, x: -11, y: 4 },
    { prefab: "dirt_tile_2", rotation: 0, x: -11, y: 3 },
    { prefab: "dirt_tile_2", rotation: 0, x: -11, y: 2 },
    { prefab: "dirt_tile_2", rotation: 0, x: -11, y: 1 },
    { prefab: "dirt_tile_2", rotation: 0, x: -11, y: 0 },
    { prefab: "dirt_tile_2", rotation: 0, x: -11, y: -1 },
    { prefab: "dirt_tile_2", rotation: 0, x: -11, y: -2 },
    { prefab: "dirt_tile_2", rotation: 0, x: -11, y: -3 },
    { prefab: "dirt_tile_2", rotation: 0, x: -11, y: -4 },
    { prefab: "dirt_tile_2", rotation: 0, x: -11, y: -5 },
    { prefab: "dirt_tile_2", rotation: 0, x: -11, y: -6 },
    { prefab: "dirt_tile_2", rotation: 0, x: -11, y: -7 },
    { prefab: "dirt_tile_2", rotation: 0, x: -12, y: -7 },
    { prefab: "dirt_tile_2", rotation: 0, x: -12, y: -6 },
    { prefab: "dirt_tile_2", rotation: 0, x: -12, y: -5 },
    { prefab: "dirt_tile_2", rotation: 0, x: -12, y: -4 },
    { prefab: "dirt_tile_2", rotation: 0, x: -12, y: -3 },
    { prefab: "dirt_tile_2", rotation: 0, x: -12, y: -2 },
    { prefab: "dirt_tile_2", rotation: 0, x: -12, y: -1 },
    { prefab: "dirt_tile_2", rotation: 0, x: -12, y: 0 },
    { prefab: "dirt_tile_2", rotation: 0, x: -12, y: 1 },
    { prefab: "dirt_tile_2", rotation: 0, x: -12, y: 2 },
    { prefab: "dirt_tile_2", rotation: 0, x: -12, y: 3 },
    { prefab: "dirt_tile_2", rotation: 0, x: -12, y: 4 },
    { prefab: "dirt_tile_2", rotation: 0, x: -12, y: 5 },
    { prefab: "dirt_tile_2", rotation: 0, x: -12, y: 6 },
    { prefab: "dirt_tile_2", rotation: 0, x: -12, y: 7 },
    { prefab: "dirt_tile_2", rotation: 0, x: -13, y: 7 },
    { prefab: "dirt_tile_2", rotation: 0, x: -13, y: 6 },
    { prefab: "dirt_tile_2", rotation: 0, x: -13, y: 8 },
    { prefab: "dirt_tile_2", rotation: 0, x: -14, y: 8 },
    { prefab: "dirt_tile_2", rotation: 0, x: -14, y: 7 },
    { prefab: "dirt_tile_2", rotation: 0, x: -15, y: 7 },
    { prefab: "dirt_tile_2", rotation: 0, x: -15, y: 8 },
    { prefab: "dirt_tile_2", rotation: 0, x: -16, y: 8 },
    { prefab: "dirt_tile_2", rotation: 0, x: -16, y: 7 },
    { prefab: "dirt_tile_2", rotation: 0, x: -13, y: 2 },
    { prefab: "dirt_tile_2", rotation: 0, x: -13, y: 1 },
    { prefab: "dirt_tile_2", rotation: 0, x: -13, y: 0 },
    { prefab: "dirt_tile_2", rotation: 0, x: -14, y: 0 },
    { prefab: "dirt_tile_2", rotation: 0, x: -14, y: 1 },
    { prefab: "dirt_tile_2", rotation: 0, x: -15, y: 1 },
    { prefab: "dirt_tile_2", rotation: 0, x: -15, y: 0 },
    { prefab: "dirt_tile_2", rotation: 0, x: -16, y: 0 },
    { prefab: "dirt_tile_2", rotation: 0, x: -16, y: 1 },
    { prefab: "dirt_tile_2", rotation: 0, x: -10, y: 3 },
    { prefab: "dirt_tile_2", rotation: 0, x: -10, y: 4 },
    { prefab: "dirt_tile_2", rotation: 0, x: -10, y: 5 },
    { prefab: "dirt_tile_2", rotation: 0, x: -9, y: 5 },
    { prefab: "dirt_tile_2", rotation: 0, x: -9, y: 4 },
    { prefab: "walls/wall_2_se", rotation: 180, x: 12, y: -10 },
    { prefab: "trees/tree_1", rotation: 90, x: 10, y: -9 },
    { prefab: "grass_to_water_tile", rotation: 270, x: 6, y: -9 },
    { prefab: "walls/wall_2_se", rotation: 90, x: 5, y: -13 },
    { prefab: "grass_tile", rotation: 0, x: 5, y: -11 },
    { prefab: "grass_to_water_tile", rotation: 270, x: 5, y: -10 },
    { prefab: "floor_to_grass_tile", rotation: 270, x: 5, y: -12 },
    { prefab: "floor_tile", rotation: 0, x: 8, y: -14 },
    { prefab: "floor_tile", rotation: 0, x: 8, y: -15 },
    { prefab: "workstation_1", rotation: 0, x: 9, y: -15 },
    { prefab: "walls/wall_2_se", rotation: 90, x: 8, y: -10 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 11, y: -10 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 10, y: -10 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 9, y: -10 },
    { prefab: "grass_tile", rotation: 0, x: 6, y: -10 },
    { prefab: "floor_to_grass_tile", rotation: 270, x: 6, y: -11 },
    { prefab: "floor_to_grass_tile", rotation: 270, x: 7, y: -10 },
    { prefab: "arcade", rotation: 0, x: 6, y: -15 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 8, y: -12 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 7, y: -13 },
    { prefab: "cactus", rotation: 0, x: 7, y: -11 },
    { prefab: "cactus", rotation: 0, x: 6, y: -12 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 8, y: -11 },
    { prefab: "cactus", rotation: 0, x: 7, y: -12 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 6, y: -13 },
    { prefab: "walls/wall_2_se", rotation: 90, x: 3, y: 15 },
    { prefab: "walls/wall_2_se", rotation: 180, x: 12, y: 15 },
    { prefab: "walls/wall_2_se", rotation: 0, x: 12, y: 14 },
    { prefab: "walls/wall_2_se", rotation: 90, x: 12, y: 11 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 12, y: 10 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 12, y: 9 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 12, y: 8 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 12, y: 7 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 12, y: 6 },
    { prefab: "walls/wall_2_se", rotation: 270, x: 12, y: 5 },
    { prefab: "walls/wall_2_sew", rotation: 270, x: 8, y: 4 },
    { prefab: "entrance", rotation: 0, x: 13, y: 11 },
    { prefab: "dirt_tile_2", rotation: 0, x: 15, y: 15 },
    { prefab: "dirt_tile_2", rotation: 0, x: 14, y: 15 },
    { prefab: "dirt_tile_2", rotation: 0, x: 13, y: 15 },
    { prefab: "grass_tile", rotation: 0, x: -9, y: 2 },
    { prefab: "grass_tile", rotation: 0, x: -8, y: 2 },
    { prefab: "grass_tile", rotation: 0, x: -8, y: 1 },
    { prefab: "grass_tile", rotation: 0, x: -8, y: 0 },
    { prefab: "grass_tile", rotation: 0, x: -8, y: -1 },
    { prefab: "grass_tile", rotation: 0, x: -8, y: -2 },
    { prefab: "grass_tile", rotation: 0, x: -9, y: -2 },
    { prefab: "grass_tile", rotation: 0, x: -9, y: -1 },
    { prefab: "grass_tile", rotation: 0, x: -10, y: -1 },
    { prefab: "grass_tile", rotation: 0, x: -10, y: -2 },
    { prefab: "grass_tile", rotation: 0, x: -10, y: 0 },
    { prefab: "grass_tile", rotation: 0, x: -10, y: 1 },
    { prefab: "grass_tile", rotation: 0, x: -7, y: -2 },
    { prefab: "grass_tile", rotation: 0, x: -7, y: -3 },
    { prefab: "grass_tile", rotation: 0, x: -6, y: -3 },
    { prefab: "grass_tile", rotation: 0, x: -6, y: -4 },
    { prefab: "grass_tile", rotation: 0, x: -7, y: 0 },
    { prefab: "grass_tile", rotation: 0, x: -6, y: 0 },
    { prefab: "grass_tile", rotation: 0, x: -6, y: 1 },
    { prefab: "grass_tile", rotation: 0, x: -6, y: 2 },
    { prefab: "grass_tile", rotation: 0, x: -7, y: 2 },
    { prefab: "grass_tile", rotation: 0, x: 1, y: -6 },
    { prefab: "grass_tile", rotation: 0, x: 0, y: -6 },
    { prefab: "grass_tile", rotation: 0, x: -1, y: -6 },
    { prefab: "grass_tile", rotation: 0, x: -2, y: -6 },
    { prefab: "grass_tile", rotation: 0, x: -3, y: -6 },
    { prefab: "grass_tile", rotation: 0, x: -4, y: -6 },
    { prefab: "grass_tile", rotation: 0, x: 2, y: -6 },
    { prefab: "grass_tile", rotation: 0, x: 3, y: -6 },
    { prefab: "grass_tile", rotation: 0, x: 4, y: -6 },
    { prefab: "grass_tile", rotation: 0, x: 5, y: -6 },
    { prefab: "grass_tile", rotation: 0, x: 6, y: -6 },
    { prefab: "grass_tile", rotation: 0, x: 7, y: -6 },
    { prefab: "grass_tile", rotation: 0, x: 8, y: -6 },
    { prefab: "walls/wall_2_se", rotation: 270, x: 8, y: -13 },
    { prefab: "entrance", rotation: 0, x: 13, y: -15 },
    { prefab: "trees/tree_1", rotation: 180, x: 13, y: -9 },
    { prefab: "trees/tree_1", rotation: 0, x: 13, y: -10 },
    { prefab: "trees/tree_1", rotation: 90, x: 13, y: -11 },
    { prefab: "trees/tree_1", rotation: 90, x: 14, y: -11 },
    { prefab: "trees/tree_1", rotation: 0, x: 12, y: -9 },
    { prefab: "trees/tree_1", rotation: 180, x: 14, y: -9 },
    { prefab: "trees/tree_1", rotation: 90, x: 14, y: -8 },
    { prefab: "trees/tree_1", rotation: 90, x: 15, y: -9 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 8, y: 2 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 8, y: 1 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 8, y: 0 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 8, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: 9, y: 6 },
    { prefab: "floor_tile", rotation: 0, x: 10, y: 6 },
    { prefab: "floor_tile", rotation: 0, x: 11, y: 7 },
    { prefab: "floor_tile", rotation: 0, x: 11, y: 8 },
    { prefab: "floor_tile", rotation: 0, x: 11, y: 9 },
    { prefab: "floor_tile", rotation: 0, x: 11, y: 11 },
    { prefab: "floor_tile", rotation: 0, x: 11, y: 12 },
    { prefab: "floor_tile", rotation: 0, x: 9, y: 13 },
    { prefab: "floor_tile", rotation: 0, x: 9, y: 14 },
    { prefab: "floor_tile", rotation: 0, x: 12, y: 13 },
    { prefab: "floor_tile", rotation: 0, x: 12, y: 12 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 6, y: 15 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 5, y: 15 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 4, y: 15 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 7, y: 15 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 8, y: 15 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 9, y: 15 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 10, y: 15 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 11, y: 15 },
    { prefab: "floor_tile", rotation: 0, x: 6, y: 14 },
    { prefab: "floor_tile", rotation: 0, x: 6, y: 13 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: 12 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: 11 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: 10 },
    { prefab: "walls/wall_2_se", rotation: 180, x: 4, y: 9 },
    { prefab: "floor_tile", rotation: 0, x: 5, y: 6 },
    { prefab: "floor_tile", rotation: 0, x: 6, y: 6 },
    { prefab: "floor_tile", rotation: 0, x: 6, y: 5 },
    { prefab: "floor_tile", rotation: 0, x: 7, y: 6 },
    { prefab: "walls/wall_2_se", rotation: 90, x: 8, y: 5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 9, y: 5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 10, y: 5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 11, y: 5 },
    { prefab: "bushes", rotation: 180, x: 9, y: 4 },
    { prefab: "grass_to_water_tile", rotation: 0, x: 10, y: 4 },
    { prefab: "grass_to_water_tile", rotation: 270, x: 15, y: -3 },
    { prefab: "grass_tile", rotation: 0, x: 15, y: -5 },
    { prefab: "grass_tile", rotation: 0, x: 15, y: -4 },
    { prefab: "grass_tile", rotation: 0, x: 14, y: -4 },
    { prefab: "grass_tile", rotation: 0, x: 13, y: -4 },
    { prefab: "grass_tile", rotation: 0, x: 13, y: -5 },
    { prefab: "grass_tile", rotation: 0, x: 12, y: -5 },
    { prefab: "grass_tile", rotation: 0, x: 12, y: -6 },
    { prefab: "grass_tile", rotation: 0, x: 12, y: -7 },
    { prefab: "grass_tile", rotation: 0, x: 12, y: -8 },
    { prefab: "grass_tile", rotation: 0, x: 11, y: -8 },
    { prefab: "grass_tile", rotation: 0, x: 11, y: -9 },
    { prefab: "grass_tile", rotation: 0, x: 10, y: -8 },
    { prefab: "grass_tile", rotation: 0, x: 9, y: -8 },
    { prefab: "grass_tile", rotation: 0, x: 9, y: -9 },
    { prefab: "grass_tile", rotation: 0, x: 8, y: -9 },
    { prefab: "grass_tile", rotation: 0, x: 8, y: -8 },
    { prefab: "grass_tile", rotation: 0, x: 7, y: -9 },
    { prefab: "grass_tile", rotation: 0, x: 13, y: -8 },
    { prefab: "grass_tile", rotation: 0, x: 15, y: -8 },
    { prefab: "grass_tile", rotation: 0, x: 14, y: -7 },
    { prefab: "trees/tree_1", rotation: 0, x: 14, y: -5 },
    { prefab: "trees/tree_1", rotation: 180, x: 14, y: -6 },
    { prefab: "trees/tree_1", rotation: 0, x: 13, y: -6 },
    { prefab: "trees/tree_1", rotation: 90, x: 13, y: -7 },
    { prefab: "trees/tree_1", rotation: 0, x: 15, y: -6 },
    { prefab: "trees/tree_1", rotation: 0, x: 15, y: -7 },
    { prefab: "water_tile", rotation: 0, x: 13, y: 10 },
    { prefab: "water_tile", rotation: 0, x: 13, y: 9 },
    { prefab: "water_tile", rotation: 0, x: 13, y: 8 },
    { prefab: "water_tile", rotation: 0, x: 13, y: 7 },
    { prefab: "water_tile", rotation: 0, x: 13, y: 6 },
    { prefab: "water_tile", rotation: 0, x: 13, y: 5 },
    { prefab: "water_tile", rotation: 0, x: 13, y: 4 },
    { prefab: "water_tile", rotation: 0, x: 13, y: 3 },
    { prefab: "water_tile", rotation: 0, x: 13, y: 2 },
    { prefab: "water_tile", rotation: 0, x: 13, y: 1 },
    { prefab: "water_tile", rotation: 0, x: 13, y: 0 },
    { prefab: "water_tile", rotation: 0, x: 13, y: -1 },
    { prefab: "water_tile", rotation: 0, x: 13, y: -2 },
    { prefab: "water_tile", rotation: 0, x: 13, y: -3 },
    { prefab: "water_tile", rotation: 0, x: 12, y: -3 },
    { prefab: "water_tile", rotation: 0, x: 12, y: -2 },
    { prefab: "water_tile", rotation: 0, x: 12, y: -1 },
    { prefab: "water_tile", rotation: 0, x: 12, y: 0 },
    { prefab: "water_tile", rotation: 0, x: 12, y: 1 },
    { prefab: "water_tile", rotation: 0, x: 12, y: 2 },
    { prefab: "water_tile", rotation: 0, x: 12, y: 3 },
    { prefab: "water_tile", rotation: 0, x: 12, y: 4 },
    { prefab: "water_tile", rotation: 0, x: 11, y: 4 },
    { prefab: "water_tile", rotation: 0, x: 11, y: 3 },
    { prefab: "water_tile", rotation: 0, x: 11, y: 2 },
    { prefab: "water_tile", rotation: 0, x: 11, y: 1 },
    { prefab: "water_tile", rotation: 0, x: 11, y: 0 },
    { prefab: "water_tile", rotation: 0, x: 11, y: -1 },
    { prefab: "water_tile", rotation: 0, x: 11, y: -2 },
    { prefab: "water_tile", rotation: 0, x: 11, y: -3 },
    { prefab: "water_tile", rotation: 0, x: 11, y: -4 },
    { prefab: "water_tile", rotation: 0, x: 11, y: -5 },
    { prefab: "water_tile", rotation: 0, x: 11, y: -6 },
    { prefab: "water_tile", rotation: 0, x: 10, y: -6 },
    { prefab: "water_tile", rotation: 0, x: 10, y: -7 },
    { prefab: "water_tile", rotation: 0, x: 9, y: -7 },
    { prefab: "water_tile", rotation: 0, x: 8, y: -7 },
    { prefab: "water_tile", rotation: 0, x: 7, y: -7 },
    { prefab: "water_tile", rotation: 0, x: 6, y: -7 },
    { prefab: "water_tile", rotation: 0, x: 6, y: -8 },
    { prefab: "water_tile", rotation: 0, x: 5, y: -8 },
    { prefab: "water_tile", rotation: 0, x: 5, y: -9 },
    { prefab: "water_tile", rotation: 0, x: 4, y: -9 },
    { prefab: "water_tile", rotation: 0, x: 4, y: -10 },
    { prefab: "water_tile", rotation: 0, x: 3, y: -10 },
    { prefab: "water_tile", rotation: 0, x: 0, y: -10 },
    { prefab: "water_tile", rotation: 0, x: 0, y: -9 },
    { prefab: "water_tile", rotation: 0, x: 0, y: -8 },
    { prefab: "water_tile", rotation: 0, x: 0, y: -7 },
    { prefab: "water_tile", rotation: 0, x: -1, y: -7 },
    { prefab: "water_tile", rotation: 0, x: -1, y: -8 },
    { prefab: "water_tile", rotation: 0, x: -1, y: -9 },
    { prefab: "water_tile", rotation: 0, x: -1, y: -10 },
    { prefab: "water_tile", rotation: 0, x: -2, y: -10 },
    { prefab: "water_tile", rotation: 0, x: -2, y: -9 },
    { prefab: "water_tile", rotation: 0, x: -2, y: -8 },
    { prefab: "water_tile", rotation: 0, x: -2, y: -7 },
    { prefab: "water_tile", rotation: 0, x: -3, y: -7 },
    { prefab: "water_tile", rotation: 0, x: -3, y: -8 },
    { prefab: "water_tile", rotation: 0, x: -3, y: -9 },
    { prefab: "water_tile", rotation: 0, x: -3, y: -10 },
    { prefab: "water_tile", rotation: 0, x: -4, y: -9 },
    { prefab: "water_tile", rotation: 0, x: -4, y: -8 },
    { prefab: "water_tile", rotation: 0, x: -4, y: -7 },
    { prefab: "water_tile", rotation: 0, x: -5, y: -7 },
    { prefab: "water_tile", rotation: 0, x: -5, y: -8 },
    { prefab: "water_tile", rotation: 0, x: -6, y: -8 },
    { prefab: "water_tile", rotation: 0, x: -6, y: -7 },
    { prefab: "water_tile", rotation: 0, x: -6, y: -6 },
    { prefab: "water_tile", rotation: 0, x: -7, y: -6 },
    { prefab: "water_tile", rotation: 0, x: -7, y: -7 },
    { prefab: "water_tile", rotation: 0, x: -8, y: -7 },
    { prefab: "water_tile", rotation: 0, x: -8, y: -6 },
    { prefab: "water_tile", rotation: 0, x: -8, y: -5 },
    { prefab: "water_tile", rotation: 0, x: -8, y: -4 },
    { prefab: "water_tile", rotation: 0, x: -9, y: -4 },
    { prefab: "water_tile", rotation: 0, x: -9, y: -5 },
    { prefab: "water_tile", rotation: 0, x: -9, y: -6 },
    { prefab: "water_tile", rotation: 0, x: -9, y: -7 },
    { prefab: "water_tile", rotation: 0, x: -10, y: -7 },
    { prefab: "water_tile", rotation: 0, x: -10, y: -6 },
    { prefab: "water_tile", rotation: 0, x: -10, y: -5 },
    { prefab: "water_tile", rotation: 0, x: -10, y: -4 },
    { prefab: "water_tile", rotation: 0, x: -10, y: -3 },
    { prefab: "water_tile", rotation: 0, x: -9, y: -3 },
    { prefab: "water_tile", rotation: 0, x: -7, y: -5 },
    { prefab: "water_tile", rotation: 0, x: 1, y: -7 },
    { prefab: "water_tile", rotation: 0, x: 1, y: -8 },
    { prefab: "water_tile", rotation: 0, x: 1, y: -9 },
    { prefab: "water_tile", rotation: 0, x: 1, y: -10 },
    { prefab: "water_tile", rotation: 0, x: 2, y: -10 },
    { prefab: "water_tile", rotation: 0, x: 2, y: -9 },
    { prefab: "water_tile", rotation: 0, x: 2, y: -8 },
    { prefab: "water_tile", rotation: 0, x: 2, y: -7 },
    { prefab: "water_tile", rotation: 0, x: 3, y: -7 },
    { prefab: "water_tile", rotation: 0, x: 3, y: -8 },
    { prefab: "water_tile", rotation: 0, x: 3, y: -9 },
    { prefab: "water_tile", rotation: 0, x: 4, y: -8 },
    { prefab: "water_tile", rotation: 0, x: 4, y: -7 },
    { prefab: "water_tile", rotation: 0, x: 5, y: -7 },
    { prefab: "water_tile", rotation: 0, x: 10, y: -5 },
    { prefab: "water_tile", rotation: 0, x: 10, y: -4 },
    { prefab: "water_tile", rotation: 0, x: 10, y: -3 },
    { prefab: "water_tile", rotation: 0, x: 10, y: -2 },
    { prefab: "water_tile", rotation: 0, x: 10, y: -1 },
    { prefab: "water_tile", rotation: 0, x: 10, y: 0 },
    { prefab: "water_tile", rotation: 0, x: 14, y: -3 },
    { prefab: "water_tile", rotation: 0, x: 14, y: -2 },
    { prefab: "water_tile", rotation: 0, x: 14, y: -1 },
    { prefab: "water_tile", rotation: 0, x: 14, y: 0 },
    { prefab: "water_tile", rotation: 0, x: 14, y: 1 },
    { prefab: "water_tile", rotation: 0, x: 14, y: 2 },
    { prefab: "water_tile", rotation: 0, x: 14, y: 3 },
    { prefab: "water_tile", rotation: 0, x: 14, y: 4 },
    { prefab: "water_tile", rotation: 0, x: 14, y: 5 },
    { prefab: "water_tile", rotation: 0, x: 14, y: 6 },
    { prefab: "water_tile", rotation: 0, x: 14, y: 7 },
    { prefab: "water_tile", rotation: 0, x: 14, y: 8 },
    { prefab: "water_tile", rotation: 0, x: 14, y: 9 },
    { prefab: "water_tile", rotation: 0, x: 14, y: 10 },
    { prefab: "water_tile", rotation: 0, x: 15, y: 10 },
    { prefab: "water_tile", rotation: 0, x: 15, y: 9 },
    { prefab: "water_tile", rotation: 0, x: 15, y: 8 },
    { prefab: "water_tile", rotation: 0, x: 15, y: 7 },
    { prefab: "water_tile", rotation: 0, x: 15, y: 6 },
    { prefab: "water_tile", rotation: 0, x: 15, y: 5 },
    { prefab: "water_tile", rotation: 0, x: 15, y: 4 },
    { prefab: "water_tile", rotation: 0, x: 15, y: 3 },
    { prefab: "water_tile", rotation: 0, x: 15, y: 2 },
    { prefab: "water_tile", rotation: 0, x: 15, y: 1 },
    { prefab: "water_tile", rotation: 0, x: 15, y: 0 },
    { prefab: "water_tile", rotation: 0, x: 15, y: -1 },
    { prefab: "water_tile", rotation: 0, x: 15, y: -2 },
    { prefab: "ls_2", rotation: 90, x: 5, y: 7 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 3, y: 9 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 2, y: 10 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 3, y: 12 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 3, y: 13 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 3, y: 14 },
    { prefab: "grass_to_water_tile", rotation: 0, x: 2, y: 12 },
    { prefab: "water_tile", rotation: 0, x: 2, y: 13 },
    { prefab: "water_tile", rotation: 0, x: 2, y: 14 },
    { prefab: "water_tile", rotation: 0, x: 2, y: 15 },
    { prefab: "water_tile", rotation: 0, x: 1, y: 15 },
    { prefab: "water_tile", rotation: 0, x: 1, y: 14 },
    { prefab: "water_tile", rotation: 0, x: 1, y: 13 },
    { prefab: "water_tile", rotation: 0, x: 0, y: 13 },
    { prefab: "water_tile", rotation: 0, x: 0, y: 14 },
    { prefab: "water_tile", rotation: 0, x: 0, y: 15 },
    { prefab: "water_tile", rotation: 0, x: -1, y: 15 },
    { prefab: "water_tile", rotation: 0, x: -1, y: 14 },
    { prefab: "water_tile", rotation: 0, x: -1, y: 13 },
    { prefab: "water_tile", rotation: 0, x: -2, y: 13 },
    { prefab: "water_tile", rotation: 0, x: -2, y: 14 },
    { prefab: "water_tile", rotation: 0, x: -2, y: 15 },
    { prefab: "water_tile", rotation: 0, x: -3, y: 15 },
    { prefab: "water_tile", rotation: 0, x: -3, y: 14 },
    { prefab: "water_tile", rotation: 0, x: -3, y: 13 },
    { prefab: "water_tile", rotation: 0, x: -4, y: 13 },
    { prefab: "water_tile", rotation: 0, x: -4, y: 14 },
    { prefab: "water_tile", rotation: 0, x: -4, y: 15 },
    { prefab: "water_tile", rotation: 0, x: -5, y: 15 },
    { prefab: "water_tile", rotation: 0, x: -5, y: 14 },
    { prefab: "water_tile", rotation: 0, x: -5, y: 13 },
    { prefab: "water_tile", rotation: 0, x: -6, y: 13 },
    { prefab: "water_tile", rotation: 0, x: -6, y: 14 },
    { prefab: "water_tile", rotation: 0, x: -6, y: 15 },
    { prefab: "water_tile", rotation: 0, x: -7, y: 15 },
    { prefab: "water_tile", rotation: 0, x: -7, y: 14 },
    { prefab: "water_tile", rotation: 0, x: -7, y: 13 },
    { prefab: "water_tile", rotation: 0, x: -7, y: 12 },
    { prefab: "water_tile", rotation: 0, x: -7, y: 11 },
    { prefab: "water_tile", rotation: 0, x: -7, y: 10 },
    { prefab: "water_tile", rotation: 0, x: -7, y: 9 },
    { prefab: "water_tile", rotation: 0, x: -7, y: 8 },
    { prefab: "water_tile", rotation: 0, x: -8, y: 8 },
    { prefab: "water_tile", rotation: 0, x: -8, y: 7 },
    { prefab: "water_tile", rotation: 0, x: -9, y: 7 },
    { prefab: "water_tile", rotation: 0, x: -9, y: 8 },
    { prefab: "water_tile", rotation: 0, x: -9, y: 9 },
    { prefab: "water_tile", rotation: 0, x: -9, y: 10 },
    { prefab: "water_tile", rotation: 0, x: -9, y: 11 },
    { prefab: "water_tile", rotation: 0, x: -9, y: 12 },
    { prefab: "water_tile", rotation: 0, x: -9, y: 13 },
    { prefab: "water_tile", rotation: 0, x: -9, y: 14 },
    { prefab: "water_tile", rotation: 0, x: -9, y: 15 },
    { prefab: "water_tile", rotation: 0, x: -8, y: 15 },
    { prefab: "water_tile", rotation: 0, x: -8, y: 14 },
    { prefab: "water_tile", rotation: 0, x: -8, y: 13 },
    { prefab: "water_tile", rotation: 0, x: -8, y: 12 },
    { prefab: "water_tile", rotation: 0, x: -8, y: 11 },
    { prefab: "water_tile", rotation: 0, x: -8, y: 10 },
    { prefab: "water_tile", rotation: 0, x: -8, y: 9 },
    { prefab: "water_tile", rotation: 0, x: -10, y: 14 },
    { prefab: "water_tile", rotation: 0, x: -10, y: 13 },
    { prefab: "water_tile", rotation: 0, x: -10, y: 12 },
    { prefab: "water_tile", rotation: 0, x: -10, y: 11 },
    { prefab: "water_tile", rotation: 0, x: -10, y: 10 },
    { prefab: "water_tile", rotation: 0, x: -10, y: 9 },
    { prefab: "water_tile", rotation: 0, x: -10, y: 8 },
    { prefab: "water_tile", rotation: 0, x: -11, y: 9 },
    { prefab: "water_tile", rotation: 0, x: -11, y: 10 },
    { prefab: "water_tile", rotation: 0, x: -11, y: 11 },
    { prefab: "walls/wall_2_se", rotation: 270, x: 3, y: 11 },
    { prefab: "water_cooler", rotation: 90, x: 3, y: 10 },
    { prefab: "walls/wall_2_sew", rotation: 180, x: 2, y: 11 },
    { prefab: "walls/wall_2_s_to_e", rotation: 180, x: 5, y: 5 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: 7 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 4, y: 6 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: 8 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: 8 },
    { prefab: "lounge", rotation: 90, x: 4, y: 13 },
    { prefab: "lounge", rotation: 90, x: 7, y: 13 },
    { prefab: "lounge", rotation: 90, x: 10, y: 13 },
    { prefab: "cactus", rotation: 0, x: 11, y: 6 },
    { prefab: "floor_tile", rotation: 0, x: 8, y: 6 },
    { prefab: "cactus", rotation: 0, x: 7, y: 5 },
    { prefab: "floor_tile", rotation: 0, x: 11, y: 10 },
    { prefab: "white_board_ns", rotation: 0, x: 3, y: -3 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: -3 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: -2 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: -1 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 2, y: -2 },
    { prefab: "walls/wall_2_se", rotation: 90, x: 2, y: -1 },
    { prefab: "white_board_ns", rotation: 0, x: 9, y: -12 },
    { prefab: "lounge", rotation: 180, x: 10, y: -12 },
    { prefab: "pool", rotation: 0, x: -3, y: -16 },
    { prefab: "grass_tile", rotation: 0, x: 3, y: -12 },
    { prefab: "grass_tile", rotation: 0, x: 3, y: -11 },
    { prefab: "grass_tile", rotation: 0, x: 4, y: -11 },
    { prefab: "benches/bench_2", rotation: 0, x: -4, y: 2 },
    { prefab: "benches/bench_1", rotation: 0, x: -4, y: 3 },
    { prefab: "plants/plant_1", rotation: 0, x: -4, y: 6 },
    { prefab: "plants/plant_2", rotation: 0, x: -4, y: 5 },
    { prefab: "plants/plant_3", rotation: 0, x: -3, y: 5 },
    { prefab: "plants/plant_4", rotation: 0, x: -3, y: 6 },
    { prefab: "plants/plant_5", rotation: 0, x: -4, y: 7 },
    { prefab: "plants/plant_6", rotation: 0, x: -3, y: 7 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -2, y: 4 },
  ],
};
