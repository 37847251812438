import { SceneEditor } from "../SceneEditor";
import { SceneEditorTool } from "../SceneEditorTool";

export class CameraTool extends SceneEditorTool {
  static NAME = "camera";
  name = CameraTool.NAME;
  tooltip = "Move the camera around by clicking and dragging on the view";
  icon = "pan_tool";
  activateTool(editor: SceneEditor): boolean {
    if (!super.activateTool(editor)) {
      return false;
    }
    editor.cameraControls.setToolOverride(false);
    return true;
  }
  escapeTool(): boolean {
    if (!this.editor || !super.escapeTool()) {
      return false;
    }
    this.editor.cameraControls.setToolOverride(true);
    return true;
  }
}
