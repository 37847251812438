import { Mesh, MeshBasicMaterial, Object3D, SphereGeometry } from "three";
import { ILoadable } from "../core/Loadable";

const SEAT_MATERIAL = new MeshBasicMaterial({
  color: 0xffffff,
  opacity: 0.3,
  transparent: true,
});

const SEAT_GEOMETRY = new SphereGeometry(0.25, 8, 6);

/**
 * A hoverable/clickable seat mesh.
 */
export class Seat
  extends Mesh<SphereGeometry, MeshBasicMaterial>
  implements ILoadable<Seat> {
  constructor(child: Object3D) {
    super(SEAT_GEOMETRY.clone() as SphereGeometry, SEAT_MATERIAL.clone());
    if (child) {
      this.name = child.name;
      this.position.copy(child.position);
      // child.getWorldPosition(this.position);
    }
    this.type = "seat";
    this.visible = false;
  }

  load(): Promise<Seat> {
    return new Promise((resolve) => {
      resolve(this);
    });
  }
  unload(): Promise<Seat> {
    return Promise.resolve(this);
  }
}
