import { Event, EventDispatcher } from "three";
import { IAction } from "./Action";
import Notify from 'quasar/src/plugins/Notify.js';;
export interface IActionManager {
  clear(): void;
  redo(): IAction | null;
  undo(): IAction | null;

  getUndoCount(): number;
  getRedoCount(): number;

  getUndoName(index: number): string;
  getRedoName(index: number): string;

  executeAction(action: IAction): boolean;
}

export interface ActionManagerEvent extends Event {
  type: string;
  action?: IAction;
}

export class ActionManager implements IActionManager {
  events = new EventDispatcher();
  private _undoStack: IAction[] = [];
  private _redoStack: IAction[] = [];
  clear(): void {
    this._undoStack.length = 0;
    this._redoStack.length = 0;
    this.events.dispatchEvent({ type: "clear" });
  }

  isDirty(): boolean {
    return this._undoStack.length > 0 || this._redoStack.length > 0;
  }

  redo(): IAction | null {
    if (this._redoStack.length === 0) {
      return null;
    }
    const last: IAction = this._redoStack[this._redoStack.length - 1];
    if (!this._executeAction(last)) {
      console.error("Failed to redo action: " + last.name, last);
      return null;
    }
    this._redoStack.pop();
    this.events.dispatchEvent({ type: "redo", action: last });
    Notify.create(`Redo: ${last.name}`);
    return last;
  }

  undo(): IAction | null {
    if (this._undoStack.length === 0) {
      return null;
    }
    const last: IAction = this._undoStack[this._undoStack.length - 1];
    if (!this._undoAction(last)) {
      console.error("Failed to undo action: " + last.name, last);
      return null;
    }
    this.events.dispatchEvent({ type: "undo", action: last });
    Notify.create(`Undo: ${last.name}`);
    this._undoStack.pop();
    return last;
  }

  getUndoCount(): number {
    return this._undoStack.length;
  }

  getRedoCount(): number {
    return this._redoStack.length;
  }

  getUndoName(index: number): string {
    return this._undoStack[index]?.name || "";
  }

  getRedoName(index: number): string {
    return this._redoStack[index]?.name || "";
  }

  executeAction(action: IAction): boolean {
    if (!this._executeAction(action)) {
      return false;
    }
    // taking a new action makes the redo history invalid
    if (this._redoStack.length > 0) {
      this._redoStack.length = 0;
    }
    return true;
  }

  private _executeAction(action: IAction): boolean {
    try {
      if (!action || !action.execute()) {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
    Notify.create(action.name);
    this._undoStack.push(action);
    this.events.dispatchEvent({ type: "do", action });
    return true;
  }
  private _undoAction(action: IAction): boolean {
    try {
      if (!action || !action.undo()) {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
    this._redoStack.push(action);
    return true;
  }
}
