import {
  Box3,
  EventDispatcher,
  Intersection,
  PerspectiveCamera,
  Vector2,
  Vector3,
  WebGLRenderer,
} from "three";
import { ActionManager } from "../core/ActionManager";
import { EditorToolChangedEvent, IEditor, IEditorTool } from "../core/Editor";
import { ThreeJSPicker } from "../core/Picker";
import { getPrefab, PieceTypes, Prefab } from "../core/Prefabs";
import {
  createEditorScene,
  createRenderer,
  SceneConfig,
  TILE_SIZE,
} from "../core/Scene";
import { EditorDocument } from "./EditorDocument";
import { SceneCameraControls } from "./SceneCameraControls";

export class SceneEditor implements IEditor {
  public events: EventDispatcher = new EventDispatcher();
  private _activeTool: IEditorTool | null = null;
  public currentPrefab: Prefab | null = null;
  public actions: ActionManager = new ActionManager();
  private picker: ThreeJSPicker;
  private pointer: Vector2 = new Vector2();
  public cameraControls: SceneCameraControls;

  private thumbnailConfig: SceneConfig;
  private thumbnailRenderer: WebGLRenderer;
  private thumbnailCamera: PerspectiveCamera;

  constructor(public document: EditorDocument, public tools: IEditorTool[]) {
    this.picker = new ThreeJSPicker(document, document.camera);
    const [thumbnailRenderer, thumbnailCamera] = createRenderer(64, 64, true);
    this.thumbnailRenderer = thumbnailRenderer;
    this.thumbnailCamera = thumbnailCamera;
    this.thumbnailConfig = createEditorScene(false);
    this.cameraControls = new SceneCameraControls(
      document.camera,
      document.renderer.domElement
    );
    this.cameraControls.enabled = false;
    this.setActiveTool(this.tools[0].name);
  }
  getActiveToolName(): string | null {
    return this._activeTool?.name || null;
  }

  dispose(): void {
    this.actions.clear();
  }

  public host: any | null = null;
  setVueHost(host: any): void {
    this.host = host;
  }

  setActiveTool(name: string): boolean {
    if (this._activeTool && !this._activeTool.escapeTool()) {
      return false;
    }
    const previousTool = this._activeTool;
    const tool = this.tools.find((t) => t.name === name);
    if (!tool) {
      throw new Error(`Unknown tool: ${name}`);
    }
    if (tool.activateTool(this)) {
      this._activeTool = tool;
      const toolEvent: EditorToolChangedEvent = {
        type: "change",
        tool,
        previousTool,
      };
      this.events.dispatchEvent(toolEvent);
      return true;
    }
    return false;
  }
  getActiveTool(): IEditorTool | null {
    return this._activeTool;
  }

  /** Set the active createPrefab type */
  setPrefab(ofType?: PieceTypes): Promise<Prefab> {
    if (!ofType) {
      return Promise.reject(`Invalid prefab type: ${ofType}`);
    }
    return getPrefab(ofType).then((prefab) => {
      const { scene } = this.document.config;
      if (this.currentPrefab) {
        scene.remove(this.currentPrefab.object);
      }
      this.currentPrefab = prefab.clone();
      scene.add(this.currentPrefab.object);
      // the prefab will be shown onmousemove inside the grid
      this.currentPrefab.object.visible = false;
      return prefab;
    });
  }

  pick(cameraX: number, cameraY: number): Intersection[] {
    this.pointer.set(cameraX, cameraY);
    return this.picker.pick(this.pointer);
  }

  /** Render a prefab to a data-url that can be bound to <img> elements. */
  renderPrefabToImage(prefab: Prefab): string {
    this.thumbnailConfig.shapes.clear();
    this.thumbnailConfig.shapes.add(prefab.object);
    const fitOffset = 0.1;
    const box = new Box3();
    box.expandByObject(prefab.object);
    const center = box.getCenter(new Vector3());
    const maxSize = Math.max(prefab.width, prefab.height);
    const fitHeightDistance =
      maxSize / (2 * Math.atan((Math.PI * this.thumbnailCamera.fov) / 360));
    const fitWidthDistance = fitHeightDistance / this.thumbnailCamera.aspect;
    const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);
    this.thumbnailCamera.near = distance / 100;
    this.thumbnailCamera.far = distance * 100;
    this.thumbnailCamera.updateProjectionMatrix();
    this.thumbnailCamera.position.set(
      prefab.width * TILE_SIZE,
      maxSize * TILE_SIZE,
      prefab.height * TILE_SIZE
    );
    this.thumbnailCamera.lookAt(center);
    this.thumbnailRenderer.clear();
    this.thumbnailRenderer.render(
      this.thumbnailConfig.scene,
      this.thumbnailCamera
    );
    return this.thumbnailRenderer.domElement.toDataURL();
  }
}
