import { EventDispatcher } from "three";
import { ActionManager } from "./ActionManager";

/** List of supported editor types as a runtime list of strings. */
export const EDITOR_TYPES = ["scene", "prefab", "office"] as const;
/** Union static type for auto-complete of editor type strings. */
export type EditorTypes = typeof EDITOR_TYPES[number];

/** The active editor tool changed */
export interface EditorToolChangedEvent {
  type: "change";
  tool: IEditorTool;
  previousTool: IEditorTool | null;
}

export interface IEditor {
  events: EventDispatcher;
  // lifetime
  dispose(): void;

  // tools
  tools: IEditorTool[];
  actions: ActionManager;
  setActiveTool(name: string): boolean;
  getActiveTool(): IEditorTool | null;
  getActiveToolName(): string | null;
}

/** An input event. */
export type IEditorInput = MouseEvent;

export interface IEditorTool {
  editor: IEditor | null;
  /** Human readable name */
  name: string;

  /**
   * A tooltip summary of what this tool does. Shown in the UI.
   *
   * TODO: replace these with lang table entry keys for i18n
   */
  tooltip: string;

  /**
   * The icon to use. This can be a single name material-icon that is built-in
   * or a path to another resource, e.g. "brush" or "img:/img/custom.svg"
   */
  icon: string;

  escapeTool(): boolean;
  activateTool(editor: IEditor): boolean;

  // pointer/mouse
  onMouseMove(event: IEditorInput): void;
  onMouseUp(event: IEditorInput): void;
  onMouseDown(event: IEditorInput): void;
  // mouse
  onMouseWheel(event: WheelEvent): void;
  // key
  onKeyDown(event: KeyboardEvent): boolean;
  onKeyUp(event: KeyboardEvent): boolean;
}
