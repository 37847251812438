import {
  BoxGeometry,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  Vector2,
  Vector3,
} from "three";
import { ThreeJSPicker } from "../../core/Picker";
import { screenToCamera, TILE_SIZE } from "../../core/Scene";
import { RoomLabel } from "../../objects/RoomLabel";
import { SceneEditor } from "../SceneEditor";
import { SceneEditorTool } from "../SceneEditorTool";
/**
 * Tool for defining "room" boundaries on the grid.
 */
export class RoomsTool extends SceneEditorTool {
  static NAME = "rooms";
  name = RoomsTool.NAME;
  tooltip =
    'Draw boundaries around prefabs that should be part of the same "room".';
  icon = "home_work";
  private dragStart: Vector2 = new Vector2();
  private roomObjects: { [roomId: string]: Object3D } = {};
  private picker: ThreeJSPicker | null = null;
  private pointer: Vector2 = new Vector2();
  private hoverRoom: Object3D | null = null;
  private selection: Object3D[] = [];
  private roomMaterial = new MeshBasicMaterial({
    name: "room",
    color: 0xeeeeee,
    opacity: 0.5,
    transparent: true,
  });
  private static HOVER_MATERIAL = new MeshBasicMaterial({
    color: 0xfff666,
    opacity: 0.5,
    transparent: true,
  });

  activateTool(editor: SceneEditor): boolean {
    if (!super.activateTool(editor)) {
      return false;
    }
    const rooms = editor.document.suggestRoomLayout();
    // console.log("room layout", rooms);
    for (const room of rooms) {
      const width = TILE_SIZE * room.width;
      const height = TILE_SIZE * room.height;
      const boxMesh = new Mesh(
        new BoxGeometry(width, TILE_SIZE * 1, height),
        this.roomMaterial
      );
      boxMesh.name = "room";
      // clicks register on boxMesh, so keep a reference to the room
      boxMesh.userData.room = room;
      const label = new RoomLabel(room.label);
      label.name = "label";
      label.text.position.z = 0.51;
      label.lookAt(new Vector3(0, 1, 0));

      const roomHost = new Object3D();
      roomHost.position.set(room.x + width / 2, 0.5, room.y + height / 2);
      roomHost.add(boxMesh);
      roomHost.add(label);

      this.roomObjects[room.label] = roomHost;
      editor.document.config.rooms.add(roomHost);
    }
    return true;
  }

  escapeTool(): boolean {
    this.clearHover(); // clean up any active drag
    if (!this.editor) {
      return false;
    }
    for (const value of Object.values(this.roomObjects)) {
      this.editor.document.config.rooms.remove(value);
      value.traverse((child) => {
        if (child instanceof RoomLabel) {
          child.unload();
        } else if (child instanceof Mesh) {
          if (child.geometry) {
            child.geometry.dispose();
          }
        }
      });
    }
    this.editor.document.config.rooms.clear();
    return super.escapeTool();
  }

  /** Add an object to the selection */
  addSelection(object: Object3D): void {
    const exists = this.selection.find((child) => child.id === object.id);
    if (!exists) {
      if (this.hoverRoom && this.hoverRoom.id === object.id) {
        this.clearHover();
      }
      this.select(object);
      this.selection.push(object);
    }
  }

  /** Remove an object from the selection */
  removeSelection(object: Object3D): void {
    const exists = this.selection.find((child) => child.id === object.id);
    if (exists) {
      this.unselect(exists);
      this.selection = this.selection.filter((child) => child.id !== object.id);
    }
  }

  clearSelection(): void {
    this.selection.forEach((child) => {
      this.unselect(child);
    });
    this.selection.length = 0;
  }

  /** Clear any decoration on an existing hover room */
  clearHover(): void {
    if (!this.editor) {
      return;
    }
    if (this.hoverRoom !== null) {
      this.unselect(this.hoverRoom);
    }
    this.hoverRoom = null;
    this.dragStart.set(-1, -1);
  }

  /** Get the room object under the mouse, or null if none. */
  getRoomFromEvent(event: MouseEvent): Object3D | null {
    if (!this.editor) {
      return null;
    }
    if (this.picker === null) {
      this.picker = new ThreeJSPicker(
        this.editor.document.config.rooms,
        this.editor.document.camera
      );
    }
    const [cameraX, cameraY] = screenToCamera(
      event.currentTarget as HTMLElement,
      event.offsetX,
      event.offsetY
    );
    this.pointer.set(cameraX, cameraY);
    this.picker.target = this.editor.document.config.rooms;
    const hit = this.picker.pickSpecific(this.pointer, (maybe) => {
      return maybe.object.name === "room";
    });
    return hit?.object || null;
  }

  onMouseMove(event: PointerEvent): void {
    if (event.buttons > 1 || !this.editor?.host || !event.currentTarget) {
      return;
    }
    const hit = this.getRoomFromEvent(event);
    if (hit && !this.selection.find((child) => child.id === hit.id)) {
      if (this.hoverRoom?.id !== hit.id) {
        this.clearHover();
        this.hoverRoom = hit;
        this.select(this.hoverRoom, RoomsTool.HOVER_MATERIAL);
      }
    } else {
      this.clearHover();
    }
  }
  onMouseUp(event: PointerEvent): void {
    if (event.button !== 0 || !this.editor?.host) {
      return;
    }
    const dragTolerance = 10;
    const deltaX = Math.abs(this.dragStart.x - event.clientX);
    const deltaY = Math.abs(this.dragStart.y - event.clientY);
    const isClick = deltaX < dragTolerance && deltaY < dragTolerance;
    const isShift = event.shiftKey;
    if (isClick) {
      const hit = this.getRoomFromEvent(event);
      if (hit) {
        if (
          // Not adding to existing selection
          !isShift &&
          // If there's 1 item with a different ID
          (this.selection.length !== 1 || hit.id !== this.selection[0].id)
        ) {
          this.clearSelection();
        }
        // Add the hit to the selection
        this.addSelection(hit);
      } else {
        this.clearSelection();
      }
    }
  }
  onMouseDown(event: PointerEvent): void {
    if (event.button !== 0 || !this.editor) {
      return;
    }
    this.dragStart.set(event.clientX, event.clientY);
    return;
  }
}
