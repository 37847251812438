import { render } from "./PrefabThumbnail.vue?vue&type=template&id=67de8484&scoped=true"
import script from "./PrefabThumbnail.vue?vue&type=script&lang=ts"
export * from "./PrefabThumbnail.vue?vue&type=script&lang=ts"

import "./PrefabThumbnail.vue?vue&type=style&index=0&id=67de8484&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-67de8484"

export default script
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTooltip});
