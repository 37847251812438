import { Color, Euler, Object3D, Vector3 } from "three";
import { IAction } from "../core/Action";
import { CameraControls, ICameraControlsState } from "../core/CameraControls";
import {
  IScenePiece,
  ISceneSerialized,
  ScenePieceRotation,
} from "../core/Document";
import { Prefab } from "../core/Prefabs";
import { snapObjectToGrid } from "../core/Scene";
import { EditorDocument } from "./EditorDocument";
import { SceneEditor } from "./SceneEditor";

/** Change the background color */
export class SetBackgroundAction implements IAction {
  name = "Set Background";
  private oldColor: Color;

  constructor(private color: Color, private document: EditorDocument) {
    this.oldColor = new Color(document.config.createArgs.backgroundColor);
  }
  execute(): boolean {
    this.document.setColor(this.color);
    return true;
  }
  undo(): boolean {
    this.document.setColor(this.oldColor);
    return true;
  }
}

export class RotatePrefabAction implements IAction {
  name = "Rotate Prefab";
  constructor(
    public target: Object3D,
    private oldRotation: ScenePieceRotation,
    private newRotation: ScenePieceRotation,
    private document: EditorDocument
  ) {}
  execute(): boolean {
    this.document.rotatePiece(this.target, this.newRotation);
    return true;
  }
  undo(): boolean {
    this.document.rotatePiece(this.target, this.oldRotation);
    return true;
  }
}

export class RemovePrefabsAction implements IAction {
  name = "Remove Prefabs";
  public backups: (IScenePiece | null)[] | null = null;
  constructor(public targets: Object3D[], private document: EditorDocument) {}
  execute(): boolean {
    this.backups = this.targets.map((t) => this.document.removePiece(t));
    return true;
  }
  undo(): boolean {
    if (this.backups !== null) {
      this.backups.forEach((b) => {
        if (!b) {
          return;
        }
        this.document.addPiece(
          b.prefab,
          b.instance,
          b.box.min.x,
          b.box.min.y,
          b.rotation
        );
      });
      this.backups = null;
      return true;
    }
    return false;
  }
}

/**
 * Paint a set of tiles into the scene.
 *
 * This operation accepts a set of pieces to be inserted into
 * the document and a set of pieces to be removed. This is because
 * we don't support stacking so the tools remove any objects that
 * get painted over.
 */
export class PaintTilesAction implements IAction {
  name = "Paint Tiles";
  constructor(
    private add: IScenePiece[],
    private remove: IScenePiece[],
    private document: EditorDocument
  ) {}
  execute(): boolean {
    // Remove the pieces that were ovewritten
    this.remove.forEach((r) => this.document.removePiece(r.instance));
    // Add (usually redundant here, but useful for redo) pieces to the doc
    this.add.forEach((r) =>
      this.document.addPiece(
        r.prefab,
        r.instance,
        r.box.min.x,
        r.box.min.y,
        r.rotation
      )
    );
    return true;
  }
  undo(): boolean {
    // Remove the painted pieces
    this.add.forEach((r) => this.document.removePiece(r.instance));
    // Add back the old ones
    this.remove.forEach((r) => {
      if (!r) {
        return;
      }
      this.document.addPiece(
        r.prefab,
        r.instance,
        r.box.min.x,
        r.box.min.y,
        r.rotation
      );
    });
    return true;
  }
}

export class ClearSceneAction implements IAction {
  name = "Clear All Prefabs";
  constructor(private editor: SceneEditor) {}
  private _backup: {
    doc: ISceneSerialized;
    prefab: Prefab | null;
  } | null = null;
  execute(): boolean {
    this._backup = {
      doc: this.editor.document.toObject(),
      prefab: this.editor.currentPrefab,
    };
    this.editor.document.reset();
    return true;
  }
  undo(): boolean {
    if (this._backup) {
      this.editor.document.load(this._backup.doc);
      if (this._backup.prefab) {
        this.editor.document.config.scene.add(this._backup.prefab.object);
      }
      this._backup = null;
      return true;
    }
    return false;
  }
}

export class ResetCameraAction implements IAction {
  name = "Reset Camera";
  constructor(
    private document: EditorDocument,
    public controls: CameraControls
  ) {}
  private _backup: {
    position: Vector3;
    rotation: Euler;
    scale: Vector3;
    controls: ICameraControlsState;
  } | null = null;
  execute(): boolean {
    const camera = this.document.camera;
    const controls = this.controls.getState();
    this._backup = {
      position: camera.position.clone(),
      rotation: camera.rotation.clone(),
      scale: camera.scale.clone(),
      controls,
    };
    this.controls.reset();
    this.document.resetCamera();
    return true;
  }
  undo(): boolean {
    if (this._backup) {
      const camera = this.document.camera;
      const { position, rotation, scale } = this._backup;
      this.controls.loadState(this._backup.controls);
      camera.position.copy(position);
      camera.rotation.copy(rotation);
      camera.scale.copy(scale);
      camera.updateProjectionMatrix();
      this._backup = null;
      return true;
    }
    return false;
  }
}

/**
 * Paint a set of tiles into the scene.
 *
 * This operation accepts a set of pieces to be inserted into
 * the document and a set of pieces to be removed. This is because
 * we don't support stacking so the tools remove any objects that
 * get painted over.
 */
export class MovePrefabAction implements IAction {
  name = "Move Prefab";
  constructor(
    private piece: IScenePiece,
    private fromX: number,
    private fromY: number,
    private toX: number,
    private toY: number,
    private remove: IScenePiece[],
    private document: EditorDocument
  ) {}
  execute(): boolean {
    // Remove the pieces that were ovewritten
    this.remove.forEach((r) => this.document.removePiece(r.instance));
    const instance = this.piece.instance;
    const point = new Vector3(this.toX, 0, this.toY);
    const [gridX, gridY] = snapObjectToGrid(
      instance,
      point,
      this.piece.prefab.width,
      this.piece.prefab.height
    );
    this.document.movePiece(this.piece, gridX, gridY, this.piece.rotation);
    return true;
  }
  undo(): boolean {
    const point = new Vector3(this.fromX, 0, this.fromY);
    const [gridX, gridY] = snapObjectToGrid(
      this.piece.instance,
      point,
      this.piece.prefab.width,
      this.piece.prefab.height
    );
    this.document.movePiece(this.piece, gridX, gridY, this.piece.rotation);
    // Add back the old ones
    this.remove.forEach((r) => {
      if (!r) {
        return;
      }
      this.document.addPiece(
        r.prefab,
        r.instance,
        r.box.min.x,
        r.box.min.y,
        r.rotation
      );
    });
    return true;
  }
}
