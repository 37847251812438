import "./styles/quasar.scss";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";

import { createApp } from "vue";
import App from "./App.vue";
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';;

createApp(App)
  .use(Quasar, {
    plugins: {
      Notify,
      Dialog,
    },
    config: {
      notify: {
        timeout: 1000,
        /* look at QuasarConfOptions from the API card */
      },
    },
  })
  .mount("#app");
