import { Object3D } from "three";
import { getPrefabRoot, screenToCamera } from "../../core/Scene";
import { SceneEditorTool } from "../SceneEditorTool";

export class RotateTool extends SceneEditorTool {
  static NAME = "rotate";
  name = RotateTool.NAME;
  tooltip = "Rotate prefabs by clicking on them";
  icon = "360";
  private hoverPrefab: Object3D | null = null;
  escapeTool(): boolean {
    if (this.hoverPrefab) {
      this.drop(this.hoverPrefab);
      this.hoverPrefab = null;
    }
    return true;
  }
  onMouseMove(event: PointerEvent): void {
    if (event.buttons > 1 || !this.editor || !this.editor.host) {
      return;
    }
    const host = this.editor.host;
    const [cameraX, cameraY] = screenToCamera(
      host.container(),
      event.offsetX,
      event.offsetY
    );
    const hits = this.editor
      .pick(cameraX, cameraY)
      .filter((h) => h.object.name !== "Grid");
    const hit = hits[0];
    if (!hit) {
      if (this.hoverPrefab) {
        this.drop(this.hoverPrefab);
        this.hoverPrefab = null;
      }
      return;
    }

    const root = getPrefabRoot(hit.object);
    if (this.hoverPrefab && this.hoverPrefab !== root) {
      this.drop(this.hoverPrefab);
      this.hoverPrefab = null;
    }
    this.hoverPrefab = root;
    if (this.hoverPrefab) {
      this.lift(this.hoverPrefab, 0.25);
    }
  }
  onMouseDown(event: PointerEvent): void {
    if (event.button !== 0 || !this.editor) {
      return;
    }
    if (!this.editor.host) {
      return;
    }
    const [cameraX, cameraY] = screenToCamera(
      this.editor.host.container(),
      event.offsetX,
      event.offsetY
    );
    const hits = this.editor
      .pick(cameraX, cameraY)
      .filter((h) => h.object.name !== "Grid");
    const hit = hits[0];
    if (!hit) {
      return;
    }
    const root = getPrefabRoot(hit.object);
    if (!root || !root.userData.prefab) {
      return;
    }
    this.rotate(root).then(() => {
      if (this.hoverPrefab) {
        this.drop(this.hoverPrefab);
        this.hoverPrefab = null;
      }
    });
  }
}
