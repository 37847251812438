
import { getPrefab, PieceTypes } from "@/welo/core/Prefabs";
import { defineComponent, inject } from "vue";
import { SceneEditor } from "@/welo/editor/SceneEditor";

interface PrefabThumbnailData {
  name: PieceTypes;
  src: string;
  width: number;
  height: number;
}

export default defineComponent({
  name: "PrefabThumbnail",
  data() {
    return {
      width: 0,
      height: 0,
      src: "",
    } as PrefabThumbnailData;
  },
  props: ["name"],
  methods: {
    setPrefab(ofType: PieceTypes) {
      getPrefab(ofType).then((prefab) => {
        if (this.editor) {
          this.src = this.editor.renderPrefabToImage(prefab);
          this.width = prefab.width;
          this.height = prefab.height;
        }
      });
    },
  },
  setup() {
    return {
      editor: inject<SceneEditor>("editor"),
    };
  },
  mounted() {
    this.setPrefab(this.name);
  },
  watch: {
    name(val: PieceTypes) {
      this.setPrefab(val);
    },
  },
});
