export interface IEnvironmentMap {
  id: string;
  name: string;
  path: string | null;
  format: string;
  source: string;
}

export const ENVIRONMENT_MAPS: IEnvironmentMap[] = [
  {
    id: "",
    name: "None",
    path: null,
    format: ".hdr",
    source: "",
  },
  {
    id: "venice-sunset",
    name: "Venice Sunset",
    path: "environment/venice_sunset_1k.hdr",
    format: ".hdr",
    source:
      "https://github.com/donmccurdy/three-gltf-viewer/tree/main/assets/environment",
  },
  {
    id: "footprint-court",
    name: "Footprint Court (HDR Labs)",
    path: "environment/footprint_court_2k.hdr",
    format: ".hdr",
    source:
      "https://github.com/donmccurdy/three-gltf-viewer/tree/main/assets/environment",
  },
  {
    id: "vintage-measuring-lab",
    name: "Vintage Measuring Lab",
    path: "environment/vintage_measuring_lab_1k.hdr",
    format: ".hdr",
    source: "https://hdrihaven.com/hdri/?c=urban&h=vintage_measuring_lab",
  },
  {
    id: "moonlit-golf-1k",
    name: "Moonlit Golf",
    path: "environment/moonlit_golf_1k.hdr",
    format: ".hdr",
    source: "https://hdrihaven.com/hdri/?c=night&h=moonlit_golf",
  },
  {
    id: "photo-studio-1k",
    name: "Photo Studio (small)",
    path: "environment/photo_studio_01_1k.hdr",
    format: ".hdr",
    source: "https://hdrihaven.com/hdri/?c=studio&h=photo_studio_01",
  },
  {
    id: "chinese-garden-1k",
    name: "Chinese Garden",
    path: "environment/chinese_garden_1k.hdr",
    format: ".hdr",
    source: "https://hdrihaven.com/hdri/?c=natural%20light&h=chinese_garden",
  },
  {
    id: "dikhololo_night_1k",
    name: "Dikhololo Night",
    path: "environment/dikhololo_night_1k.hdr",
    format: ".hdr",
    source: "https://hdrihaven.com/hdri/?c=night&h=dikhololo_night",
  },
];
