import { MOUSE, OrthographicCamera, TOUCH } from "three";
import { CameraControls } from "../core/CameraControls";

/**
 * Office controls maintains the "up" direction, camera.up (+Y by default)
 *
 *    Zoom - middle mouse, or mousewheel / touch: two-finger spread or squish
 *    Pan - right mouse, or left mouse + ctrl/meta/shiftKey
 *    Pan - left mouse, or left right + ctrl/meta/shiftKey, or arrow keys / touch: one-finger move
 */
export class OfficeCameraControls extends CameraControls {
  constructor(
    public object: OrthographicCamera,
    public domElement: HTMLElement
  ) {
    super(object, domElement);
    this.mouseButtons.LEFT = MOUSE.PAN;
    this.mouseButtons.RIGHT = MOUSE.PAN;
    this.mouseButtons.MIDDLE = MOUSE.PAN;
    this.touches.ONE = TOUCH.PAN;
    this.zoomSpeed = 2.25;
    this.maxZoom = 100;
    this.minZoom = 3;
    this.maxPolarAngle = 60 * (Math.PI / 180);
    this.minPolarAngle = 45 * (Math.PI / 180);
    this.zoomTilt = true;
  }
}
