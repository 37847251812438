
import { ActionManager } from "@/welo/core/ActionManager";
import { IEditorInput } from "@/welo/core/Editor";
import { PieceTypes, Prefab, PREFAB_TYPES } from "@/welo/core/Prefabs";
import { SceneEditor } from "@/welo/editor/SceneEditor";
import * as TWEEN from "@tweenjs/tween.js";
import Notify from 'quasar/src/plugins/Notify.js';;
import { Mesh, Vector2 } from "three";
import { defineComponent, inject } from "vue";
import StatusBar from "../components/StatusBar.vue";
import { CameraTool } from "../welo/editor/tools/CameraTool";

export interface SceneViewData {
  prefabs: Prefab[];
  plane: Mesh;
  unmounted: boolean;
  containerElement: HTMLElement | null;
}

export default defineComponent({
  components: { StatusBar },
  name: "SceneView",
  data() {
    return ({
      prefabTypes: PREFAB_TYPES,
      actions: new ActionManager(),
      pointer: new Vector2(),
      unmounted: false,
      containerElement: null,
    } as unknown) as SceneViewData;
  },
  methods: {
    init() {
      this.unmounted = false;
      const container = this.container();
      const { renderer } = this.editor.document;
      this.editor.setVueHost(this);
      this.editor.document.resetCamera();
      if (container) {
        container.appendChild(renderer.domElement);
      }
    },
    animate(deltaTime = 0.0) {
      if (this.unmounted) {
        return;
      }
      requestAnimationFrame(this.animate);
      try {
        this.editor.document.render(
          this.editor.document.renderer,
          this.editor.document.camera
        );
        TWEEN.update(deltaTime);
      } catch (e) {
        console.error("Failed to render with error", e);
        this.unmounted = true;
      }
    },
    container(): HTMLElement | null {
      const container = this.$refs.sceneContainer as HTMLElement;
      return container;
    },
    onWindowResize() {
      const container = this.container();
      if (!container) {
        return;
      }
      const { camera, renderer } = this.editor.document;
      const width = container.clientWidth;
      const height = container.clientHeight;
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    },
    onMouseMove(event: IEditorInput): void {
      const tool = this.editor.getActiveTool();
      if (tool) {
        tool.onMouseMove(event);
      }
    },
    onMouseDown(event: IEditorInput): void {
      const tool = this.editor.getActiveTool();
      if (tool) {
        tool.onMouseDown(event);
      }
    },
    onMouseUp(event: IEditorInput): void {
      const tool = this.editor.getActiveTool();
      if (tool) {
        tool.onMouseUp(event);
      }
    },
    onMouseWheel(event: WheelEvent): void {
      const tool = this.editor.getActiveTool();
      if (tool) {
        return tool.onMouseWheel(event);
      }
    },
    onKeyDown(event: KeyboardEvent): void {
      const tool = this.editor.getActiveTool();
      if (tool && tool.onKeyDown(event) === true) {
        return;
      }
      const key = event.key;
      const ctrl = event.ctrlKey || event.metaKey;
      const shift = event.shiftKey;
      if (key === "Escape") {
        this.editor.setActiveTool(CameraTool.NAME);
      }
      if (key === "c" && ctrl) {
        const json = JSON.stringify(this.editor.document.toObject());
        navigator.clipboard.writeText(json).then(
          () => Notify.create(`Map data copied to clipboard!`),
          () => Notify.create(`Couldn't copy map data to clipboard!`)
        );
      }
      if (key === "z" && ctrl) {
        event.preventDefault();
        if (shift) {
          this.editor.actions.redo();
        } else {
          this.editor.actions.undo();
        }
      }
    },
    onKeyUp(event: KeyboardEvent): void {
      const tool = this.editor.getActiveTool();
      if (tool) {
        tool.onKeyUp(event);
      }
    },
  },
  created() {
    this.editor.setPrefab(this.createPrefab);
  },
  mounted() {
    this.containerElement = this.container();
    this.init();
    window.addEventListener("resize", this.onWindowResize);
    document.addEventListener("keydown", this.onKeyDown);
    document.addEventListener("keyup", this.onKeyUp);
    this.onWindowResize(); // set the initial canvas size
    this.editor.cameraControls.enabled = true;
    this.animate();
  },
  beforeUnmount() {
    this.editor.cameraControls.enabled = false;
    window.removeEventListener("resize", this.onWindowResize);
    document.removeEventListener("keydown", this.onKeyDown);
    document.removeEventListener("keyup", this.onKeyUp);
    this.unmounted = true;
  },
  setup() {
    const editor = inject<SceneEditor>("editor");
    if (!editor) {
      throw new Error("missing injection for editor");
    }
    return {
      createPrefab: inject<PieceTypes>("createPrefab"),
      editor,
      drawerOpen: inject<boolean>("drawerOpen"),
    };
  },
  watch: {
    createPrefab(value: PieceTypes) {
      this.editor?.setPrefab(value);
    },
    drawerOpen() {
      setTimeout(() => this.onWindowResize(), 100);
    },
  },
});
