import { IScenePiece } from "../../core/Document";
import { screenToCamera, TILE_SIZE } from "../../core/Scene";
import { SceneEditor } from "../SceneEditor";
import { PaintTilesAction } from "../SceneEditorActions";
import { SceneEditorTool } from "../SceneEditorTool";

/**
 * Prefab creation tool.
 */
export class PrefabTool extends SceneEditorTool {
  static NAME = "prefab";
  name = PrefabTool.NAME;
  tooltip = "Create new objects by clicking on the grid";
  icon = "approval";

  private overlapObjects: IScenePiece[] = [];

  activateTool(editor: SceneEditor): boolean {
    if (!super.activateTool(editor)) {
      return false;
    }
    this.ensurePrefab();
    return true;
  }

  /** Hide the "preview" shape if there is one. */
  escapeTool(): boolean {
    this.clearSelection();
    if (this.editor?.currentPrefab) {
      const { object } = this.editor.currentPrefab;
      object.visible = false;
    }
    return true;
  }

  clearSelection(): void {
    for (const obj of this.overlapObjects) {
      obj.instance.visible = true;
    }
    this.overlapObjects = [];
    if (this.editor?.currentPrefab) {
      this.editor.currentPrefab.object.visible = true;
    }
  }

  /** Ensure the preview prefab exists in the scene we're editing */
  ensurePrefab(): void {
    if (this.editor?.currentPrefab) {
      const { object } = this.editor.currentPrefab;
      const { scene } = this.editor.document.config;
      if (object.parent && object.parent.id !== scene.id) {
        object.parent.remove(object);
        scene.add(object);
      } else if (!object.parent) {
        scene.add(object);
      }
    }
  }

  /**
   * Determine if placing a prefab at the mouse location would work, and
   * if it would, move a "preview" prefab into position there to show what
   * will happen if you click.
   */
  onMouseMove(event: PointerEvent): void {
    if (!this.editor || !this.editor.host) {
      return;
    }
    this.ensurePrefab();
    const host = this.editor.host;
    const [x, y] = this.gridPointFromEvent(event);
    const [cameraX, cameraY] = screenToCamera(
      host.container(),
      event.offsetX,
      event.offsetY
    );
    if (!this.editor.currentPrefab) {
      return;
    }
    const hits = this.editor.pick(cameraX, cameraY);
    const { object, width, height, ofType } = this.editor.currentPrefab;
    const hit = hits[0];
    if (!hit) {
      object.visible = false;
    } else {
      object.position.copy(hit.point);
      // snap to grid in world position
      let targetX = object.position.x;
      const gridX = Math.floor(targetX / TILE_SIZE) * TILE_SIZE;
      let targetZ = object.position.z;
      const gridY = Math.floor(targetZ / TILE_SIZE) * TILE_SIZE;
      const willFit = this.editor.document.prefabWillBeInBounds(
        this.editor.currentPrefab,
        x,
        y
      );

      this.clearSelection();
      this.overlapObjects = this.editor.document.prefabCollidesWith(
        this.editor.currentPrefab,
        x,
        y
      );

      // Reposition the preview prefab
      targetX = gridX + width / 2;
      targetZ = gridY + height / 2;
      object.position.x = targetX;
      object.position.y = 0;
      object.position.z = targetZ;
      object.visible = willFit;

      // If we're overlapping only one tile and it's the same
      // one that we're painting, don't hide it.
      const overlap = this.overlapObjects[0];
      const singleOverlap = this.overlapObjects.length === 1;
      if (!singleOverlap || overlap.prefab.ofType !== ofType) {
        for (const obj of this.overlapObjects) {
          obj.instance.visible = false;
        }
        // Restore the preview prefab incase it was hidden
        object.visible = willFit;
      } else if (overlap.prefab.ofType === ofType) {
        // Here we're hovering over the exact prefab we are trying to create
        // so let's hide the prefab preview to avoid visual duplicates
        object.visible = false;
      }
    }
  }
  /** Place a prefab at the pointer location */
  onMouseUp(event: PointerEvent): void {
    if (event.button !== 0 || !this.editor?.host) {
      return;
    }
    const [x, y] = this.gridPointFromEvent(event);
    const piece = this.editor.document.pick(x, y);
    const current = this.editor.currentPrefab;
    // If an existing piece was hit
    if (piece) {
      // If it's the same type as we're painting, rotate the existing
      // object instead of painting over it.
      if (current?.ofType === piece.prefab.ofType) {
        // This path terminates with a rotate action
        this.rotate(piece.instance);
        return;
      }
    }

    // Mouse move toggles the current prefab visibility. If
    // it is hidden, then there is no valid spot to place it.
    if (!current || !current.object.visible) {
      return;
    }
    const newPiece = this.createFor(x, y);
    const prefabAction = new PaintTilesAction(
      [newPiece],
      this.overlapObjects,
      this.editor.document
    );
    this.editor.actions.executeAction(prefabAction);
    this.clearSelection();
    // Hide the preview because it will be right on top of the newly created tile
    current.object.visible = false;
  }
}
