import { render } from "./Toolbox.vue?vue&type=template&id=0947c7ba&scoped=true"
import script from "./Toolbox.vue?vue&type=script&lang=ts"
export * from "./Toolbox.vue?vue&type=script&lang=ts"

import "./Toolbox.vue?vue&type=style&index=0&id=0947c7ba&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-0947c7ba"

export default script
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QItem from 'quasar/src/components/item/QItem.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtnGroup,QBtn,QTooltip,QPopupEdit,QColor,QItem});qInstall(script, 'directives', {ClosePopup});
