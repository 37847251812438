import * as dat from "dat.gui";
import {
  AmbientLight,
  BoxGeometry,
  Color,
  DirectionalLight,
  DirectionalLightHelper,
  GridHelper,
  Group,
  Mesh,
  MeshBasicMaterial,
  NoToneMapping,
  Object3D,
  OrthographicCamera,
  PCFSoftShadowMap,
  PerspectiveCamera,
  PlaneGeometry,
  Scene,
  sRGBEncoding,
  Vector3,
  WebGLRenderer,
} from "three";

/** The size of a single tile in the grid */
export const TILE_SIZE = 1;

/** How many grid tiles */
export const GRID_SIZE = 10;

/** The intensity of the ambient scene light WITHOUT an environemnt map applied */
export const AMBIENT_INTENSITY_NO_ENV_MAP = 2.0;
/** The intensity of the ambient scene light WITH an environemnt map applied */
export const AMBIENT_INTENSITY_ENV_MAP = 0.3;

/** Default map background color */
export const DEFAULT_MAP_BG_HEX = 0xf0f0ff;

export const NULL_COORD = -Infinity;
/**
 * Point returned from screen/world to grid space coordinate conversions when the
 * input point does not fall within the valid grid spaces.
 */
export const NULL_POINT: [number, number] = [NULL_COORD, NULL_COORD];

export interface SceneConfig {
  scene: Scene;
  shapes: Group;
  seats: Group;
  rooms: Group;

  /** The grid that all objects must sit on top of */
  grid: Mesh<PlaneGeometry>;
  avatars: Group;
  /** The directional light for casting shadows */
  directionalLight: DirectionalLight;
  /** The ambient light present in the scene */
  ambientLight: AmbientLight;
  /** The args used to create the scene */
  createArgs: ISceneCreationArgs;
  /** Render shadows */
  shadows: boolean;
  /** Use an environment map for better scene lighting */
  envMap: string | null;
}

export function createRenderer(
  width: number,
  height: number,
  buffer = false,
  shadows = false
): [WebGLRenderer, PerspectiveCamera] {
  const renderer = new WebGLRenderer({
    antialias: true,
    preserveDrawingBuffer: buffer,
  });
  renderer.setSize(width, height);
  if (!buffer) {
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  }
  renderer.physicallyCorrectLights = true;
  renderer.outputEncoding = sRGBEncoding;
  renderer.toneMapping = NoToneMapping;

  if (shadows) {
    renderer.shadowMap.enabled = true;
  }

  // camera
  const camera = new PerspectiveCamera(45, width / height, 1, 10000);
  camera.position.set(TILE_SIZE, TILE_SIZE * 2, TILE_SIZE);
  camera.lookAt(0, 0, 0);
  return [renderer, camera];
}

export function createOrthoRenderer(
  width: number,
  height: number,
  shadows = false
): [WebGLRenderer, OrthographicCamera] {
  const renderer = new WebGLRenderer({ antialias: true });
  renderer.setSize(width, height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.physicallyCorrectLights = true;
  renderer.outputEncoding = sRGBEncoding;
  renderer.toneMapping = NoToneMapping;
  renderer.shadowMap.type = PCFSoftShadowMap;

  if (shadows) {
    renderer.shadowMap.enabled = true;
  }

  // camera
  const camera = new OrthographicCamera(-10, 10, 10, -10, 1, 10000);
  camera.position.set(TILE_SIZE, TILE_SIZE * 2, TILE_SIZE);
  camera.lookAt(0, 0, 0);
  return [renderer, camera];
}

export interface ISceneCreationArgs {
  gridSize: number;
  backgroundColor: number;
}

export function createEditorScene(
  editable: boolean,
  args?: Partial<ISceneCreationArgs>
): SceneConfig {
  const { gridSize = -1, backgroundColor = DEFAULT_MAP_BG_HEX } = args || {};
  const lights = new Group();
  lights.name = "Lights";
  const shapes = new Group();
  shapes.name = "Shapes";
  const seats = new Group();
  shapes.name = "Seats";
  const avatars = new Group();
  avatars.name = "Avatars";
  const rooms = new Group();
  rooms.name = "Rooms";

  // Ambient lighting (required for AO maps)
  const ambientLight = new AmbientLight(0xffffff, AMBIENT_INTENSITY_NO_ENV_MAP);
  lights.add(ambientLight);

  // Directional light
  const directionalLight = new DirectionalLight(0xffffff, 2.5);
  directionalLight.position.set(2, 10, 4.6);
  directionalLight.shadow.bias = -0.0005; // bias reduces self-shadowing on double-sided objects
  directionalLight.shadow.mapSize.setScalar(256); // smooth shadows are okay w/small textures
  lights.add(directionalLight);

  // three.js scene
  const scene = new Scene();
  scene.background = new Color(backgroundColor);
  scene.add(lights);
  scene.add(shapes);
  scene.add(seats);
  scene.add(rooms);
  scene.add(avatars);

  // grid helper
  if (editable && gridSize > 0) {
    const gridHelper = new GridHelper(TILE_SIZE * gridSize, gridSize);
    // Don't show through the floor
    gridHelper.position.y = -0.3;
    scene.add(gridHelper);
  }
  // hit testing grid
  const geometry = new PlaneGeometry(gridSize, gridSize);
  geometry.rotateX(-Math.PI / 2);
  const grid = new Mesh(geometry, new MeshBasicMaterial({ visible: false }));
  grid.name = "Grid";
  shapes.add(grid);

  // Add ground if not editable
  if (!editable) {
    const groundHeight = 0.6;
    const prefabBorder = 0.3;
    const groundGeometry = new BoxGeometry(
      TILE_SIZE * gridSize,
      groundHeight,
      TILE_SIZE * gridSize
    );
    const groundMaterial = new MeshBasicMaterial({
      // color: 0x508843, // green (okay, light)
      // color: 0x32552A, // green (dark, ...)
      // color: 0x5b4b34, // brown (dark, ...)
      color: 0x4d3724, // brown (dark, ...),
    });
    const ground = new Mesh(groundGeometry, groundMaterial);
    ground.position.y = -(groundHeight / 2 + prefabBorder);
    scene.add(ground);
  }

  const ed: SceneConfig = {
    scene,
    shapes,
    avatars,
    seats,
    rooms,
    grid,
    directionalLight,
    ambientLight,
    shadows: !editable,
    envMap: null,
    createArgs: {
      backgroundColor,
      gridSize,
    },
  };
  return ed;
}

export function addDebugTweaks(
  gui: dat.GUI,
  ed: SceneConfig,
  lightHelpers = false,
  changeFn?: () => void
): void {
  const changed = () => {
    if (changeFn) {
      changeFn();
    }
  };
  gui
    .add(ed.directionalLight, "intensity")
    .min(0)
    .max(10)
    .step(0.01)
    .onChange(changed)
    .name("dirLightIntensity");
  gui
    .add(ed.directionalLight.position, "x")
    .min(0)
    .max(50)
    .step(0.01)
    .onChange(changed)
    .name("dirLightX");
  gui
    .add(ed.directionalLight.position, "y")
    .min(0)
    .max(100)
    .step(0.01)
    .onChange(changed)
    .name("dirLightY");
  gui
    .add(ed.directionalLight.position, "z")
    .min(0)
    .max(50)
    .step(0.01)
    .onChange(changed)
    .name("dirLightZ");

  gui
    .add(ed.ambientLight, "intensity")
    .min(0)
    .max(10)
    .step(0.001)
    .onChange(changed)
    .name("ambientLightIntensity");
  gui
    .add(ed.ambientLight.position, "x")
    .min(0)
    .max(50)
    .step(0.001)
    .onChange(changed)
    .name("ambientLightX");
  gui
    .add(ed.ambientLight.position, "y")
    .min(0)
    .max(50)
    .step(0.001)
    .onChange(changed)
    .name("ambientLightY");
  gui
    .add(ed.ambientLight.position, "z")
    .min(0)
    .max(50)
    .step(0.001)
    .onChange(changed)
    .name("ambientLightZ");

  if (lightHelpers) {
    // Light source that provides shadows
    const dirHelper = new DirectionalLightHelper(ed.directionalLight);
    ed.scene.add(dirHelper);
    // Shadow camera (where shadows are drawn)
    // const cameraHelper = new CameraHelper(ed.directionalLight.shadow.camera);
    // ed.scene.add(cameraHelper);
    // Ambient light
    // const ambientHelper = new AmbientLightHelper(ed.ambientLight, 5);
    // ed.scene.add(ambientHelper);
  }
}

/** Convert an x/y point from screen space to world camera space */
export function screenToCamera(
  view: HTMLElement,
  x: number,
  y: number
): [number, number] {
  return [(x / view.clientWidth) * 2 - 1, -(y / view.clientHeight) * 2 + 1];
}

export function getPrefabRoot(object: Object3D): Object3D | null {
  let parent: Object3D | null = object;
  while (parent) {
    if (parent.name === "PrefabRoot") {
      return parent;
    }
    parent = parent.parent;
  }
  return null;
}

export function snapObjectToGrid(
  object: Object3D,
  target: Vector3,
  gridWidth: number,
  gridHeight: number
): [number, number] {
  object.position.copy(target);
  // snap to grid in world position
  let x = object.position.x;
  const gridX = Math.floor(x / TILE_SIZE) * TILE_SIZE;
  x = gridX + gridWidth / 2;
  let z = object.position.z;
  const gridY = Math.floor(z / TILE_SIZE) * TILE_SIZE;
  z = gridY + gridHeight / 2;
  object.position.x = x;
  object.position.y = 0;
  object.position.z = z;
  return [gridX, gridY];
}

/** Wrap rotation around so it's always in the range 0-360 */
export function normalizeRotation(degrees: number, inclusive = false): number {
  let result = degrees;
  if (result < 0) {
    const leftOver = -(Math.abs(result) % 360);
    result = 360 + leftOver;
  } else if ((!inclusive && result > 360) || (inclusive && result >= 360)) {
    result = result % 360;
  }
  return result;
}

export function setChildShadows(child: Object3D, enabled: boolean): void {
  if (child instanceof Mesh) {
    if (!enabled) {
      child.receiveShadow = false;
      child.castShadow = false;
      return;
    }
    const lower = child.name.toLowerCase();
    if (!lower.startsWith("wall")) {
      child.castShadow = true;
      child.receiveShadow = true;
    }
    if (lower.startsWith("floor")) {
      child.castShadow = false;
    }
  }
}
