import {
  Group,
  Intersection,
  Object3D,
  OrthographicCamera,
  PerspectiveCamera,
  Raycaster,
  Vector2,
} from "three";
import { BaseDocument } from "./Document";

/** Pick items from a set. */
export interface IPicker<T> {
  // Single
  pickFirst(point: Vector2): T | null;
  pick(point: Vector2): T[];
  pickSpecific(point: Vector2, filterFn: (hit: T) => boolean): T | null;
}

export class ThreeJSPicker implements IPicker<Intersection> {
  pointer = new Vector2();
  raycaster = new Raycaster();
  public recursive = true;

  constructor(
    public target: BaseDocument | Group | Object3D[] | Object3D,
    private camera: PerspectiveCamera | OrthographicCamera
  ) {}

  pickSpecific(
    point: Vector2,
    filterFn: (intersection: Intersection) => boolean
  ): Intersection | null {
    const intersects = this.pick(point);
    return intersects.find(filterFn) || null;
  }

  pickFirst(point: Vector2): Intersection | null {
    const intersects = this.pick(point);
    if (intersects.length > 0) {
      return intersects[0];
    }
    return null;
  }
  pick(point: Vector2): Intersection[] {
    let searchChildren: Object3D[];
    if (this.target instanceof BaseDocument) {
      searchChildren = this.target.config.shapes.children;
    } else if (this.target instanceof Group) {
      searchChildren = this.target.children;
    } else if (this.target instanceof Object3D) {
      searchChildren = [this.target];
    } else {
      searchChildren = this.target;
    }
    this.raycaster.setFromCamera(point, this.camera);
    return this.raycaster.intersectObjects(searchChildren, this.recursive);
  }
}
