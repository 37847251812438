import { Vector2 } from "three";
import { IScenePiece } from "../../core/Document";
import { PaintTilesAction } from "../SceneEditorActions";
import { SceneEditorTool } from "../SceneEditorTool";
/**
 * Pencil tool for drawing 1x1 prefab tiles on the grid.
 */
export class PencilTool extends SceneEditorTool {
  static NAME = "pencil";
  name = PencilTool.NAME;
  tooltip = "Draw single-tile prefabs by dragging on the grid";
  icon = "edit";
  private dragStart: Vector2 = new Vector2();
  private dragObjects: {
    create: { [objectId: string]: IScenePiece };
    remove: { [objectId: string]: IScenePiece };
  } | null = null;

  escapeTool(): boolean {
    this.cancelPaint(); // clean up any active drag
    return super.escapeTool();
  }

  /** Cancel the current paint operation */
  cancelPaint(): void {
    if (!this.dragObjects || !this.editor) {
      return;
    }
    const removeObjects: IScenePiece[] = Object.values(
      this.dragObjects?.remove || {}
    );
    // Add removed shapes back to scene
    removeObjects.forEach((r) => {
      this.editor?.document.addPiece(
        r.prefab,
        r.instance,
        r.box.min.x,
        r.box.min.y,
        r.rotation
      );
    });

    // Destroy the temporary new pieces
    const createObjects: IScenePiece[] = Object.values(
      this.dragObjects?.create || {}
    );
    createObjects.forEach((c) => {
      this.editor?.document.removePiece(c.instance);
    });
    this.dragObjects = null;
    this.dragStart.set(-1, -1);
  }

  onMouseMove(event: PointerEvent): void {
    if (event.buttons > 1 || !this.editor?.host || this.dragObjects === null) {
      return;
    }

    // Find a prefab
    const [x, y] = this.gridPointFromEvent(event);
    if (!this.editor.document.pointInGrid(x, y)) {
      return;
    }
    const piece = this.editor.document.pick(x, y);
    // There's a piece, not already in the remove/create list
    if (piece) {
      const isRemoved = piece.instance.id in this.dragObjects.remove;
      const isCreated = piece.instance.id in this.dragObjects.create;
      if (isCreated || isRemoved) {
        return;
      }

      // Need to remove the prefab and replace with a create
      this.dragObjects.remove[piece.instance.id] = piece;
      // Remove from document.
      this.editor.document.removePiece(piece.instance);

      // Create an object in the event location
      const newPiece = this.createFor(x, y);
      this.dragObjects.create[newPiece.instance.id] = newPiece;
    } else {
      // Create an object in the event location
      const newPiece = this.createFor(x, y);
      this.dragObjects.create[newPiece.instance.id] = newPiece;
    }
  }
  onMouseUp(event: PointerEvent): void {
    const removeObjects: IScenePiece[] = Object.values(
      this.dragObjects?.remove || {}
    );
    const createObjects: IScenePiece[] = Object.values(
      this.dragObjects?.create || {}
    );
    this.dragObjects = null;
    if (event.button !== 0 || !this.editor?.host) {
      return;
    }
    // Look for objects under the pointer if there was no drag selection.
    if (removeObjects.length === 0 && createObjects.length === 0) {
      const tolerance = 10;
      const deltaX = Math.abs(this.dragStart.x - event.clientX);
      const deltaY = Math.abs(this.dragStart.y - event.clientY);
      const isClick = deltaX < tolerance && deltaY < tolerance;
      if (isClick) {
        const [x, y] = this.gridPointFromEvent(event);
        if (!this.editor.document.pointInGrid(x, y)) {
          return;
        }
        const piece = this.editor.document.pick(x, y);
        // If an existing piece was hit
        if (piece) {
          const current = this.editor.currentPrefab;
          // If it's the same type as we're painting, rotate the existing
          // object instead of painting over it.
          if (current?.ofType === piece.prefab.ofType) {
            // This path terminates with a rotate action
            this.rotate(piece.instance);
            return;
          }
          // add to remove objects if it's a different tile.
          removeObjects.push(piece);
        }
        const newPiece = this.createFor(x, y);
        createObjects.push(newPiece);
      }
    }
    if (createObjects.length > 0 || removeObjects.length > 0) {
      const prefabAction = new PaintTilesAction(
        createObjects,
        removeObjects,
        this.editor.document
      );
      this.editor.actions.executeAction(prefabAction);
    } else {
      this.cancelPaint();
    }
  }
  onMouseDown(event: PointerEvent): void {
    if (event.button !== 0 || !this.editor) {
      return;
    }
    this.cancelPaint();
    this.dragStart.set(event.clientX, event.clientY);
    this.dragObjects = { create: {}, remove: {} };
    return;
  }
}
