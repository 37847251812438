<template>
  <!-- Toolbox -->
  <q-btn-group class="toolbox" push>
    <q-btn
      flat
      color="white"
      v-for="tool in editor.tools"
      v-bind:key="tool"
      @click="setTool(tool)"
      :class="{
        selected: activeTool == tool.name,
      }"
      :icon="tool.icon"
    >
      <q-tooltip anchor="center right" self="center start">{{
        tool.tooltip
      }}</q-tooltip>
    </q-btn>
    <hr />
    <q-btn flat icon="undo" @click="undo()" :disable="undoCount == 0"
      ><q-tooltip anchor="center right" self="center start"
        >Undo the last action</q-tooltip
      ></q-btn
    >
    <q-btn flat icon="redo" @click="redo()" :disable="redoCount == 0"
      ><q-tooltip anchor="center right" self="center start"
        >Redo the last action</q-tooltip
      ></q-btn
    >
    <q-btn flat icon="delete" @click="clearScene()"
      ><q-tooltip anchor="center right" self="center start"
        >Delete All Prefabs</q-tooltip
      ></q-btn
    >
    <q-btn flat icon="photo_camera" @click="resetCamera()"
      ><q-tooltip anchor="center right" self="center start"
        >Reset the Camera</q-tooltip
      ></q-btn
    >
    <hr class="no-margin" />
    <q-btn flat icon="wallpaper" :style="`background: ${hex};`"
      ><q-popup-edit v-model="hex" anchor="top right" :cover="false">
        <q-color
          @change="setColor()"
          v-close-popup
          v-model="hex"
          no-header
          no-footer
          default-view="palette"
          class="my-picker no-margin"
        /> </q-popup-edit
      ><q-tooltip anchor="center right" self="center start"
        >Select the background color</q-tooltip
      ></q-btn
    >
  </q-btn-group>
</template>

<script lang="ts">
import { IEditorTool } from "@/welo/core/Editor";
import { SCENE_EDITOR } from "@/welo/core/Singletons";
import { SceneEditor } from "@/welo/editor/SceneEditor";
import {
  ClearSceneAction,
  ResetCameraAction,
  SetBackgroundAction,
} from "@/welo/editor/SceneEditorActions";
import { Color } from "three";
import { defineComponent, inject, provide, ref } from "vue";
import { ISceneSerialized } from "../welo/core/Document";
import { DEFAULT_MAP_BG_HEX } from "../welo/core/Scene";
import { CameraTool } from "../welo/editor/tools/CameraTool";

export default defineComponent({
  name: "Toolbox",
  methods: {
    clearScene() {
      this.editor.actions.executeAction(new ClearSceneAction(this.editor));
    },
    resetCamera() {
      this.editor.actions.executeAction(
        new ResetCameraAction(this.editor.document, this.editor.cameraControls)
      );
    },
    setColor() {
      const newColor = new Color(this.hex);
      this.editor.actions.executeAction(
        new SetBackgroundAction(newColor, this.editor.document)
      );
    },

    setTool(tool: IEditorTool) {
      this.activeTool = tool.name;
      this.editor.setActiveTool(tool.name);
    },
    undo() {
      this.editor.actions.undo();
      this.updateActionsState();
    },
    redo() {
      this.editor.actions.redo();
      this.updateActionsState();
    },
    updateActionsState() {
      this.undoCount = this.editor.actions.getUndoCount();
      this.redoCount = this.editor.actions.getRedoCount();
      this.activeTool = this.editor.getActiveTool()?.name || CameraTool.NAME;
      const mapColor = this.editor.document.config.createArgs.backgroundColor;
      this.hex = `#${new Color(mapColor).getHexString()}`;
    },
  },
  mounted() {
    const emitter = this.editor.actions.events;
    emitter.addEventListener("do", this.updateActionsState);
    emitter.addEventListener("undo", this.updateActionsState);
    emitter.addEventListener("redo", this.updateActionsState);
    this.editor.events.addEventListener("change", this.updateActionsState);
  },
  beforeUnmount() {
    const emitter = this.editor.actions.events;
    emitter.removeEventListener("do", this.updateActionsState);
    emitter.removeEventListener("undo", this.updateActionsState);
    emitter.removeEventListener("redo", this.updateActionsState);
    this.editor.events.removeEventListener("change", this.updateActionsState);
  },
  setup() {
    const editor = inject<SceneEditor>("editor");
    const viewMap = inject<ISceneSerialized>("viewMap");
    const activeTool = inject<string>("activeTool");
    if (!editor || !viewMap || !activeTool) {
      throw new Error("missing injections");
    }

    const undoCount = ref(0);
    const redoCount = ref(0);
    const mapColor =
      SCENE_EDITOR.document.config.createArgs.backgroundColor ||
      DEFAULT_MAP_BG_HEX;
    const background = `#${new Color(mapColor).getHexString()}`;
    const hex = ref(background);
    provide("undoCount", undoCount);
    provide("redoCount", redoCount);
    return {
      viewMap,
      editor,
      undoCount,
      redoCount,
      activeTool,
      hex,
    };
  },

  watch: {
    viewMap() {
      const mapColor = this.viewMap.args.backgroundColor || DEFAULT_MAP_BG_HEX;
      this.hex = `#${new Color(mapColor).getHexString()}`;
    },
    hex() {
      if (this.editor) {
        const newColor = new Color(this.hex);
        this.editor.document.setColor(newColor);
      }
    },
  },
});
</script>

<style scoped>
.q-tooltip {
  font-size: 1em;
}
.toolbox .q-item {
  padding: 0;
  border: 2px solid white;
}
.toolbox .q-item.selected {
  border: 2px solid darkgrey;
}
.toolbox {
  border: 2px solid white;
  background-color: var(--q-primary);
  color: white;
  position: fixed;
  top: 64px;
  left: 10px;
  z-index: 1;
  flex-direction: column;
  overflow: hidden;
}
.toolbox > button {
  border-radius: 0 !important;
}
.toolbox > hr {
  width: 100%;
  border-color: white;
}
.toolbox > .selected {
  background-color: var(--q-dark);
}

.q-popup-edit {
  padding: 0;
}
</style>
