<template>
  <div
    id="office-view"
    ref="sceneContainer"
    @dblclick="onDoubleClick"
    @pointermove="onPointerMove"
    @pointerup="onPointerUp"
    @pointerdown="onPointerDown"
  ></div>
  <status-bar source="office" :frames="true"></status-bar>
</template>

<script lang="ts">
import { ThreeJSPicker } from "@/welo/core/Picker";
import { Prefab } from "@/welo/core/Prefabs";
import { addDebugTweaks, screenToCamera, NULL_COORD } from "@/welo/core/Scene";
import { SceneEditor } from "@/welo/editor/SceneEditor";
import { OfficeCameraControls } from "@/welo/office/OfficeCameraControls";
import { OfficeDocument } from "@/welo/office/OfficeDocument";
import * as TWEEN from "@tweenjs/tween.js";
import * as dat from "dat.gui";
import { throttle } from "quasar";
import Stats from "stats.js";
import { Intersection, Object3D, Vector2, Vector3 } from "three";
import { defineComponent, inject } from "vue";
import StatusBar from "../components/StatusBar.vue";
import { ISceneSerialized } from "../welo/core/Document";
import { Avatar } from "../welo/objects/Avatar";

export interface OfficeViewData {
  prefabs: Prefab[];
  unmounted: boolean;
  containerElement: HTMLElement | null;
  cameraControls: OfficeCameraControls;
  picker: ThreeJSPicker | null;
  dragStart: Vector2;
  pointer: Vector2;
  avatar: Avatar | null;
  hoverSeat: Object3D | null;
  lastPointerMove: Vector2;
  throttleAnimate: ((delta?: number) => void) | null;
  throttleHit: ((delta?: number) => void) | null;
}
const useDebugTweaks = false;
const useFPSTweaks = false;
let STATIC_GUI: dat.GUI | null = null;
let STATIC_FPS: any | null = null;

export default defineComponent({
  name: "OfficeView",
  components: {
    StatusBar,
  },
  data() {
    return {
      unmounted: false,
      containerElement: null,
      picker: null,
      pointer: new Vector2(),
      dragStart: new Vector2(),
      throttleAnimate: null,
      throttleHit: null,
    } as unknown as OfficeViewData;
  },
  watch: {
    viewMap() {
      this.syncMap();
    },
  },
  methods: {
    init(): void {
      this.lastPointerMove = new Vector2(-1, -1);
      this.unmounted = false;
      this.throttleAnimate = throttle(this.animate, 10);
      this.throttleHit = throttle(this.hitTestSeats, 10);
      const container = this.container();
      this.hoverSeat = null;
      const { renderer, camera } = this.office;
      this.office.resetCamera();
      if (container) {
        container.appendChild(renderer.domElement);
      }
      this.cameraControls = new OfficeCameraControls(
        camera,
        renderer.domElement
      );
      this.syncMap();
    },

    syncMap(): void {
      let loadMap: Promise<OfficeDocument>;
      if (this.office.updated === -1 && this.editor.document.updated === -1) {
        loadMap = this.office.load(this.viewMap);
      } else if (this.editor.document.updated !== this.office.updated) {
        const editorLatest = this.editor.document.updated > this.office.updated;
        loadMap = editorLatest
          ? this.office.syncWith(this.editor.document)
          : this.office.load(this.viewMap);
      } else {
        this.picker = new ThreeJSPicker(
          this.office.config.seats,
          this.office.camera
        );
        return;
      }
      if (this.avatar) {
        this.avatar.geometry.dispose();
        this.avatar = null;
      }
      loadMap.then(() => {
        this.picker = new ThreeJSPicker(
          this.office.config.seats,
          this.office.camera
        );

        if (this.office && useFPSTweaks && STATIC_FPS === null) {
          STATIC_FPS = new Stats();
          STATIC_FPS.showPanel(1); // mspf
          document.body.appendChild(STATIC_FPS.dom);
        }

        if (this.office && useDebugTweaks && STATIC_GUI === null) {
          STATIC_GUI = new dat.GUI();
          STATIC_GUI.domElement.id = "gui";
          addDebugTweaks(STATIC_GUI, this.office.config, true, this.changed);
        }
        this.office.setShadows(this.currentShadows || false);
        this.office.setEnvironmentMap(this.currentEnvMap || null).then(() => {
          this.changed();
        });
      });
    },
    changed(): void {
      if (this.throttleAnimate !== null) {
        requestAnimationFrame(this.throttleAnimate);
      }
    },
    onPointerMove(event: MouseEvent): void {
      if (event.buttons > 0) {
        return;
      }
      this.lastPointerMove.set(event.offsetX, event.offsetY);
      if (this.throttleHit !== null) {
        requestAnimationFrame(this.throttleHit);
      }
    },
    clearHover(quiet = false): void {
      if (this.hoverSeat !== null) {
        this.hoverSeat.visible = false;
        this.hoverSeat = null;
        if (!quiet) {
          this.changed();
        }
      }
    },

    /** Determine which seat (if any) is under the given screen coordinates */
    getHitSeat(screenX: number, screenY: number): Intersection | null {
      if (!this.picker) {
        return null;
      }
      const view = this.container();
      // For hit-testing specific seats in a piece
      const [cameraX, cameraY] = screenToCamera(view, screenX, screenY);
      let [gridX, gridY] = this.office.gridPointFromScreen(
        view,
        screenX,
        screenY
      );
      // Quick out-of-bounds check
      if (gridX === NULL_COORD || gridY === NULL_COORD) {
        return null;
      }
      const [lookupX, lookupY] = this.office.gridPointToLookup(gridX, gridY);
      const lookup = this.office.getPieceLookup();
      const piece = lookup[lookupX][lookupY];
      if (!piece) {
        return null;
      }

      this.picker.target = piece.seats;
      this.pointer.set(cameraX, cameraY);
      return this.picker.pickFirst(this.pointer);
    },
    /**
     * Determine if there is a seat under the pointer position currently, and
     * if the state changed from the last check, render a frame.
     */
    hitTestSeats(): void {
      const container = this.container();
      if (!this.picker || !container) {
        return;
      }
      const hit = this.getHitSeat(
        this.lastPointerMove.x,
        this.lastPointerMove.y
      );
      if (!hit) {
        this.clearHover();
        return;
      }
      // Hover changes
      let changed = false;
      if (this.hoverSeat !== null && this.hoverSeat.id !== hit.object.id) {
        this.clearHover(true);
        changed = true;
      }
      // Seat being set for the first time
      if (this.hoverSeat === null) {
        changed = true;
      }
      // update hit set and make visible
      this.hoverSeat = hit.object;
      this.hoverSeat.visible = true;

      // re-render if something changed
      if (changed) {
        this.changed();
      }
    },
    onDoubleClick(event: MouseEvent): void {
      // Get hit prefab, and choose a random seat
      if (!this.picker) {
        return;
      }
      const view = this.container();
      let [gridX, gridY] = this.office.gridPointFromScreen(
        view,
        event.offsetX,
        event.offsetY
      );
      // Quick out-of-bounds check
      if (gridX === NULL_COORD || gridY === NULL_COORD) {
        return;
      }
      const [lookupX, lookupY] = this.office.gridPointToLookup(gridX, gridY);
      const lookup = this.office.getPieceLookup();
      const piece = lookup[lookupX][lookupY];
      if (!piece || piece.seats.children.length === 0) {
        return;
      }
      const items = piece.seats.children;
      const [worldX, worldY] = this.office.worldPointFromScreen(
        view,
        event.offsetX,
        event.offsetY
      );
      // Cursor - Piece = Prefab local coordinates
      const referencePoint = new Vector3(
        worldX - piece.instance.position.x,
        0,
        worldY - piece.instance.position.z
      );
      const closest: { distance: number; seat: Object3D | null } = {
        distance: Infinity,
        seat: null,
      };
      for (const seat of piece.seats.children) {
        const distance = referencePoint.distanceTo(seat.position);
        if (distance < closest.distance) {
          closest.distance = distance;
          closest.seat = seat;
        }
      }
      let seat: Object3D;
      if (closest.seat !== null) {
        seat = closest.seat;
      } else {
        seat = items[Math.floor(Math.random() * items.length)];
      }
      this.claimSeat(seat);
    },
    onPointerDown(event: MouseEvent): void {
      this.dragStart.set(event.clientX, event.clientY);
    },
    onPointerUp(event: MouseEvent): void {
      if (!this.picker) {
        return;
      }
      const tolerance = 10;
      const deltaX = Math.abs(this.dragStart.x - event.clientX);
      const deltaY = Math.abs(this.dragStart.y - event.clientY);
      const isClick = deltaX < tolerance && deltaY < tolerance;
      if (isClick) {
        const hit = this.getHitSeat(event.offsetX, event.offsetY);
        if (!hit) {
          return;
        }
        this.claimSeat(hit.object);
      }
    },

    claimSeat(seat: Object3D): void {
      if (!this.avatar) {
        const av = new Avatar();
        this.avatar = av;
        this.avatar.load().then(() => {
          seat.getWorldPosition(av.position);
          this.office.config.avatars.add(av);
          this.changed();
        });
      } else {
        seat.getWorldPosition(this.avatar.position);
        this.changed();
      }
    },

    animate(deltaTime = 0.0): void {
      if (this.unmounted) {
        return;
      }
      const { camera, renderer } = this.office;
      if (STATIC_FPS) {
        STATIC_FPS.update();
      }
      try {
        if (this.cameraControls.enabled) {
          this.cameraControls.update();
        }
        this.office.render(renderer, camera);
        TWEEN.update(deltaTime);
      } catch (e) {
        console.error("Failed to render with error", e);
        this.unmounted = true;
      }
    },
    container(): HTMLElement {
      const container = this.$refs.sceneContainer as HTMLElement;
      return container;
    },
    onWindowResize(): void {
      const container = this.container();
      const { camera, renderer } = this.office;
      const width = container.clientWidth;
      const height = container.clientHeight;
      renderer.setSize(width, height);
      // update the camera
      camera.left = -width / camera.zoom;
      camera.right = width / camera.zoom;
      camera.top = height / camera.zoom;
      camera.bottom = -height / camera.zoom;
      camera.updateProjectionMatrix();
      this.changed();
    },
  },
  mounted(): void {
    // if (useFPSTweaks) {
    //   const stats = new Stats();
    // }

    this.containerElement = this.container();
    this.init();
    window.addEventListener("resize", this.onWindowResize);
    this.cameraControls.events.addEventListener("change", this.changed);
    this.office.events.addEventListener("loaded", this.changed);
    this.office.events.addEventListener("render", this.changed);
    this.office.events.addEventListener("reset", this.changed);
    this.office.events.addEventListener("change", this.changed);
    this.onWindowResize(); // set the initial canvas size
    this.animate();
  },
  beforeUnmount(): void {
    if (this.avatar) {
      this.office.config.avatars.remove(this.avatar);
      if (this.avatar) {
        this.avatar.geometry.dispose();
        this.avatar.material.dispose();
        this.avatar = null;
      }
    }
    this.cameraControls.dispose();
    window.removeEventListener("resize", this.onWindowResize);
    this.cameraControls.events.removeEventListener("change", this.changed);
    this.office.events.removeEventListener("render", this.changed);
    this.office.events.removeEventListener("loaded", this.changed);
    this.office.events.removeEventListener("reset", this.changed);
    this.office.events.removeEventListener("change", this.changed);
    this.unmounted = true;
  },
  setup() {
    const office = inject<OfficeDocument>("office");
    const editor = inject<SceneEditor>("editor");
    const viewMap = inject<ISceneSerialized>("viewMap");
    if (!office || !editor || !viewMap) {
      throw new Error("missing required injections");
    }
    return {
      office,
      editor,
      viewMap,
      currentEnvMap: inject<string>("currentEnvMap"),
      currentShadows: inject<boolean>("currentShadows"),
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#office-view {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
