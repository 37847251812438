
import { CameraControls } from "@/welo/core/CameraControls";
import { ThreeJSPicker } from "@/welo/core/Picker";
import { getPrefab, PieceTypes } from "@/welo/core/Prefabs";
import {
  addDebugTweaks,
  createEditorScene,
  createRenderer
} from "@/welo/core/Scene";
import * as dat from "dat.gui";
import {
  Group, Object3D,
  PerspectiveCamera,
  Scene,
  Vector2,
  WebGLRenderer
} from "three";
import { defineComponent } from "vue";
import { Avatar } from "../welo/objects/Avatar";
import { Seat } from "../welo/objects/Seat";

interface PrefabEditorData {
  name: PieceTypes;
  renderer: WebGLRenderer;
  scene: Scene;
  camera: PerspectiveCamera;
  mesh: Object3D;
  unmounted: boolean;
  picker: ThreeJSPicker;
  dragStart: Vector2;
  pointer: Vector2;
  seats: Group;
  avatar: Avatar | null;
  hoverSeat: Object3D | null;
}

const useDebugTweaks = false;
let STATIC_GUI: dat.GUI | null = null;

export default defineComponent({
  name: "PrefabEditor",
  data() {
    return {
      unmounted: false,
      pointer: new Vector2(),
      dragStart: new Vector2(),
    } as PrefabEditorData;
  },
  props: ["name"],
  methods: {
    init() {
      this.avatar = null;
      const container = this.container();
      const edScene = createEditorScene(true, { gridSize: 10 });
      const { scene } = edScene;
      const [renderer, camera] = createRenderer(
        container.clientWidth,
        container.clientHeight
      );
      this.scene = scene;
      this.renderer = renderer;
      this.camera = camera;
      if (useDebugTweaks && STATIC_GUI === null) {
        STATIC_GUI = new dat.GUI();
        STATIC_GUI.domElement.id = "gui";
        addDebugTweaks(STATIC_GUI, edScene);
      }
      container.appendChild(this.renderer.domElement);
      // Seat selection
      this.seats = new Group();
      this.scene.add(this.seats);
      this.hoverSeat = null;
      this.picker = new ThreeJSPicker(this.seats, this.camera);
      // camera controls
      const controls = new CameraControls(
        this.camera,
        this.renderer.domElement
      );
      controls.enablePan = false;
      controls.enableKeys = false;
      controls.minDistance = 1;
      controls.maxDistance = 50;
      controls.target.set(0, 0, 0);
      controls.update();
      this.resetCamera();
    },
    resetCamera() {
      this.camera.position.set(5, 10, 5);
      this.camera.zoom = 1.5;
      this.camera.lookAt(0, 0, 0);
    },
    setPrefab(ofType: PieceTypes) {
      getPrefab(ofType).then((prefab) => {
        if (this.mesh) {
          this.scene.remove(this.mesh);
          this.seats.clear();
        }
        if (this.avatar) {
          this.scene.remove(this.avatar);
          this.avatar = null;
        }
        this.mesh = prefab.object.clone();
        this.scene.add(this.mesh);
        this.mesh.traverse((child: Object3D) => {
          if (!child.name.toLowerCase().startsWith("seat")) {
            return;
          }
          const seatMesh = new Seat(child);
          this.seats.add(seatMesh);
        });
      });
    },
    container(): HTMLElement {
      const container = this.$refs.sceneContainer as HTMLElement;
      return container;
    },
    onWindowResize() {
      const container = this.container();
      const width = container.clientWidth;
      const height = container.clientHeight;

      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(width, height);
    },
    onPointerMove(event: MouseEvent) {
      const [worldX, worldY] = this.screenToCamera(
        event.offsetX,
        event.offsetY
      );
      this.pointer.set(worldX, worldY);
      const hit = this.picker.pickFirst(this.pointer);
      if (!hit) {
        if (this.hoverSeat) {
          this.hoverSeat.visible = false;
          this.hoverSeat = null;
        }
        return;
      }
      if (this.hoverSeat && this.hoverSeat !== hit.object) {
        this.hoverSeat.visible = false;
        this.hoverSeat = null;
      }
      this.hoverSeat = hit.object;
      this.hoverSeat.visible = true;
    },
    onPointerDown(event: MouseEvent) {
      this.dragStart.set(event.clientX, event.clientY);
    },
    onPointerUp(event: MouseEvent) {
      const tolerance = 10;
      const deltaX = Math.abs(this.dragStart.x - event.clientX);
      const deltaY = Math.abs(this.dragStart.y - event.clientY);
      const isClick = deltaX < tolerance && deltaY < tolerance;
      if (isClick && this.hoverSeat) {
        const [worldX, worldY] = this.screenToCamera(
          event.offsetX,
          event.offsetY
        );
        this.pointer.set(worldX, worldY);
        const hit = this.picker.pickFirst(this.pointer);
        if (!hit) {
          return;
        }
        if (this.avatar === null) {
          const av = new Avatar();
          const avPos = this.hoverSeat.position;
          this.avatar = av;
          this.avatar.load().then(() => {
            av.position.copy(avPos);
            this.scene.add(av);
          });
        } else {
          this.avatar.position.copy(this.hoverSeat.position);
        }
      }
    },
    /** Convert an x/y point from screen space to camera space */
    screenToCamera(x: number, y: number): [number, number] {
      const container = this.container();
      return [
        (x / container.clientWidth) * 2 - 1,
        -(y / container.clientHeight) * 2 + 1,
      ];
    },
    animate() {
      if (this.unmounted) {
        return;
      }
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },
  },
  beforeUnmount() {
    this.unmounted = true;
    const container = this.container();
    window.removeEventListener("resize", this.onWindowResize);
    container.removeEventListener("pointermove", this.onPointerMove);
    container.removeEventListener("pointerdown", this.onPointerDown);
    container.removeEventListener("pointerup", this.onPointerUp);
  },
  mounted() {
    this.init();
    const container = this.container();
    window.addEventListener("resize", this.onWindowResize);
    container.addEventListener("pointermove", this.onPointerMove);
    container.addEventListener("pointerdown", this.onPointerDown);
    container.addEventListener("pointerup", this.onPointerUp);
    this.animate();
    this.setPrefab(this.name);
  },
  watch: {
    name(val: PieceTypes) {
      this.setPrefab(val);
    },
  },
});
