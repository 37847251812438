import throttle from 'quasar/src/utils/throttle.js';;
import {
  OrthographicCamera,
  PMREMGenerator,
  Texture,
  UnsignedByteType,
  WebGLRenderer,
} from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { BaseDocument, ISceneSerialized } from "../core/Document";
import { ENVIRONMENT_MAPS, IEnvironmentMap } from "../core/EnvironmentMaps";
import {
  AMBIENT_INTENSITY_ENV_MAP,
  AMBIENT_INTENSITY_NO_ENV_MAP,
  createOrthoRenderer,
  setChildShadows,
} from "../core/Scene";

export class OfficeDocument extends BaseDocument {
  public renderer: WebGLRenderer;
  public camera: OrthographicCamera;
  protected pmremGenerator: PMREMGenerator;

  constructor(public name: string) {
    super(name, { gridSize: 16 }, false);
    const [renderer, camera] = createOrthoRenderer(
      640,
      480,
      this.config.shadows
    );
    this.renderer = renderer;
    this.camera = camera;
    this.pmremGenerator = new PMREMGenerator(renderer); // for environment map
  }

  public finalizeLoad(object: ISceneSerialized): Promise<void> {
    return new Promise((resolve) => {
      this.setShadows(this.config.shadows);
      this.setEnvironmentMap(this.config.envMap).then(() => resolve());
    });
  }

  /** Dispatch an event indicating the render needs to be updated for this document */
  public renderDirty = throttle(() => {
    this.events.dispatchEvent({ type: "render", document: this });
  }, 10);

  /** Reset the scene camera to the default */
  public resetCamera(): void {
    this.camera.position.set(20, 25, 20);
    this.camera.zoom = 10;
    this.camera.lookAt(0, 0, 0);
    this.renderDirty();
  }

  /** Sync this document with the contents of another document */
  public syncWith(editor: BaseDocument): Promise<OfficeDocument> {
    const serialized: ISceneSerialized = {
      onLoad: editor.loadedEffect,
      ...editor.toObject(),
    };
    return this.load(serialized).then(() => {
      this.updated = editor.updated;
      return this;
    });
  }

  public setShadows(enabled = true): void {
    this.config.shadows = enabled;
    this.renderer.shadowMap.enabled = enabled;
    this.config.directionalLight.castShadow = enabled;
    // Resize shadow camera to fit the grid exactly
    if (enabled) {
      const shadowCam = this.config.directionalLight.shadow.camera;
      shadowCam.left = -this.config.createArgs.gridSize;
      shadowCam.right = this.config.createArgs.gridSize;
      shadowCam.top = this.config.createArgs.gridSize;
      shadowCam.bottom = -this.config.createArgs.gridSize;
      shadowCam.updateProjectionMatrix();
    }
    this.config.shapes.traverse((child) => setChildShadows(child, enabled));
    this.renderDirty();
  }

  /** Set the environment map to a specific map by id in EnvironmentMaps.ts */
  public setEnvironmentMap(id: string | null): Promise<void> {
    this.config.envMap = id;
    if (id === null) {
      this.config.scene.environment = null;
      return new Promise((resolve) => {
        // Unwind the stack with setTimeout
        setTimeout(() => {
          // Now re-render the scene
          this.renderDirty();
          resolve();
        }, 10);
      });
    }
    const environment = ENVIRONMENT_MAPS.find((entry) => entry.id === id);
    if (!environment) {
      return Promise.reject("Invalid environemnt map: " + id);
    }

    return this.getCubeMapTexture(environment).then(
      (envMap: Texture | null) => {
        this.config.scene.environment = envMap;
        const intensity = envMap
          ? AMBIENT_INTENSITY_ENV_MAP
          : AMBIENT_INTENSITY_NO_ENV_MAP;
        this.config.ambientLight.intensity = intensity;
        this.renderDirty();
      }
    );
  }

  /**
   * Load a HDR cubemap texture as an evironment map.
   *
   * From: https://github.com/donmccurdy/three-gltf-viewer/blob/main/src/viewer.js
   */
  protected getCubeMapTexture(
    environment: IEnvironmentMap
  ): Promise<Texture | null> {
    const { path } = environment;
    // no envmap
    if (!path) {
      return Promise.resolve(null);
    }

    return new Promise((resolve, reject) => {
      new RGBELoader().setDataType(UnsignedByteType).load(
        path,
        (texture) => {
          const envMap =
            this.pmremGenerator.fromEquirectangular(texture).texture;
          this.pmremGenerator.dispose();
          resolve(envMap);
        },
        undefined,
        reject
      );
    });
  }
}
