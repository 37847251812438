import { EditorDocument } from "../editor/EditorDocument";
import { SceneEditor } from "../editor/SceneEditor";
import { CameraTool } from "../editor/tools/CameraTool";
import { EraserTool } from "../editor/tools/EraserTool";
import { FloodFillTool } from "../editor/tools/FloodFillTool";
import { MoveTool } from "../editor/tools/MoveTool";
import { PencilTool } from "../editor/tools/PencilTool";
import { PrefabTool } from "../editor/tools/PrefabTool";
import { RoomsTool } from "../editor/tools/RoomsTool";
import { RotateTool } from "../editor/tools/RotateTool";
import { OfficeDocument } from "../office/OfficeDocument";

export const EDITOR_DOCUMENT = new EditorDocument();

export const SCENE_EDITOR = new SceneEditor(EDITOR_DOCUMENT, [
  new CameraTool(),
  new MoveTool(),
  new PencilTool(),
  new FloodFillTool(),
  new PrefabTool(),
  new RotateTool(),
  new EraserTool(),
  new RoomsTool(),
]);

export const OFFICE_DOCUMENT = new OfficeDocument("test office");
