import { Box3, Group, Object3D, TextureLoader, Vector3 } from "three";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

/**
 * The list of prefabs shapes the editor knows about.
 *
 * TODO: This should probably be made dynamic at some point.
 */
export const PREFAB_TYPES = [
  "entrance",
  "conference_1",
  "office_1",
  "office_2",
  "lounge",
  "arcade",
  "white_board",
  "white_board_ns",
  "white_board_ew",
  "workstation_1",
  "ls_1",
  "ls_2",
  "ls_3",
  "pool",
  "benches/bench_1",
  "benches/bench_2",
] as const;
export type PrefabTypes = typeof PREFAB_TYPES[number]; // union type

export const TILE_TYPES = [
  "floor_tile",
  "floor_to_water_tile",
  "floor_to_grass_tile",
  "dirt_tile",
  "dirt_tile_2",
  "dirt_to_grass_tile_2",
  "grass_tile",
  "grass_to_water_tile",
  "water_tile",
  "water_rocks_1",
  "water_rocks_2",
  "bushes",
  "bushes_2",
  "bushes_3",
  "trees/tree_1",
  "trees/tree_2",
  "trees/tree_3",
  "plants/plant_1",
  "plants/plant_2",
  "plants/plant_3",
  "plants/plant_4",
  "plants/plant_5",
  "plants/plant_6",
  "cactus",
  "water_cooler",
  "walls/wall_2_se",
  "walls/wall_2_s_to_e",
  "walls/wall_2_ns",
  "walls/wall_2_nsew",
  "walls/wall_2_sew",
  "fence_1",
] as const;
export type TileTypes = typeof TILE_TYPES[number]; // union type

/** All piece types */
export type PieceTypes = PrefabTypes | TileTypes;

export const GLTF_LOADER = new GLTFLoader();
export const TEXTURE_LOADER = new TextureLoader();

/** Cache loaded prefabs so they aren't requested more than once. */
const PREFAB_CACHE: { [ofType: string]: Prefab } = {};
const PREFAB_PROMISES: { [ofType: string]: Promise<Prefab> } = {};

export interface IPrefabTemplate {
  ofType: PieceTypes;
}

/**
 * "Prefabricated" 3D model. Instances are shared by the loader,
 * so make a clone of the prefab if you intend to manipulate it.
 */
export class Prefab implements IPrefabTemplate {
  constructor(
    public readonly ofType: PieceTypes,
    public readonly width: number,
    public readonly height: number,
    public readonly object: Object3D
  ) {}

  clone(): Prefab {
    return new Prefab(
      this.ofType,
      this.width,
      this.height,
      this.object.clone()
    );
  }
}

export function getPrefabSize(prefab: Object3D): [number, number] {
  const bbox = new Box3().setFromObject(prefab);
  const size = new Vector3();
  const result = bbox.getSize(size).round();
  return [result.x, result.z];
}

/** Load a model and cache it to avoid duplicate loads. */
export function getPrefab(ofType: PieceTypes): Promise<Prefab> {
  if (!ofType) {
    return Promise.reject(`invalid ofType: ${ofType}`);
  }
  if (PREFAB_CACHE[ofType]) {
    return Promise.resolve(PREFAB_CACHE[ofType]);
  }
  if (!PREFAB_PROMISES[ofType]) {
    PREFAB_PROMISES[ofType] = new Promise((resolve, reject) => {
      GLTF_LOADER.loadAsync(`models/${ofType}.glb`)
        .then((object: GLTF | Group) => {
          const group = object instanceof Group ? object : object.scene;
          group.name = "PrefabRoot";
          const [width, height] = getPrefabSize(group);
          PREFAB_CACHE[ofType] = new Prefab(ofType, width, height, group);
          delete PREFAB_PROMISES[ofType];
          console.log("Loaded: " + ofType);
          return resolve(PREFAB_CACHE[ofType]);
        })
        .catch(reject);
    });
  }

  return PREFAB_PROMISES[ofType];
}

// function processPrefabGLTF(group: Group): void {
//   group.traverse((child: Object3D) => {
//     if (child instanceof Mesh) {
//       child.geometry = makeInstances(child.geometry);
//     }
//   });
// }

// function makeInstances(
//   geometry: BufferGeometry,
//   instanceCount = 256
// ): InstancedBufferGeometry {
//   const instanceID = new InstancedBufferAttribute(
//     new Float32Array(new Array(instanceCount).fill(0).map((_, index) => index)),
//     1
//   );
//   const result = new InstancedBufferGeometry().copy(geometry);
//   result.setAttribute("instanceID", instanceID);
//   result.instanceCount = instanceCount;
//   return result;
// }
