import { MOUSE, PerspectiveCamera } from "three";
import { CameraControls } from "../core/CameraControls";

export class SceneCameraControls extends CameraControls {
  constructor(
    public object: PerspectiveCamera,
    public domElement: HTMLElement
  ) {
    super(object, domElement);
    this.mouseButtons.LEFT = MOUSE.PAN;
    this.mouseButtons.RIGHT = MOUSE.PAN;
    this.mouseButtons.MIDDLE = MOUSE.ROTATE;
  }

  /**
   * When active, the left mouse button does nothing and is reserved for use
   * by the active editor tool.
   */
  setToolOverride(enabled: boolean): void {
    this.mouseButtons.LEFT = enabled ? null : MOUSE.PAN;
  }
}
