import {
  Box2,
  Camera,
  Color,
  Event,
  EventDispatcher,
  Group,
  MathUtils,
  Mesh,
  Object3D,
  OrthographicCamera,
  PerspectiveCamera,
  Vector2,
  Vector3,
  WebGLInfo,
  WebGLRenderer,
} from "three";
import { Seat } from "../objects/Seat";
import { ThreeJSPicker } from "./Picker";
import { getPrefab, PieceTypes, Prefab } from "./Prefabs";
import {
  createEditorScene,
  ISceneCreationArgs,
  normalizeRotation,
  SceneConfig,
  screenToCamera,
  setChildShadows,
  snapObjectToGrid,
  TILE_SIZE,
  NULL_POINT,
} from "./Scene";
import { nanoid } from "nanoid";

export type ScenePieceRotation = 0 | 90 | 180 | 270 | 360;

export interface IScenePieceTemplate {
  /** The prefab template used by this piece */
  prefab: PieceTypes;
  x: number;
  y: number;
  /** The orientation of the piece in the placed position */
  rotation: ScenePieceRotation;
}

export interface IScenePiece {
  /** Unique object id for referencing pieces from other objects like rooms. */
  id: string;
  /** The prefab used by this piece */
  prefab: Prefab;
  /** The instantiated prefab Object */
  instance: Object3D;
  /** The grid "box" space occupied by the piece. */
  box: Box2;
  /** The orientation of the piece in the placed position */
  rotation: ScenePieceRotation;
  /** The seat objects defined by the prefab */
  seats: Group;
  /** The render target planes defined by the prefab */
  renderTargets: Object3D[];
}

export interface ISceneRoom {
  id: string;
  label: string;
  x: number;
  y: number;
  width: number;
  height: number;
  /** When a room is based on a piece, the reference is kept */
  fromPieceId?: string;
}

export interface ISceneSerialized {
  name: string;
  args: Partial<ISceneCreationArgs>;
  pieces: IScenePieceTemplate[];
  rooms: ISceneRoom[];
  onLoad?: (doc: BaseDocument) => Promise<void>;
}

/** The document was loaded */
export interface DocumentLoadedEvent extends Event {
  type: "loaded";
  document: BaseDocument;
}

/** The document was rendered and debug stats updated */
export interface RenderStatsEvent extends Event {
  type: "render-stats";
  info: WebGLInfo;
}

/**
 * A 2d lookup array that allows quickly finding IScenePieces from
 * a spot in the grid. Empty tiles return undefined.
 */
export type DocumentLookup = readonly (IScenePiece | undefined)[][];

export abstract class BaseDocument {
  public events = new EventDispatcher();
  public config: SceneConfig;
  /** An array of all the pieces placed in the scene. */
  public pieces: IScenePiece[] = [];
  public rooms: ISceneRoom[] = [];
  public updated = -1;
  public loadedEffect?: (doc: BaseDocument) => Promise<void>;

  /** Subclass must implement renderer */
  public abstract renderer: WebGLRenderer;
  /** Subclass must implement a camera */
  public abstract camera: PerspectiveCamera | OrthographicCamera;

  private _gridPicker: ThreeJSPicker | null = null;
  private _gridPickPoint: Vector2 = new Vector2();

  protected _collideBox = new Box2();
  protected _collideSize = new Vector2();
  constructor(
    public name: string = "untitled",
    args: Partial<ISceneCreationArgs>,
    private readonly editable = true
  ) {
    this.config = createEditorScene(editable, args);
  }

  /** Render the document and update the render stats */
  public render(renderer: WebGLRenderer, camera: Camera): void {
    const { scene } = this.config;
    renderer.render(scene, camera);
    const statsEvent: RenderStatsEvent = {
      type: "render-stats",
      info: renderer.info,
    };
    this.events.dispatchEvent(statsEvent);
  }

  public reset(
    from: Partial<ISceneCreationArgs> = this.config.createArgs
  ): void {
    this.config.seats.children.forEach((s) => {
      if (s instanceof Mesh) {
        s.geometry.dispose();
      }
    });
    this.config = createEditorScene(this.editable, from);
    this.events.dispatchEvent({ type: "reset", document: this });
    this.pieces = [];
    this.updated = Date.now();
  }

  public changed(): void {
    this.updated = Date.now();
    this.events.dispatchEvent({ type: "change", document: this });
  }

  public addPiece(
    prefab: Prefab,
    instance: Object3D,
    gridX: number,
    gridY: number,
    rotation: ScenePieceRotation
  ): IScenePiece {
    // Do nothing if the piece already exists in the document
    const existing = this.pieces.find((p) => p.instance.id === instance.id);
    if (existing) {
      return existing;
    }
    // Store reference to the original prefab
    instance.userData.prefab = prefab;
    const box = new Box2(
      new Vector2(gridX, gridY),
      new Vector2(gridX + prefab.width, gridY + prefab.height)
    );
    const seats: Group = new Group();
    const renderTargets: Object3D[] = [];
    seats.name = "Seats";
    instance.traverse((child: Object3D) => {
      const childName = child.name.toLowerCase();
      if (childName.startsWith("seat") && child.type !== "Group") {
        seats.add(new Seat(child));
      }
      if (child.name.startsWith("render")) {
        renderTargets.push(child);
      }
    });
    instance.add(seats);
    const piece: IScenePiece = {
      id: nanoid(),
      seats,
      renderTargets,
      instance,
      box,
      prefab,
      rotation,
    };
    this.pieces.push(piece);
    this.config.shapes.add(instance);
    this.rotatePiece(instance, rotation);
    this.changed();
    return piece;
  }

  public movePiece(
    piece: IScenePiece,
    gridX: number,
    gridY: number,
    rotation: ScenePieceRotation
  ): IScenePiece {
    // Do nothing if the piece already exists in the document
    const existing = this.pieces.find(
      (p) => p.instance.id === piece.instance.id
    );
    if (!existing) {
      throw new Error("piece not found in document to move");
    }
    existing.box = new Box2(
      new Vector2(gridX, gridY),
      new Vector2(gridX + piece.prefab.width, gridY + piece.prefab.height)
    );
    existing.rotation = rotation;
    this.changed();
    return piece;
  }

  public removePiece(instance: Object3D): IScenePiece | null {
    const foundPiece = this.pieces.find((p) => p.instance === instance);
    if (foundPiece) {
      const newPieces = this.pieces.filter((p) => p.instance !== instance);
      this.pieces = newPieces;
      this.config.shapes.remove(instance);
      this.changed();
      return foundPiece;
    }
    return null;
  }

  /**
   * Get the IScenePiece that a room was originally generated from.
   *
   * Return either the found piece, or null if the room was not generated
   * automatically from a prefab.
   */
  public getRoomTemplate(room: ISceneRoom): IScenePiece | null {
    if (!room || room.fromPieceId === undefined) {
      return null;
    }
    const piece = this.pieces.find((p) => p.id === room.fromPieceId);
    return piece || null;
  }

  /** Determine if an instance has 1:1 aspect ratio or not */
  public isSquare(instance: Object3D): boolean {
    const piece = this.pieces.find((p) => p.instance === instance);
    if (piece) {
      return piece.prefab.width === piece.prefab.height;
    }
    throw new Error("Object not found in document.");
  }

  private _lookupCache: { updated: number; result: DocumentLookup } = {
    updated: -Infinity,
    result: [],
  };
  /**
   * Build a 2D lookup array for quickly accessing pieces by their
   * x/y coordinates in the grid. The result is cached according to the
   * `this.updated` value of the document. That is, if the updated value
   * changes, the lookup array will be rebuilt, otherwise a copy of the
   * cached value will be returned.
   */
  public getPieceLookup(forceRebuild = false): DocumentLookup {
    if (this._lookupCache.updated === this.updated && !forceRebuild) {
      return this._lookupCache.result;
    }
    const width = this.config.createArgs.gridSize;
    const height = this.config.createArgs.gridSize;
    const halfWidth = width / 2;
    const lookup = Array.from(Array(width), () => new Array(height));
    for (const piece of this.pieces) {
      const pieceX = piece.box.min.x + halfWidth;
      const pieceY = piece.box.min.y + halfWidth;
      const pieceWidth = piece.box.max.x - piece.box.min.x;
      const pieceHeight = piece.box.max.y - piece.box.min.y;
      for (let py = 0; py < pieceHeight; py++) {
        for (let px = 0; px < pieceWidth; px++) {
          lookup[pieceX + px][pieceY + py] = piece;
        }
      }
    }
    this._lookupCache = {
      updated: this.updated,
      result: lookup,
    };
    return lookup;
  }

  /** Rotate a piece to the right */
  public rotatePiece(instance: Object3D, newAngle: ScenePieceRotation): void {
    const radRotation = MathUtils.degToRad(newAngle);
    const piece = this.pieces.find((p) => p.instance === instance);
    if (!piece) {
      throw new Error("Object not found in document.");
    }
    piece.rotation = normalizeRotation(newAngle, false) as ScenePieceRotation;
    piece.instance.rotation.y = radRotation;
    this.changed();
  }

  public setColor(backgroundColor: Color): void {
    this.config.createArgs.backgroundColor = backgroundColor.getHex();
    this.config.scene.background = backgroundColor;
    this.changed();
  }

  /** Determine if a point is inside of the world grid */
  public pointInGrid(x: number, y: number): boolean {
    const gridX = Math.floor(x / TILE_SIZE);
    const gridY = Math.floor(y / TILE_SIZE);
    const halfSize = this.config.createArgs.gridSize / 2;
    const lowerBound = -halfSize;
    const upperBound = halfSize - 1;
    const xInBounds = gridX >= lowerBound && gridX <= upperBound;
    const yInBounds = gridY >= lowerBound && gridY <= upperBound;
    return xInBounds && yInBounds;
  }

  public toObject(): ISceneSerialized {
    return {
      name: this.name,
      args: { ...this.config.createArgs },
      rooms: this.rooms,
      pieces: this.pieces.map(({ prefab, rotation, box }) => {
        const { ofType } = prefab;
        const result: IScenePieceTemplate = {
          prefab: ofType,
          rotation,
          x: box.min.x,
          y: box.min.y,
        };
        return result;
      }),
    };
  }

  /**
   * Custom logic just before loading a map is finalized and
   * the "loaded" event is triggered.
   */
  public finalizeLoad(object: ISceneSerialized): Promise<void> {
    return Promise.resolve();
  }

  /** Load the given serialized scene into this document. */
  public load(object: ISceneSerialized): Promise<this> {
    this.reset(object.args);
    this.loadedEffect = object.onLoad;
    this.updated = -1;
    this.name = object.name;
    this.rooms = [...(object.rooms || [])];
    return Promise.all(
      object.pieces.map((value: IScenePieceTemplate) => {
        return getPrefab(value.prefab);
      })
    )
      .then((prefabs) => {
        prefabs.forEach((p) => {
          setChildShadows(p.object, this.config.shadows);
        });
        object.pieces.forEach((value: IScenePieceTemplate, index: number) => {
          const prefab: Prefab = prefabs[index];
          const instance = prefab.object.clone();
          const point = new Vector3(value.x, 0, value.y);
          snapObjectToGrid(instance, point, prefab.width, prefab.height);
          const gridX = Math.floor(point.x / TILE_SIZE) * TILE_SIZE;
          const gridY = Math.floor(point.z / TILE_SIZE) * TILE_SIZE;
          this.addPiece(prefab, instance, gridX, gridY, value.rotation);
        });
      })
      .then(() => this.finalizeLoad(object))
      .then(() => (this.loadedEffect ? this.loadedEffect(this) : null))
      .then(() => {
        const loadedEvent: DocumentLoadedEvent = {
          type: "loaded",
          document: this,
        };
        this.events.dispatchEvent(loadedEvent);
        return this;
      });
  }

  /** Returns the total number of seats across all pieces */
  getSeatCount(): number {
    let seats = 0;
    for (const piece of this.pieces) {
      seats += piece.seats.children.length;
    }
    return seats;
  }

  /**
   * Pick the object under a given grid point, or null.
   */
  public pick(gridX: number, gridY: number): IScenePiece | null {
    return (
      this.pieces.find((p) => {
        this._collideBox.min.set(gridX, gridY);
        this._collideBox.max.set(gridX + 0.5, gridY + 0.5);
        // Don't use intersectBox because edges touching is considered an overlap
        //
        // https://github.com/mrdoob/three.js/issues/9137#issuecomment-226296659
        const i = this._collideBox.intersect(p.box);
        i.getSize(this._collideSize);
        const collide = this._collideSize.x * this._collideSize.y > 0;
        return collide ? p : null;
      }) || null
    );
  }

  /** Find the world point under the cursor. */
  public worldPointFromEvent(
    /** The pointer event to find world coordinates from */
    event: PointerEvent
  ): [number, number] {
    return this.worldPointFromScreen(
      event.currentTarget as HTMLElement,
      event.offsetX,
      event.offsetY
    );
  }
  /** Find the world point under the cursor. */
  public gridPointFromEvent(event: PointerEvent): [number, number] {
    return this.gridPointFromScreen(
      event.currentTarget as HTMLElement,
      event.offsetX,
      event.offsetY
    );
  }
  /** Find the world point under the cursor. */
  public worldPointFromScreen(
    view: HTMLElement,
    screenX: number,
    screenY: number
  ): [number, number] {
    if (this._gridPicker === null) {
      this._gridPicker = new ThreeJSPicker(this.config.grid, this.camera);
    }
    const [cameraX, cameraY] = screenToCamera(view, screenX, screenY);
    this._gridPicker.target = this.config.grid;
    this._gridPickPoint.set(cameraX, cameraY);
    const hit = this._gridPicker.pickFirst(this._gridPickPoint);
    if (!hit) {
      return NULL_POINT;
    }
    return [hit.point.x, hit.point.z];
  }

  /** Find the world point under the cursor. */
  public gridPointFromScreen(
    view: HTMLElement,
    screenX: number,
    screenY: number
  ): [number, number] {
    const pointTuple = this.worldPointFromScreen(view, screenX, screenY);
    if (pointTuple === NULL_POINT) {
      return NULL_POINT;
    }
    return this.worldPointToGrid(pointTuple[0], pointTuple[1]);
  }

  /** Convert a world point (technically x/z on three.js objects) to grid coordinates */
  public worldPointToGrid(worldX: number, worldY: number): [number, number] {
    const gridX = Math.floor(worldX / TILE_SIZE);
    const gridY = Math.floor(worldY / TILE_SIZE);
    return [gridX, gridY];
  }

  /** Convert grid x/y to indices in a piece lookup array. e.g. (-8,-8) => (0, 0) */
  public gridPointToLookup(gridX: number, gridY: number): [number, number] {
    const width = this.config.createArgs.gridSize;
    const height = this.config.createArgs.gridSize;
    // Normalize grid positions for lookup array
    // e.g. grid size 16 goes from (-8 => 8) to (0 => 16)
    return [gridX + width / 2, gridY + height / 2];
  }
}
