import { Object3D, Vector2 } from "three";
import { RemovePrefabsAction } from "../SceneEditorActions";
import { SceneEditorTool } from "../SceneEditorTool";

/**
 * Prefab eraser tool removes tiles under the pointer.
 */
export class EraserTool extends SceneEditorTool {
  static NAME = "eraser";
  name = EraserTool.NAME;
  tooltip = "Remove prefabs by clicking on them";
  icon = "img:/img/mdi/eraser.svg";
  private hoverPrefab: Object3D | null = null;
  private dragStart: Vector2 = new Vector2();
  private dragObjects: { [objectId: string]: Object3D } | null = null;
  escapeTool(): boolean {
    if (this.dragObjects !== null) {
      Object.values(this.dragObjects).forEach((v) => this.unselect(v));
    }
    if (this.hoverPrefab) {
      this.unselect(this.hoverPrefab);
      this.hoverPrefab = null;
    }
    this.dragStart.set(-1, -1);
    this.dragObjects = null;
    return true;
  }

  onMouseMove(event: PointerEvent): void {
    if (event.buttons > 1 || !this.editor?.host) {
      return;
    }
    const prefab = this.objectFromEvent(event);
    if (!prefab) {
      if (this.hoverPrefab && this.dragObjects === null) {
        this.unselect(this.hoverPrefab);
        this.hoverPrefab = null;
      }
    } else if (this.hoverPrefab && this.hoverPrefab !== prefab) {
      if (this.dragObjects === null) {
        this.unselect(this.hoverPrefab);
        this.hoverPrefab = null;
      }
    }
    this.hoverPrefab = prefab;
    if (this.hoverPrefab) {
      this.select(this.hoverPrefab);
      if (this.dragObjects && !(this.hoverPrefab.id in this.dragObjects)) {
        this.dragObjects[this.hoverPrefab.id] = this.hoverPrefab;
      }
    }
  }
  onMouseUp(event: PointerEvent): void {
    const prefabs = Object.values(this.dragObjects || {});
    this.dragObjects = null;
    if (this.hoverPrefab) {
      this.unselect(this.hoverPrefab);
      this.hoverPrefab = null;
    }
    if (event.button !== 0 || !this.editor?.host) {
      return;
    }
    // Look for objects under the pointer if there was no drag selection.
    if (prefabs.length === 0) {
      const tolerance = 10;
      const deltaX = Math.abs(this.dragStart.x - event.clientX);
      const deltaY = Math.abs(this.dragStart.y - event.clientY);
      const isClick = deltaX < tolerance && deltaY < tolerance;
      if (!isClick) {
        return;
      }
      const prefab = this.objectFromEvent(event);
      if (prefab) {
        prefabs.push(prefab);
      }
    }
    if (prefabs.length > 0) {
      prefabs.forEach((p) => this.unselect(p));
      const prefabAction = new RemovePrefabsAction(
        prefabs,
        this.editor.document
      );
      this.editor.actions.executeAction(prefabAction);
    }
  }
  onMouseDown(event: PointerEvent): void {
    if (!this.editor) {
      return;
    }
    this.dragStart.set(event.clientX, event.clientY);
    this.dragObjects = {};
    return;
  }
}
