import { IScenePiece } from "../../core/Document";
import { Prefab } from "../../core/Prefabs";
import { PaintTilesAction } from "../SceneEditorActions";
import { SceneEditorTool } from "../SceneEditorTool";
/**
 * Flood fill tool paints from the cursor expanding out in all directions
 * until it reaches a tile that is a different type to the one under the cursor.
 */
export class FloodFillTool extends SceneEditorTool {
  static NAME = "flood_fill";
  name = FloodFillTool.NAME;
  tooltip = "Fill all connected tiles with the given prefab";
  icon = "format_color_fill";
  private previewObjects: {
    prefab: Prefab | null;
    remove: IScenePiece[];
    add: IScenePiece[];
  } = { prefab: null, remove: [], add: [] };

  escapeTool(): boolean {
    this.resetPreview();
    return super.escapeTool();
  }

  hasValidPrefab(): boolean {
    if (!this.editor || !this.editor.currentPrefab) {
      return false;
    }
    return (
      this.editor.currentPrefab.height === 1 &&
      this.editor.currentPrefab.width === 1
    );
  }

  /** Cancel the current paint preview */
  resetPreview(): void {
    if (!this.previewObjects || !this.editor) {
      return;
    }
    const removeObjects: IScenePiece[] = this.previewObjects.remove;
    // Add removed shapes back to scene
    removeObjects.forEach((r) => {
      this.editor?.document.addPiece(
        r.prefab,
        r.instance,
        r.box.min.x,
        r.box.min.y,
        r.rotation
      );
    });

    // Destroy the temporary new pieces
    const createObjects: IScenePiece[] = this.previewObjects.add;
    createObjects.forEach((c) => {
      this.editor?.document.removePiece(c.instance);
    });
    this.previewObjects = { prefab: null, remove: [], add: [] };
  }

  onMouseMove(event: PointerEvent): void {
    if (!this.editor?.host || !this.hasValidPrefab()) {
      return;
    }

    // Find a prefab
    const [x, y] = this.gridPointFromEvent(event);
    if (!this.editor.document.pointInGrid(x, y)) {
      this.resetPreview();
      return;
    }
    const fillPrefab = this.editor.currentPrefab;
    if (!fillPrefab) {
      return;
    }

    const [add, remove] = this.floodFill(x, y, fillPrefab);
    if (add.length > 0 || remove.length > 0) {
      this.resetPreview();
      this.previewObjects.prefab = fillPrefab;
      this.previewObjects.add = add;
      this.previewObjects.remove = remove;
    }
  }
  onMouseDown(event: PointerEvent): void {
    const removeObjects: IScenePiece[] = this.previewObjects.remove;
    const createObjects: IScenePiece[] = this.previewObjects.add;
    this.resetPreview();
    if (event.button !== 0 || !this.editor?.host || !this.hasValidPrefab()) {
      return;
    }
    if (createObjects.length > 0 || removeObjects.length > 0) {
      const prefabAction = new PaintTilesAction(
        createObjects,
        removeObjects,
        this.editor.document
      );
      this.editor.actions.executeAction(prefabAction);
    }
  }

  floodFill(
    paintX: number,
    paintY: number,
    prefab: Prefab
  ): [IScenePiece[], IScenePiece[]] {
    if (!this.editor || !this.hasValidPrefab()) {
      throw new Error("invalid prefab size!");
    }

    const width = this.editor.document.config.createArgs.gridSize;
    const height = this.editor.document.config.createArgs.gridSize;
    const halfWidth = width / 2;
    const halfHeight = height / 2;

    // Normalize grid positions for lookup array
    // e.g. grid size 16 goes from (-8 => 8) to (0 => 16)
    paintX += halfWidth;
    paintY += halfHeight;

    // flags for if we visited a pixel already
    const visited = Array.from(Array(width), () => new Array(height));
    const pieceLookup = this.editor.document.getPieceLookup();
    const paintAtPrefab = pieceLookup[paintX][paintY];
    const addPieces: IScenePiece[] = [];
    const removePieces: IScenePiece[] = [];
    let isFirstFill = true;
    // If there's no prefab there, or it's a different one than we're filling
    if (!paintAtPrefab || paintAtPrefab.prefab.ofType !== prefab.ofType) {
      const pixelsToCheck = [paintX, paintY];
      while (pixelsToCheck.length > 0) {
        const y = pixelsToCheck.pop();
        const x = pixelsToCheck.pop();
        if (x === undefined || y === undefined) {
          break;
        }
        if (x < 0 || y < 0 || x >= width || y >= height) {
          // out of bounds check
          continue;
        }
        const iterColor = pieceLookup[x][y];
        const isEmptyFill = !iterColor && !paintAtPrefab;
        const isMatchFill =
          paintAtPrefab &&
          iterColor?.prefab.ofType === paintAtPrefab.prefab.ofType;
        if ((isFirstFill || isMatchFill || isEmptyFill) && !visited[x][y]) {
          isFirstFill = false;
          addPieces.push(this.createFor(x - halfWidth, y - halfHeight));
          // If there was a paintAtPrefab, we're replacing, so remove the same piece
          if (isMatchFill) {
            if (!iterColor) {
              // If this fires, it means that somehow we think we're match filling
              // but there is no tile (a match) under this point. If we start from a
              // point that has a match, all the tiles we replace should match that and
              // exist.
              throw new Error("no piece under cursor when match filling");
            }
            this.editor.document.removePiece(iterColor.instance);
            removePieces.push(iterColor);
          }
          visited[x][y] = true;
          pixelsToCheck.push(x + 1, y);
          pixelsToCheck.push(x - 1, y);
          pixelsToCheck.push(x, y + 1);
          pixelsToCheck.push(x, y - 1);
        }
      }
    }
    return [addPieces, removePieces];
  }
}
