/**
 * Camera controls for the various builder/player views.
 *
 * Based on the example camera controls from the threejs repo:
 * https://github.com/mrdoob/three.js/blob/dev/examples/jsm/controls/experimental/CameraControls.js
 */
import {
  EventDispatcher,
  Matrix4,
  MOUSE,
  OrthographicCamera,
  PerspectiveCamera,
  Quaternion,
  Spherical,
  TOUCH,
  Vector2,
  Vector3,
} from "three";

export interface ICameraControlsState {
  readonly target0: Vector3;
  readonly position0: Vector3;
  readonly quaternion0: Quaternion;
  readonly zoom0: number;

  readonly target: Vector3;
  readonly position: Vector3;
  readonly quaternion: Quaternion;
  readonly zoom: number;

  readonly spherical: Spherical;
  readonly sphericalDelta: Spherical;

  readonly scale: number;
  readonly panOffset: Vector3;
  readonly zoomChanged: boolean;

  readonly rotateStart: Vector2;
  readonly rotateEnd: Vector2;
  readonly rotateDelta: Vector2;

  readonly panStart: Vector2;
  readonly panEnd: Vector2;
  readonly panDelta: Vector2;

  readonly dollyStart: Vector2;
  readonly dollyEnd: Vector2;
  readonly dollyDelta: Vector2;

  // UPDATE PRIVATE SCOPE
  readonly offset: Vector3;
  // so camera.up is the orbit axis
  readonly quat: Quaternion;
  readonly quatInverse: Quaternion;
  readonly lastPosition: Vector3;
  readonly lastQuaternion: Quaternion;
  readonly q: Quaternion;
  readonly vec: Vector3;
}

export class CameraControls {
  /** Set to false to disable this control */
  public enabled = true;

  /** "target" sets the location of focus, where the object orbits around */
  public target = new Vector3();

  /** Set to true to enable trackball behavior */
  public trackball = false;

  /** How far you can dolly in and out ( PerspectiveCamera only ) */
  public minDistance = 0;
  /** How far you can dolly in and out ( PerspectiveCamera only ) */
  public maxDistance = Infinity;

  /** How far you can zoom in and out ( OrthographicCamera only ) */
  public minZoom = 0;
  /** How far you can zoom in and out ( OrthographicCamera only ) */
  public maxZoom = Infinity;

  /** When enabled for an orth camera, tilt between min/max polar angle when scrolling */
  public zoomTilt = false;

  /** How much to increment the rotation of the camera per zoom step */
  public zoomTiltIncrement = 0.02;

  /**
   * How far you can orbit vertically, upper and lower limits.
   * Range is 0 to Math.PI radians.
   */
  public minPolarAngle = 0; // radians
  /**
   * How far you can orbit vertically, upper and lower limits.
   * Range is 0 to Math.PI radians.
   */
  public maxPolarAngle = Math.PI; // radians

  /**
   * How far you can orbit horizontally, upper and lower limits.
   * If set, must be a sub-interval of the interval [ - Math.PI, Math.PI ].
   */
  public minAzimuthAngle = -Infinity; // radians
  /**
   * How far you can orbit horizontally, upper and lower limits.
   * If set, must be a sub-interval of the interval [ - Math.PI, Math.PI ].
   */
  public maxAzimuthAngle = Infinity; // radians

  /**
   * Set to true to enable damping (inertia)
   *
   * If damping is enabled, you must call controls.update() in your animation loop
   */
  public enableDamping = false;
  public dampingFactor = 0.05;

  // This option enables dollying in and out; property named as "zoom" for backwards compatibility
  // Set to false to disable zooming
  public enableZoom = true;
  public enableMiddleButtonZoom = false;
  public zoomSpeed = 1.0;

  // Set to false to disable rotating
  public enableRotate = true;
  public rotateSpeed = 1.0;

  /** Set to false to disable panning */
  public enablePan = true;
  /** How fast to pan */
  public panSpeed = 1.0;
  /** if true, pan in screen-space */
  public screenSpacePanning = false;
  /** pixels moved per arrow key push */
  public keyPanSpeed = 7.0;

  /**
   * Set to true to automatically rotate around the target
   * If auto-rotate is enabled, you must call controls.update() in your animation loop
   * auto-rotate is not supported for trackball behavior.
   */
  public autoRotate = false;
  /** 30 seconds per round when fps is 60 */
  public autoRotateSpeed = 2.0;

  /** Set to false to disable use of the keys */
  public enableKeys = true;

  /** The four arrow keys */
  public keys = { LEFT: 37, UP: 38, RIGHT: 39, BOTTOM: 40 };

  /** Mouse buttons */
  public mouseButtons: { [button: string]: MOUSE | null } = {
    LEFT: MOUSE.ROTATE,
    MIDDLE: MOUSE.DOLLY,
    RIGHT: MOUSE.PAN,
  };

  /** Touch fingers */
  public touches = { ONE: TOUCH.ROTATE, TWO: TOUCH.DOLLY_PAN };

  private target0: Vector3;
  private position0: Vector3;
  private quaternion0: Quaternion;
  private zoom0: number;

  public events = new EventDispatcher();

  constructor(
    public object: PerspectiveCamera | OrthographicCamera,
    public domElement: HTMLElement
  ) {
    // for reset
    this.target0 = this.target.clone();
    this.position0 = this.object.position.clone();
    this.quaternion0 = this.object.quaternion.clone();
    this.zoom0 = this.object.zoom;

    this.domElement.addEventListener("contextmenu", this.onContextMenu, false);
    this.domElement.addEventListener("mousedown", this.onMouseDown, false);
    this.domElement.addEventListener("wheel", this.onMouseWheel, false);
    this.domElement.addEventListener("touchstart", this.onTouchStart, false);
    this.domElement.addEventListener("touchend", this.onTouchEnd, false);
    this.domElement.addEventListener("touchmove", this.onTouchMove, false);
    this.domElement.addEventListener("keydown", this.onKeyDown, false);

    // make sure element can receive keys.
    if (this.domElement.tabIndex === -1) {
      this.domElement.tabIndex = 0;
    }

    // force an update at start
    this.object.lookAt(this.target);
    this.update();
    this.saveState();
  }

  getPolarAngle(): number {
    return this.spherical.phi;
  }

  getAzimuthalAngle(): number {
    return this.spherical.theta;
  }

  saveState(): void {
    this.target0.copy(this.target);
    this.position0.copy(this.object.position);
    this.quaternion0.copy(this.object.quaternion);
    this.zoom0 = this.object.zoom;
  }

  getState(): ICameraControlsState {
    return {
      target0: this.target0.clone(),
      position0: this.position0.clone(),
      quaternion0: this.quaternion0.clone(),
      zoom0: this.zoom0,
      target: this.target.clone(),
      position: this.object.position.clone(),
      quaternion: this.object.quaternion.clone(),
      zoom: this.object.zoom,
      spherical: this.spherical.clone(),
      sphericalDelta: this.sphericalDelta.clone(),
      scale: this.scale,
      panOffset: this.panOffset.clone(),
      zoomChanged: this.zoomChanged,

      rotateStart: this.rotateStart.clone(),
      rotateEnd: this.rotateEnd.clone(),
      rotateDelta: this.rotateDelta.clone(),

      panStart: this.panStart.clone(),
      panEnd: this.panEnd.clone(),
      panDelta: this.panDelta.clone(),

      dollyStart: this.dollyStart.clone(),
      dollyEnd: this.dollyEnd.clone(),
      dollyDelta: this.dollyDelta.clone(),

      // UPDATE PRIVATE SCOPE
      offset: this.offset.clone(),
      // so camera.up is the orbit axis
      quat: this.quat.clone(),
      quatInverse: this.quatInverse.clone(),
      lastPosition: this.lastPosition.clone(),
      lastQuaternion: this.lastQuaternion.clone(),
      q: this.q.clone(),
      vec: this.vec.clone(),
    };
  }
  loadState(fromState: ICameraControlsState): void {
    this.target0.copy(fromState.target0);
    this.position0.copy(fromState.position0);
    this.quaternion0.copy(fromState.quaternion0);
    this.zoom0 = fromState.zoom0;

    this.target.copy(fromState.target);
    this.object.position.copy(fromState.position);
    this.object.quaternion.copy(fromState.quaternion);
    this.object.zoom = fromState.zoom;

    this.spherical.copy(fromState.spherical);
    this.sphericalDelta.copy(fromState.sphericalDelta);

    this.scale = fromState.scale;
    this.panOffset.copy(fromState.panOffset);
    this.zoomChanged = fromState.zoomChanged;

    this.rotateStart.copy(fromState.rotateStart);
    this.rotateEnd.copy(fromState.rotateEnd);
    this.rotateDelta.copy(fromState.rotateDelta);

    this.panStart.copy(fromState.panStart);
    this.panEnd.copy(fromState.panEnd);
    this.panDelta.copy(fromState.panDelta);

    this.dollyStart.copy(fromState.dollyStart);
    this.dollyEnd.copy(fromState.dollyEnd);
    this.dollyDelta.copy(fromState.dollyDelta);

    this.offset.copy(fromState.offset);
    this.quat.copy(fromState.quat);
    this.quatInverse.copy(fromState.quatInverse);
    this.lastPosition.copy(fromState.lastPosition);
    this.lastQuaternion.copy(fromState.lastQuaternion);
    this.q.copy(fromState.q);
    this.vec.copy(fromState.vec);

    this.object.updateProjectionMatrix();
    this.events.dispatchEvent(this.changeEvent);

    this.update();

    this.state = this.STATE.NONE;
  }

  reset(): void {
    this.target.copy(this.target0);
    this.object.position.copy(this.position0);
    this.object.quaternion.copy(this.quaternion0);
    this.object.zoom = this.zoom0;
  }

  //
  // internals
  //

  public changeEvent = { type: "change" };
  public startEvent = { type: "start" };
  public endEvent = { type: "end" };

  public STATE = {
    NONE: -1,
    ROTATE: 0,
    DOLLY: 1,
    PAN: 2,
    TOUCH_ROTATE: 3,
    TOUCH_PAN: 4,
    TOUCH_DOLLY_PAN: 5,
    TOUCH_DOLLY_ROTATE: 6,
  };

  public state = this.STATE.NONE;

  public EPS = 0.000001;

  // current position in spherical coordinates
  public spherical = new Spherical();
  public sphericalDelta = new Spherical();

  public scale = 1;
  public panOffset = new Vector3();
  public zoomChanged = false;

  public rotateStart = new Vector2();
  public rotateEnd = new Vector2();
  public rotateDelta = new Vector2();

  public panStart = new Vector2();
  public panEnd = new Vector2();
  public panDelta = new Vector2();

  public dollyStart = new Vector2();
  public dollyEnd = new Vector2();
  public dollyDelta = new Vector2();

  // UPDATE PRIVATE SCOPE
  private offset = new Vector3();
  // so camera.up is the orbit axis
  private quat = new Quaternion().setFromUnitVectors(
    this.object.up,
    new Vector3(0, 1, 0)
  );
  private quatInverse = this.quat.clone().invert();
  private lastPosition = new Vector3();
  private lastQuaternion = new Quaternion();
  private q = new Quaternion();
  private vec = new Vector3();
  // UPDATE PRIVATE SCOPE (END)

  public update(): boolean {
    const position = this.object.position;
    this.offset.copy(position).sub(this.target);
    if (this.trackball) {
      // rotate around screen-space y-axis
      if (this.sphericalDelta.theta) {
        this.vec.set(0, 1, 0).applyQuaternion(this.object.quaternion);
        const factor = this.enableDamping ? this.dampingFactor : 1;
        this.q.setFromAxisAngle(this.vec, this.sphericalDelta.theta * factor);
        this.object.quaternion.premultiply(this.q);
        this.offset.applyQuaternion(this.q);
      }

      // rotate around screen-space x-axis
      if (this.sphericalDelta.phi) {
        this.vec.set(1, 0, 0).applyQuaternion(this.object.quaternion);
        const factor = this.enableDamping ? this.dampingFactor : 1;
        this.q.setFromAxisAngle(this.vec, this.sphericalDelta.phi * factor);
        this.object.quaternion.premultiply(this.q);
        this.offset.applyQuaternion(this.q);
      }
      this.offset.multiplyScalar(this.scale);
      this.offset.clampLength(this.minDistance, this.maxDistance);
    } else {
      // rotate offset to "y-axis-is-up" space
      this.offset.applyQuaternion(this.quat);
      if (this.autoRotate && this.state === this.STATE.NONE) {
        this.rotateLeft(this.getAutoRotationAngle());
      }
      this.spherical.setFromVector3(this.offset);
      if (this.enableDamping) {
        this.spherical.theta += this.sphericalDelta.theta * this.dampingFactor;
        this.spherical.phi += this.sphericalDelta.phi * this.dampingFactor;
      } else {
        this.spherical.theta += this.sphericalDelta.theta;
        this.spherical.phi += this.sphericalDelta.phi;
      }
      // restrict theta to be between desired limits
      this.spherical.theta = Math.max(
        this.minAzimuthAngle,
        Math.min(this.maxAzimuthAngle, this.spherical.theta)
      );
      // restrict phi to be between desired limits
      this.spherical.phi = Math.max(
        this.minPolarAngle,
        Math.min(this.maxPolarAngle, this.spherical.phi)
      );
      this.spherical.makeSafe();
      this.spherical.radius *= this.scale;
      // restrict radius to be between desired limits
      this.spherical.radius = Math.max(
        this.minDistance,
        Math.min(this.maxDistance, this.spherical.radius)
      );
      this.offset.setFromSpherical(this.spherical);
      // rotate offset back to "camera-up-vector-is-up" space
      this.offset.applyQuaternion(this.quatInverse);
    }
    // move target to panned location
    if (this.enableDamping === true) {
      this.target.addScaledVector(this.panOffset, this.dampingFactor);
    } else {
      this.target.add(this.panOffset);
    }
    position.copy(this.target).add(this.offset);
    if (this.trackball === false) {
      this.object.lookAt(this.target);
    }
    if (this.enableDamping === true) {
      this.sphericalDelta.theta *= 1 - this.dampingFactor;
      this.sphericalDelta.phi *= 1 - this.dampingFactor;
      this.panOffset.multiplyScalar(1 - this.dampingFactor);
    } else {
      this.sphericalDelta.set(0, 0, 0);
      this.panOffset.set(0, 0, 0);
    }
    this.scale = 1;
    // update condition is:
    // min(camera displacement, camera rotation in radians)^2 > EPS
    // using small-angle approximation cos(x/2) = 1 - x^2 / 8

    if (
      this.zoomChanged ||
      this.lastPosition.distanceToSquared(this.object.position) > this.EPS ||
      8 * (1 - this.lastQuaternion.dot(this.object.quaternion)) > this.EPS
    ) {
      this.events.dispatchEvent(this.changeEvent);

      this.lastPosition.copy(this.object.position);
      this.lastQuaternion.copy(this.object.quaternion);
      this.zoomChanged = false;

      return true;
    }

    return false;
  }

  dispose(): void {
    this.domElement.removeEventListener(
      "contextmenu",
      this.onContextMenu,
      false
    );
    this.domElement.removeEventListener("mousedown", this.onMouseDown, false);
    this.domElement.removeEventListener("wheel", this.onMouseWheel, false);

    this.domElement.removeEventListener("touchstart", this.onTouchStart, false);
    this.domElement.removeEventListener("touchend", this.onTouchEnd, false);
    this.domElement.removeEventListener("touchmove", this.onTouchMove, false);

    document.removeEventListener("mousemove", this.onMouseMove, false);
    document.removeEventListener("mouseup", this.onMouseUp, false);

    this.domElement.removeEventListener("keydown", this.onKeyDown, false);

    //this.events.dispatchEvent( { type: 'dispose' } ); // should this be added here?
  }

  getAutoRotationAngle(): number {
    return ((2 * Math.PI) / 60 / 60) * this.autoRotateSpeed;
  }

  getZoomScale(trackpad = false): number {
    return Math.pow(0.95, trackpad ? this.zoomSpeed / 3 : this.zoomSpeed);
  }

  rotateLeft(angle: number): void {
    this.sphericalDelta.theta -= angle;
  }
  rotateRight(angle: number): void {
    this.sphericalDelta.theta += angle;
  }
  rotateUp(angle: number): void {
    this.sphericalDelta.phi -= angle;
  }
  rotateDown(angle: number): void {
    this.sphericalDelta.phi += angle;
  }

  private _panLeftV = new Vector3();
  panLeft(distance: number, objectMatrix: Matrix4): void {
    this._panLeftV.setFromMatrixColumn(objectMatrix, 0); // get X column of objectMatrix
    this._panLeftV.multiplyScalar(-distance);

    this.panOffset.add(this._panLeftV);
  }
  private _panUpV = new Vector3();
  panUp(distance: number, objectMatrix: Matrix4): void {
    if (this.screenSpacePanning === true) {
      this._panUpV.setFromMatrixColumn(objectMatrix, 1);
    } else {
      this._panUpV.setFromMatrixColumn(objectMatrix, 0);
      this._panUpV.crossVectors(this.object.up, this._panUpV);
    }
    this._panUpV.multiplyScalar(distance);
    this.panOffset.add(this._panUpV);
  }

  /** deltaX and deltaY are in pixels; right and down are positive */
  pan(deltaX: number, deltaY: number): void {
    const element = this.domElement;

    if (this.object instanceof PerspectiveCamera) {
      // perspective
      const position = this.object.position;
      this.offset.copy(position).sub(this.target);
      let targetDistance = this.offset.length();

      // half of the fov is center to top of screen
      targetDistance *= Math.tan(((this.object.fov / 2) * Math.PI) / 180.0);

      // we use only clientHeight here so aspect ratio does not distort speed
      this.panLeft(
        (2 * deltaX * targetDistance) / element.clientHeight,
        this.object.matrix
      );
      this.panUp(
        (2 * deltaY * targetDistance) / element.clientHeight,
        this.object.matrix
      );
    } else if (this.object.isOrthographicCamera) {
      // orthographic
      this.panLeft(
        (deltaX * (this.object.right - this.object.left)) /
          this.object.zoom /
          element.clientWidth,
        this.object.matrix
      );
      this.panUp(
        (deltaY * (this.object.top - this.object.bottom)) /
          this.object.zoom /
          element.clientHeight,
        this.object.matrix
      );
    } else {
      // camera neither orthographic nor perspective
      console.warn(
        "WARNING: CameraControls.js encountered an unknown camera type - pan disabled."
      );
      this.enablePan = false;
    }
  }

  dollyIn(dollyScale: number): void {
    if (this.object instanceof PerspectiveCamera) {
      this.scale /= dollyScale;
    } else if (this.object instanceof OrthographicCamera) {
      this.object.zoom = Math.max(
        this.minZoom,
        Math.min(this.maxZoom, this.object.zoom * dollyScale)
      );
      // tilt the camera between min/max polar angle while zooming
      if (this.zoomTilt) {
        this.rotateUp(this.zoomTiltIncrement);
      }
      this.object.updateProjectionMatrix();
      this.zoomChanged = true;
    } else {
      console.warn(
        "WARNING: CameraControls.js encountered an unknown camera type - dolly/zoom disabled."
      );
      this.enableZoom = false;
    }
  }

  dollyOut(dollyScale: number): void {
    if (this.object instanceof PerspectiveCamera) {
      this.scale *= dollyScale;
    } else if (this.object instanceof OrthographicCamera) {
      this.object.zoom = Math.max(
        this.minZoom,
        Math.min(this.maxZoom, this.object.zoom / dollyScale)
      );
      if (this.zoomTilt) {
        this.rotateDown(this.zoomTiltIncrement);
      }
      this.object.updateProjectionMatrix();
      this.zoomChanged = true;
    } else {
      console.warn(
        "WARNING: CameraControls.js encountered an unknown camera type - dolly/zoom disabled."
      );
      this.enableZoom = false;
    }
  }

  //
  // event callbacks - update the object state
  //
  private handleMouseDownRotate(event: MouseEvent) {
    this.rotateStart.set(event.clientX, event.clientY);
  }

  private handleMouseDownDolly(event: MouseEvent) {
    this.dollyStart.set(event.clientX, event.clientY);
  }

  private handleMouseDownPan(event: MouseEvent) {
    this.panStart.set(event.clientX, event.clientY);
  }

  private handleMouseMoveRotate(event: MouseEvent) {
    this.rotateEnd.set(event.clientX, event.clientY);
    this.rotateDelta
      .subVectors(this.rotateEnd, this.rotateStart)
      .multiplyScalar(this.rotateSpeed);
    const element = this.domElement;
    this.rotateLeft((2 * Math.PI * this.rotateDelta.x) / element.clientHeight); // yes, height
    this.rotateUp((2 * Math.PI * this.rotateDelta.y) / element.clientHeight);
    this.rotateStart.copy(this.rotateEnd);
    this.update();
  }

  private handleMouseMoveDolly(event: MouseEvent) {
    this.dollyEnd.set(event.clientX, event.clientY);
    this.dollyDelta.subVectors(this.dollyEnd, this.dollyStart);
    if (this.dollyDelta.y > 0) {
      this.dollyIn(this.getZoomScale());
    } else if (this.dollyDelta.y < 0) {
      this.dollyOut(this.getZoomScale());
    }
    this.dollyStart.copy(this.dollyEnd);
    this.update();
  }

  private handleMouseMovePan(event: MouseEvent) {
    this.panEnd.set(event.clientX, event.clientY);
    this.panDelta
      .subVectors(this.panEnd, this.panStart)
      .multiplyScalar(this.panSpeed);
    this.pan(this.panDelta.x, this.panDelta.y);
    this.panStart.copy(this.panEnd);
    this.update();
  }

  private handleMouseUp(event: MouseEvent) {
    // no-op
    event;
  }

  private handleMouseWheel(event: WheelEvent) {
    const e: any = event as any;
    const isTouchPad =
      e.wheelDeltaY !== undefined
        ? e.wheelDeltaY === -3 * event.deltaY
        : event.deltaMode === 0;

    if (event.deltaY < 0) {
      this.dollyOut(this.getZoomScale(isTouchPad));
    } else if (event.deltaY > 0) {
      this.dollyIn(this.getZoomScale(isTouchPad));
    }
    this.update();
  }

  private handleKeyDown(event: KeyboardEvent) {
    let needsUpdate = false;
    switch (event.keyCode) {
      case this.keys.UP:
        this.pan(0, this.keyPanSpeed);
        needsUpdate = true;
        break;
      case this.keys.BOTTOM:
        this.pan(0, -this.keyPanSpeed);
        needsUpdate = true;
        break;
      case this.keys.LEFT:
        this.pan(this.keyPanSpeed, 0);
        needsUpdate = true;
        break;
      case this.keys.RIGHT:
        this.pan(-this.keyPanSpeed, 0);
        needsUpdate = true;
        break;
    }

    if (needsUpdate) {
      // prevent the browser from scrolling on cursor keys
      event.preventDefault();
      this.update();
    }
  }

  private handleTouchStartRotate(event: TouchEvent) {
    if (event.touches.length == 1) {
      this.rotateStart.set(event.touches[0].pageX, event.touches[0].pageY);
    } else {
      const x = 0.5 * (event.touches[0].pageX + event.touches[1].pageX);
      const y = 0.5 * (event.touches[0].pageY + event.touches[1].pageY);
      this.rotateStart.set(x, y);
    }
  }

  private handleTouchStartPan(event: TouchEvent) {
    if (event.touches.length == 1) {
      this.panStart.set(event.touches[0].pageX, event.touches[0].pageY);
    } else {
      const x = 0.5 * (event.touches[0].pageX + event.touches[1].pageX);
      const y = 0.5 * (event.touches[0].pageY + event.touches[1].pageY);
      this.panStart.set(x, y);
    }
  }

  private handleTouchStartDolly(event: TouchEvent) {
    const dx = event.touches[0].pageX - event.touches[1].pageX;
    const dy = event.touches[0].pageY - event.touches[1].pageY;
    const distance = Math.sqrt(dx * dx + dy * dy);
    this.dollyStart.set(0, distance);
  }

  private handleTouchStartDollyPan(event: TouchEvent) {
    if (this.enableZoom) {
      this.handleTouchStartDolly(event);
    }
    if (this.enablePan) {
      this.handleTouchStartPan(event);
    }
  }

  private handleTouchStartDollyRotate(event: TouchEvent) {
    if (this.enableZoom) {
      this.handleTouchStartDolly(event);
    }
    if (this.enableRotate) {
      this.handleTouchStartRotate(event);
    }
  }

  private handleTouchMoveRotate(event: TouchEvent) {
    if (event.touches.length == 1) {
      this.rotateEnd.set(event.touches[0].pageX, event.touches[0].pageY);
    } else {
      const x = 0.5 * (event.touches[0].pageX + event.touches[1].pageX);
      const y = 0.5 * (event.touches[0].pageY + event.touches[1].pageY);
      this.rotateEnd.set(x, y);
    }
    this.rotateDelta
      .subVectors(this.rotateEnd, this.rotateStart)
      .multiplyScalar(this.rotateSpeed);
    const element = this.domElement;
    this.rotateLeft((2 * Math.PI * this.rotateDelta.x) / element.clientHeight); // yes, height
    this.rotateUp((2 * Math.PI * this.rotateDelta.y) / element.clientHeight);
    this.rotateStart.copy(this.rotateEnd);
  }

  private handleTouchMovePan(event: TouchEvent) {
    if (event.touches.length == 1) {
      this.panEnd.set(event.touches[0].pageX, event.touches[0].pageY);
    } else {
      const x = 0.5 * (event.touches[0].pageX + event.touches[1].pageX);
      const y = 0.5 * (event.touches[0].pageY + event.touches[1].pageY);
      this.panEnd.set(x, y);
    }
    this.panDelta
      .subVectors(this.panEnd, this.panStart)
      .multiplyScalar(this.panSpeed);
    this.pan(this.panDelta.x, this.panDelta.y);
    this.panStart.copy(this.panEnd);
  }

  private handleTouchMoveDolly(event: TouchEvent) {
    const dx = event.touches[0].pageX - event.touches[1].pageX;
    const dy = event.touches[0].pageY - event.touches[1].pageY;
    const distance = Math.sqrt(dx * dx + dy * dy);
    this.dollyEnd.set(0, distance);
    this.dollyDelta.set(
      0,
      Math.pow(this.dollyEnd.y / this.dollyStart.y, this.zoomSpeed)
    );
    this.dollyIn(this.dollyDelta.y);
    this.dollyStart.copy(this.dollyEnd);
  }

  private handleTouchMoveDollyPan(event: TouchEvent) {
    if (this.enableZoom) {
      this.handleTouchMoveDolly(event);
    }

    if (this.enablePan) {
      this.handleTouchMovePan(event);
    }
  }

  private handleTouchMoveDollyRotate(event: TouchEvent) {
    if (this.enableZoom) {
      this.handleTouchMoveDolly(event);
    }

    if (this.enableRotate) {
      this.handleTouchMoveRotate(event);
    }
  }

  private handleTouchEnd(event: TouchEvent) {
    // no-op
    event;
  }

  //
  // event handlers - FSM: listen for events and reset state
  //

  private onMouseDown = (event: MouseEvent) => {
    if (this.enabled === false) {
      return;
    }
    // Prevent the browser from scrolling.
    event.preventDefault();
    // Manually set the focus since calling preventDefault above
    // prevents the browser from setting it automatically.
    this.domElement.focus ? this.domElement.focus() : window.focus();
    let mouseAction;
    switch (event.button) {
      case 0:
        mouseAction = this.mouseButtons.LEFT;
        break;
      case 1:
        mouseAction = this.mouseButtons.MIDDLE;
        break;
      case 2:
        mouseAction = this.mouseButtons.RIGHT;
        break;
      default:
        mouseAction = -1;
    }

    switch (mouseAction) {
      case MOUSE.DOLLY:
        if (this.enableMiddleButtonZoom === false) {
          return;
        }
        this.handleMouseDownDolly(event);
        this.state = this.STATE.DOLLY;
        break;
      case MOUSE.ROTATE:
        if (event.ctrlKey || event.metaKey || event.shiftKey) {
          if (this.enablePan === false) {
            return;
          }
          this.handleMouseDownPan(event);
          this.state = this.STATE.PAN;
        } else {
          if (this.enableRotate === false) {
            return;
          }
          this.handleMouseDownRotate(event);
          this.state = this.STATE.ROTATE;
        }
        break;
      case MOUSE.PAN:
        if (event.ctrlKey || event.metaKey || event.shiftKey) {
          if (this.enableRotate === false) {
            return;
          }
          this.handleMouseDownRotate(event);
          this.state = this.STATE.ROTATE;
        } else {
          if (this.enablePan === false) {
            return;
          }
          this.handleMouseDownPan(event);
          this.state = this.STATE.PAN;
        }
        break;
      default:
        this.state = this.STATE.NONE;
    }
    if (this.state !== this.STATE.NONE) {
      document.addEventListener("mousemove", this.onMouseMove, false);
      document.addEventListener("mouseup", this.onMouseUp, false);
      this.events.dispatchEvent(this.startEvent);
    }
  };

  private onMouseMove = (event: MouseEvent) => {
    if (this.enabled === false) return;

    event.preventDefault();

    switch (this.state) {
      case this.STATE.ROTATE:
        if (this.enableRotate === false) {
          return;
        }
        this.handleMouseMoveRotate(event);
        break;
      case this.STATE.DOLLY:
        if (this.enableMiddleButtonZoom === false) {
          return;
        }
        this.handleMouseMoveDolly(event);
        break;
      case this.STATE.PAN:
        if (this.enablePan === false) {
          return;
        }
        this.handleMouseMovePan(event);
        break;
    }
  };

  private onMouseUp = (event: MouseEvent) => {
    if (this.enabled === false) {
      return;
    }
    this.handleMouseUp(event);
    document.removeEventListener("mousemove", this.onMouseMove, false);
    document.removeEventListener("mouseup", this.onMouseUp, false);
    this.events.dispatchEvent(this.endEvent);
    this.state = this.STATE.NONE;
  };

  private onMouseWheel = (event: WheelEvent) => {
    if (this.enableZoom === false) {
      return;
    }
    if (this.state !== this.STATE.NONE && this.state !== this.STATE.ROTATE) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.events.dispatchEvent(this.startEvent);
    this.handleMouseWheel(event);
    this.events.dispatchEvent(this.endEvent);
  };

  private onKeyDown = (event: KeyboardEvent) => {
    if (!this.enabled || !this.enableKeys || !this.enablePan) {
      return;
    }
    this.handleKeyDown(event);
  };

  private onTouchStart = (event: TouchEvent) => {
    if (this.enabled === false) {
      return;
    }
    event.preventDefault();
    switch (event.touches.length) {
      case 1:
        switch (this.touches.ONE) {
          case TOUCH.ROTATE:
            if (this.enableRotate === false) {
              return;
            }
            this.handleTouchStartRotate(event);
            this.state = this.STATE.TOUCH_ROTATE;
            break;
          case TOUCH.PAN:
            if (this.enablePan === false) {
              return;
            }
            this.handleTouchStartPan(event);
            this.state = this.STATE.TOUCH_PAN;
            break;
          default:
            this.state = this.STATE.NONE;
        }
        break;
      case 2:
        switch (this.touches.TWO) {
          case TOUCH.DOLLY_PAN:
            if (this.enableZoom === false && this.enablePan === false) {
              return;
            }
            this.handleTouchStartDollyPan(event);
            this.state = this.STATE.TOUCH_DOLLY_PAN;
            break;
          case TOUCH.DOLLY_ROTATE:
            if (this.enableZoom === false && this.enableRotate === false) {
              return;
            }
            this.handleTouchStartDollyRotate(event);
            this.state = this.STATE.TOUCH_DOLLY_ROTATE;
            break;
          default:
            this.state = this.STATE.NONE;
        }
        break;
      default:
        this.state = this.STATE.NONE;
    }
    if (this.state !== this.STATE.NONE) {
      this.events.dispatchEvent(this.startEvent);
    }
  };

  private onTouchMove = (event: TouchEvent) => {
    if (this.enabled === false) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    switch (this.state) {
      case this.STATE.TOUCH_ROTATE:
        if (this.enableRotate === false) {
          return;
        }
        this.handleTouchMoveRotate(event);
        this.update();
        break;
      case this.STATE.TOUCH_PAN:
        if (this.enablePan === false) {
          return;
        }
        this.handleTouchMovePan(event);
        this.update();
        break;
      case this.STATE.TOUCH_DOLLY_PAN:
        if (this.enableZoom === false && this.enablePan === false) {
          return;
        }
        this.handleTouchMoveDollyPan(event);
        this.update();
        break;
      case this.STATE.TOUCH_DOLLY_ROTATE:
        if (this.enableZoom === false && this.enableRotate === false) {
          return;
        }
        this.handleTouchMoveDollyRotate(event);
        this.update();
        break;
      default:
        this.state = this.STATE.NONE;
    }
  };

  private onTouchEnd = (event: TouchEvent) => {
    if (this.enabled === false) {
      return;
    }
    this.handleTouchEnd(event);
    this.events.dispatchEvent(this.endEvent);
    this.state = this.STATE.NONE;
  };

  private onContextMenu = (event: MouseEvent) => {
    if (this.enabled === false) {
      return;
    }
    event.preventDefault();
  };
}
