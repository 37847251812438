import { ISceneSerialized } from "../core/Document";

export const SMALL_CONFERENCE_MAP: ISceneSerialized = {
  name: "Conference Area (12x12)",
  args: { backgroundColor: 15790335, gridSize: 12 },
  rooms: [],
  pieces: [
    { prefab: "floor_tile", rotation: 0, x: -2, y: -4 },
    { prefab: "floor_tile", rotation: 0, x: -2, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: -2, y: 4 },
    { prefab: "floor_tile", rotation: 0, x: -3, y: 4 },
    { prefab: "floor_tile", rotation: 0, x: -3, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: -3, y: -4 },
    { prefab: "floor_tile", rotation: 0, x: -3, y: -5 },
    { prefab: "floor_tile", rotation: 0, x: -4, y: -3 },
    { prefab: "floor_tile", rotation: 0, x: -4, y: -2 },
    { prefab: "floor_tile", rotation: 0, x: -4, y: -1 },
    { prefab: "floor_tile", rotation: 0, x: -4, y: 0 },
    { prefab: "floor_tile", rotation: 0, x: -4, y: 1 },
    { prefab: "floor_tile", rotation: 0, x: -4, y: 2 },
    { prefab: "floor_tile", rotation: 0, x: -5, y: 2 },
    { prefab: "floor_tile", rotation: 0, x: -5, y: 1 },
    { prefab: "floor_tile", rotation: 0, x: -5, y: 0 },
    { prefab: "floor_tile", rotation: 0, x: -5, y: -1 },
    { prefab: "floor_tile", rotation: 0, x: -5, y: -2 },
    { prefab: "floor_tile", rotation: 0, x: -1, y: 4 },
    { prefab: "floor_tile", rotation: 0, x: -1, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: -1, y: -4 },
    { prefab: "floor_tile", rotation: 0, x: 0, y: -4 },
    { prefab: "floor_tile", rotation: 0, x: 0, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: 0, y: 4 },
    { prefab: "floor_tile", rotation: 0, x: 1, y: 4 },
    { prefab: "floor_tile", rotation: 0, x: 1, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: 1, y: -4 },
    { prefab: "floor_tile", rotation: 0, x: 2, y: -5 },
    { prefab: "floor_tile", rotation: 0, x: 2, y: -4 },
    { prefab: "floor_tile", rotation: 0, x: 2, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: 2, y: 4 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: 2 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: 1 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: 0 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: -1 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: -2 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: -3 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: -2 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: 0 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: 1 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: 2 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -6, y: -5 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -6, y: -2 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -6, y: -1 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -6, y: 3 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -6, y: 4 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -6, y: 0 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -6, y: 1 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -6, y: 2 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -6, y: -4 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -6, y: -3 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 5, y: -5 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 5, y: -4 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 5, y: -3 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 5, y: -2 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 5, y: -1 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 5, y: 0 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 5, y: 1 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 5, y: 2 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 5, y: 3 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 5, y: 4 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -5, y: 5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -4, y: 5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -2, y: 5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 0, y: 5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 2, y: 5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 3, y: 5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 4, y: 5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 1, y: 5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -1, y: 5 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -3, y: 5 },
    { prefab: "walls/wall_2_se", rotation: 90, x: -6, y: 5 },
    { prefab: "walls/wall_2_se", rotation: 180, x: 5, y: 5 },
    { prefab: "walls/wall_2_se", rotation: 270, x: 5, y: -6 },
    { prefab: "walls/wall_2_se", rotation: 0, x: -6, y: -6 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -5, y: -6 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -4, y: -6 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -3, y: -6 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -2, y: -6 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -1, y: -6 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 0, y: -6 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 1, y: -6 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 2, y: -6 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 3, y: -6 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 4, y: -6 },
    { prefab: "ls_2", rotation: 0, x: -3, y: -3 },
    { prefab: "lounge", rotation: 0, x: 3, y: -5 },
    { prefab: "lounge", rotation: 0, x: -5, y: -5 },
    { prefab: "arcade", rotation: 90, x: -5, y: 3 },
    { prefab: "arcade", rotation: 270, x: 3, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -5 },
    { prefab: "floor_tile", rotation: 0, x: 0, y: -5 },
    { prefab: "floor_tile", rotation: 0, x: 1, y: -5 },
    { prefab: "floor_tile", rotation: 0, x: -2, y: -5 },
    { prefab: "cactus", rotation: 0, x: 4, y: -3 },
    { prefab: "cactus", rotation: 0, x: -5, y: -3 },
  ],
};
