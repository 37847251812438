import { Vector2 } from "three";
import { IScenePiece } from "../../core/Document";
import { screenToCamera, TILE_SIZE } from "../../core/Scene";
import { MovePrefabAction } from "../SceneEditorActions";
import { SceneEditorTool } from "../SceneEditorTool";

/**
 * Prefab creation tool.
 */
export class MoveTool extends SceneEditorTool {
  static NAME = "move";
  name = MoveTool.NAME;
  tooltip = "Move existing objects by dragging them around";
  icon = "open_with";

  private dragState: {
    start: Vector2;
    offset: Vector2;
    target: IScenePiece;
    overlaps: IScenePiece[];
  } | null = null;

  /** Hide the "preview" shape if there is one. */
  escapeTool(): boolean {
    this.clearSelection();
    return true;
  }

  clearSelection(restoreDragPrefab = true): void {
    if (this.dragState === null) {
      return;
    }
    // Restore hidden prefabs
    for (const obj of this.dragState.overlaps) {
      obj.instance.visible = true;
    }

    // Return the drag piece to its original location
    if (restoreDragPrefab) {
      const halfWidth = this.dragState.target.prefab.width / 2;
      const halfHeight = this.dragState.target.prefab.height / 2;
      this.dragState.target.instance.position.set(
        this.dragState.start.x + halfWidth,
        0,
        this.dragState.start.y + halfHeight
      );
      this.dragState.target.instance.visible = true;
    }
    this.dragState = null;
  }
  onMouseDown(event: PointerEvent): void {
    if (!this.editor || event.button !== 0) {
      return;
    }
    const [x, y] = this.gridPointFromEvent(event);
    const target = this.editor.document.pick(x, y);
    if (!target) {
      return;
    }
    const start = new Vector2(target.box.min.x, target.box.min.y);
    const offset = new Vector2(x - start.x, y - start.y);
    this.dragState = {
      overlaps: [],
      target,
      start,
      offset,
    };
  }

  /**
   * Determine if placing a prefab at the mouse location would work, and
   * if it would, move a "preview" prefab into position there to show what
   * will happen if you click.
   */
  onMouseMove(event: PointerEvent): void {
    if (!this.editor?.host || !this.dragState) {
      return;
    }
    const host = this.editor.host;
    const [x, y] = this.gridPointFromEvent(event);
    const [cameraX, cameraY] = screenToCamera(
      host.container(),
      event.offsetX,
      event.offsetY
    );
    const hits = this.editor.pick(cameraX, cameraY);
    const target = this.dragState.target;
    const prefabWidth = target.prefab.width;
    const prefabHeight = target.prefab.height;
    const hit = hits[0];
    if (!hit) {
      // Hide when dragged out of bounds
      target.instance.visible = false;
    } else {
      target.instance.position.copy(hit.point);
      // snap to grid in world position
      let targetX = target.instance.position.x;
      const gridX = Math.floor(targetX / TILE_SIZE) * TILE_SIZE;
      let targetZ = target.instance.position.z;
      const gridY = Math.floor(targetZ / TILE_SIZE) * TILE_SIZE;
      const willFit = this.editor.document.boxWillBeInBounds(
        x - this.dragState.offset.x,
        y - this.dragState.offset.y,
        prefabWidth,
        prefabHeight
      );

      // Restore hidden prefabs
      for (const obj of this.dragState.overlaps) {
        obj.instance.visible = true;
      }
      // Update them with new ones
      const overlaps = this.editor.document.boxCollidesWith(
        x - this.dragState.offset.x,
        y - this.dragState.offset.y,
        prefabWidth,
        prefabHeight
      );
      // Exclude the move target
      this.dragState.overlaps = overlaps.filter(
        (piece) => piece.instance.id !== target.instance.id
      );

      // Hide overlapped objects
      for (const obj of this.dragState.overlaps) {
        obj.instance.visible = false;
      }

      // Reposition the preview prefab
      targetX = gridX + prefabWidth / 2 - this.dragState.offset.x;
      targetZ = gridY + prefabHeight / 2 - this.dragState.offset.y;
      target.instance.position.x = targetX;
      target.instance.position.y = 0;
      target.instance.position.z = targetZ;
      target.instance.visible = willFit;
    }
  }
  /** Place a prefab at the pointer location */
  onMouseUp(event: PointerEvent): void {
    if (event.button !== 0 || !this.editor?.host || !this.dragState) {
      this.clearSelection();
      return;
    }
    const [x, y] = this.gridPointFromEvent(event);
    const width = this.dragState.target.prefab.width;
    const height = this.dragState.target.prefab.height;
    const fromX = this.dragState.start.x;
    const fromY = this.dragState.start.y;
    const toX = x - this.dragState.offset.x;
    const toY = y - this.dragState.offset.y;
    const inBounds = this.editor.document.boxWillBeInBounds(
      toX,
      toY,
      width,
      height
    );
    const didMove = fromX !== toX || fromY !== toY;
    if (!inBounds || !didMove) {
      this.clearSelection();
      return;
    }
    const prefabAction = new MovePrefabAction(
      this.dragState.target,
      fromX,
      fromY,
      toX,
      toY,
      this.dragState.overlaps,
      this.editor.document
    );
    this.editor.actions.executeAction(prefabAction);
    this.clearSelection(false);
  }
}
