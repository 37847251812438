<template>
  <div class="status-bar">
    <section v-if="triangles">Triangles: {{ info.triangles }}</section>
    <section v-if="draws">Draws/Render: {{ info.drawsPerFrame }}</section>
    <section v-if="frames">Renders: {{ info.framesDrawn }}</section>
    <section v-if="info.seatCount != -1">Seats: {{ info.seatCount }}</section>
  </div>
</template>

<script lang="ts">
import { Prefab } from "@/welo/core/Prefabs";
import { Mesh } from "three";
import { defineComponent, inject, onBeforeUnmount, onMounted, ref } from "vue";
import { DocumentLoadedEvent, RenderStatsEvent } from "../welo/core/Document";
import { EDITOR_TYPES } from "../welo/core/Editor";
import { SceneEditor } from "../welo/editor/SceneEditor";
import { OfficeDocument } from "../welo/office/OfficeDocument";

export interface SceneViewData {
  prefabs: Prefab[];
  plane: Mesh;
  unmounted: boolean;
  containerElement: HTMLElement | null;
}

export interface StatusBarProps {
  readonly frames?: boolean;
  readonly triangles?: boolean;
  readonly draws?: boolean;
  readonly source?: string;
}

export default defineComponent({
  name: "StatusBar",
  props: {
    source: {
      type: String,
      default: EDITOR_TYPES[0],
    },
    triangles: {
      type: Boolean,
      default: true,
    },
    draws: {
      type: Boolean,
      default: true,
    },
    frames: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: StatusBarProps) {
    const office = inject<OfficeDocument>("office");
    const editor = inject<SceneEditor>("editor");
    if (!office || !editor) {
      throw new Error("missing editor/office injections");
    }
    const source = props.source === "office" ? office : editor.document;
    const seatCount = props.source === "office" ? office.getSeatCount() : -1;
    const info = ref({
      triangles: 0,
      drawsPerFrame: 0,
      framesDrawn: 0,
      seatCount,
    });
    function updateStats(event: RenderStatsEvent): void {
      info.value.triangles = event.info.render.triangles;
      info.value.drawsPerFrame = event.info.render.calls;
      info.value.framesDrawn = event.info.render.frame;
    }
    function updateSeats(event: DocumentLoadedEvent): void {
      info.value.seatCount = event.document.getSeatCount();
    }
    onMounted(() => {
      source.events.addEventListener("loaded", updateSeats as any);
      source.events.addEventListener("render-stats", updateStats as any);
    });
    onBeforeUnmount(() => {
      source.events.removeEventListener("loaded", updateSeats as any);
      source.events.removeEventListener("render-stats", updateStats as any);
    });
    return {
      info,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.status-bar {
  box-sizing: border-box;
  background-color: var(--q-primary);
  position: absolute;
  height: 30px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.status-bar > section {
  display: inline;
  min-width: 150px;
  color: white;
  font-weight: bold;
}
</style>
