<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated>
      <q-toolbar>
        <q-toolbar-title shrink
          ><img src="welo-logo.svg" height="26"
        /></q-toolbar-title>
        <q-tabs dense v-model="currentTab" stretch>
          <q-tab name="office" label="Preview" />
          <q-tab name="scene" label="Edit" />
          <q-tab name="prefab" label="Browse" />
        </q-tabs>
        <q-toolbar-title></q-toolbar-title>
        <q-btn
          flat
          dense
          round
          v-if="currentTab == 'office'"
          @click="toggleShadows()"
          :aria-label="currentShadows ? 'Turn off shadows' : 'Turn on shadows'"
          :icon="currentShadows ? 'lightbulb' : 'o_lightbulb'"
        >
          <q-tooltip anchor="center left" self="center end">{{
            currentShadows ? "Turn off shadows" : "Turn on shadows"
          }}</q-tooltip></q-btn
        >
        <q-btn-dropdown
          icon="view_in_ar"
          flat
          dense
          round
          v-if="currentTab == 'office'"
          aria-label="Set environment map"
        >
          <q-list class="envs">
            <q-item
              clickable
              v-for="envMap in envMaps"
              v-bind:key="envMap.id"
              v-close-popup
              @click="setEnvironmentMap(envMap)"
              :class="{ selected: currentEnvMap == envMap.id }"
            >
              <q-item-section>
                <q-item-label>{{ envMap.name }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        <!-- Toggle prefab palette -->
        <q-btn
          flat
          dense
          round
          v-if="currentTab != 'office'"
          @click="drawerOpen = !drawerOpen"
          aria-label="Menu"
          icon="palette"
        >
          <q-tooltip anchor="center left" self="center end"
            >Toggle the prefab palette</q-tooltip
          ></q-btn
        >
        <!-- Swap map -->
        <q-btn-dropdown
          icon="map"
          flat
          dense
          round
          aria-label="Set current map"
          v-if="currentTab != 'prefab'"
        >
          <q-list class="maps">
            <q-item
              clickable
              v-for="map in allMaps"
              v-bind:key="map.name"
              v-close-popup
              @click="setCurrentMap(map)"
              :class="{ selected: viewMap.name == map.name }"
            >
              <q-item-section>
                <q-item-label>{{ map.name }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-toolbar>
    </q-header>

    <!-- Toolbox -->
    <toolbox v-if="currentTab === 'scene'"></toolbox>
    <!-- Palette -->
    <div
      class="palette row"
      push
      :class="{ shown: drawerOpen }"
      v-show="prefabTypes.length > 0"
    >
      <q-btn
        flat
        class="prefab"
        v-for="prefabType in prefabTypes"
        v-bind:key="prefabType"
        @click="setPrefabType(prefabType)"
        :class="{ selected: createPrefab == prefabType }"
      >
        <PrefabThumbnail :name="prefabType" />
      </q-btn>
    </div>

    <q-page-container>
      <SceneView v-if="currentTab === 'scene'" />
      <OfficeView v-if="currentTab === 'office'" />
      <PrefabEditor v-if="currentTab === 'prefab'" :name="createPrefab" />
    </q-page-container>
  </q-layout>
</template>

<script lang="ts">
import PrefabThumbnail from "@/components/PrefabThumbnail.vue";
import Toolbox from "@/components/Toolbox.vue";
import OfficeView from "@/tabs/OfficeView.vue";
import PrefabEditor from "@/tabs/PrefabView.vue";
import SceneView from "@/tabs/SceneView.vue";
import { ISceneSerialized } from "@/welo/core/Document";
import { EditorTypes } from "@/welo/core/Editor";
import { ENVIRONMENT_MAPS, IEnvironmentMap } from "@/welo/core/EnvironmentMaps";
import { PieceTypes, PREFAB_TYPES, TILE_TYPES } from "@/welo/core/Prefabs";
import { Settings } from "@/welo/core/Settings";
import {
  EDITOR_DOCUMENT,
  OFFICE_DOCUMENT,
  SCENE_EDITOR,
} from "@/welo/core/Singletons";
import { FloodFillTool } from "@/welo/editor/tools/FloodFillTool";
import { PencilTool } from "@/welo/editor/tools/PencilTool";
import { PrefabTool } from "@/welo/editor/tools/PrefabTool";
import { MAPS } from "@/welo/maps";
import { Dialog } from "quasar";
import { computed, defineComponent, provide, ref } from "vue";

/** Tools that require the palette to be shown */
const PALETTE_TOOLS = [PrefabTool.NAME, PencilTool.NAME, FloodFillTool.NAME];

export default defineComponent({
  name: "LayoutDefault",
  components: {
    OfficeView,
    SceneView,
    PrefabEditor,
    PrefabThumbnail,
    Toolbox,
  },
  methods: {
    setEnvironmentMap(envMap: IEnvironmentMap) {
      if (this.currentEnvMap === envMap.id) {
        return;
      }
      if (this.office) {
        this.office.setEnvironmentMap(envMap.id);
        this.currentEnvMap = envMap.id;
      }
    },
    setCurrentMap(map: ISceneSerialized) {
      if (this.viewMap.name === map.name) {
        return;
      }
      const finalizeChange = () => {
        this.editor.document.load(map).then(() => {
          this.editor.actions.clear();
          this.viewMap = map;
        });
      };
      if (!this.editor.actions.isDirty()) {
        return finalizeChange();
      }
      Dialog.create({
        title: "Discard Current Changes?",
        message:
          "Loading a new map now will discard your current changes and cannot be undone.",
        cancel: true,
        persistent: true,
      }).onOk(finalizeChange);
    },
    toggleShadows() {
      const old = this.currentShadows;
      const newValue = !old;
      this.currentShadows = newValue;
      this.office.setShadows(newValue);
    },
    setPrefabType(ofType: PieceTypes) {
      this.createPrefab = ofType;
      if (!this.activeTool || PALETTE_TOOLS.indexOf(this.activeTool) === -1) {
        this.activeTool = PrefabTool.NAME;
        this.editor.setActiveTool(PrefabTool.NAME);
      }
    },
  },
  beforeUnmount() {
    if (this.office) {
      this.office.renderer.dispose();
    }
    if (this.editor) {
      this.editor.document.renderer.dispose();
    }
  },
  setup() {
    const mapName = Settings.readSetting(Settings.ACTIVE_MAP, MAPS[0].name);
    let map: ISceneSerialized | undefined = MAPS.find(
      (m) => m.name === mapName
    );
    if (!map) {
      console.warn(`Couldn't find: ${mapName}. Using default: ${MAPS[0].name}`);
      map = MAPS[0];
    }
    const createPrefab = ref<PieceTypes>(PREFAB_TYPES[0]);
    EDITOR_DOCUMENT.load(map);
    const currentTab = ref<EditorTypes>(
      Settings.readSetting(Settings.ACTIVE_TAB, "office")
    );
    const currentEnvMap = ref(
      Settings.readSetting(Settings.ACTIVE_ENV_MAP, "")
    );
    const currentShadows = ref<boolean>(
      parseInt(Settings.readSetting(Settings.USE_SHADOWS, "0"), 10) > 0
    );
    const viewMap = ref<ISceneSerialized>(map);
    const drawerOpen = ref(false);
    const activeTool = ref(SCENE_EDITOR.getActiveToolName());
    provide("viewMap", viewMap);
    provide("drawerOpen", drawerOpen);
    provide("activeTool", activeTool);
    provide("office", OFFICE_DOCUMENT);
    provide("editor", SCENE_EDITOR);
    provide("createPrefab", createPrefab);
    provide("currentEnvMap", currentEnvMap);
    provide("currentShadows", currentShadows);
    const prefabTypes = computed(() => {
      if (currentTab.value === "scene") {
        if (activeTool.value === PrefabTool.NAME) {
          return PREFAB_TYPES;
        } else if (activeTool.value === PencilTool.NAME) {
          return TILE_TYPES;
        } else if (activeTool.value === FloodFillTool.NAME) {
          return TILE_TYPES;
        } else {
          return [];
        }
      }
      const result: any[] = [];
      return result.concat(PREFAB_TYPES, TILE_TYPES);
    });

    return {
      currentEnvMap,
      currentShadows,
      allMaps: MAPS,
      envMaps: ENVIRONMENT_MAPS,
      activeTool,
      editor: SCENE_EDITOR,
      office: OFFICE_DOCUMENT,
      viewMap,
      currentTab,
      drawerOpen,
      prefabTypes,
      createPrefab,
    };
  },
  watch: {
    currentTab() {
      if (this.currentTab === "office") {
        this.drawerOpen = false;
      } else if (this.currentTab === "prefab") {
        this.drawerOpen = true;
      }
      Settings.writeSetting(Settings.ACTIVE_TAB, this.currentTab);
    },
    viewMap() {
      Settings.writeSetting(Settings.ACTIVE_MAP, this.viewMap.name);
    },
    activeTool() {
      if (this.activeTool && PALETTE_TOOLS.indexOf(this.activeTool) !== -1) {
        this.drawerOpen = true;
      }
    },
    currentEnvMap() {
      Settings.writeSetting(Settings.ACTIVE_ENV_MAP, this.currentEnvMap);
    },
    currentShadows() {
      Settings.writeSetting(
        Settings.USE_SHADOWS,
        this.currentShadows ? "1" : "0"
      );
    },
  },
});
</script>

<style>
html,
body {
  overflow: hidden;
  height: 100%;
  min-height: 100%;
}
.q-btn-dropdown__arrow {
  margin-left: 0 !important;
}
.q-tooltip {
  font-size: 1em;
}
.q-notification {
  transition: transform 0.4s, opacity 0.4s;
}

/* width */
::-webkit-scrollbar {
  padding: 2px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  background: rgba(0, 0, 0, 0.3);
  padding: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.6);
}

.palette {
  border-radius: 7px;
  border: 2px solid white;
  background-color: var(--q-primary);
  color: white;
  position: fixed;
  top: 64px;
  max-height: 80%;
  width: 225px;
  flex-direction: row;
  z-index: 1;
  overflow-y: auto;
  padding: 6px;
  transition: 0.2s ease-in;
  right: 10px;
  transform: translateX(120%);
  justify-content: center;
}
.palette.shown {
  transform: translateX(0);
}

.palette > .prefab {
  border: 2px solid white;
  border-radius: 7px;
  padding: 6px;
  box-sizing: border-box;
  background-color: white;
  margin: 6px;
}
.palette > .selected {
  background-color: var(--q-accent);
  border-color: var(--q-accent);
}
.no-margin {
  margin: 0;
}
.envs > .selected {
  background-color: var(--q-accent);
  border-color: var(--q-accent);
}
.maps > .selected {
  background-color: var(--q-accent);
  border-color: var(--q-accent);
}

.q-popup-edit {
  padding: 0;
}
</style>
