import { ISceneSerialized } from "../core/Document";

export const WALLS_MAP: ISceneSerialized = {
  name: "Office with Walls",
  args: { backgroundColor: 13428479, gridSize: 16 },
  rooms: [],
  pieces: [
    { prefab: "conference_1", rotation: 0, x: -7, y: -7 },
    { prefab: "conference_1", rotation: 0, x: -2, y: -7 },
    { prefab: "conference_1", rotation: 0, x: 3, y: -7 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 2, y: -7 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 2, y: -6 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 2, y: -5 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 2, y: -4 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -3, y: -7 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -3, y: -6 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -3, y: -5 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -3, y: -4 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 6, y: -8 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 5, y: -8 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 4, y: -8 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 3, y: -8 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 1, y: -8 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 0, y: -8 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -1, y: -8 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -2, y: -8 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -4, y: -8 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -5, y: -8 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -6, y: -8 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -7, y: -8 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -8, y: -7 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -8, y: -6 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -8, y: -5 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -8, y: -4 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 7, y: -7 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 7, y: -6 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 7, y: -5 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 7, y: -4 },
    { prefab: "walls/wall_2_sew", rotation: 0, x: 2, y: -8 },
    { prefab: "walls/wall_2_sew", rotation: 0, x: -3, y: -8 },
    { prefab: "walls/wall_2_se", rotation: 0, x: -8, y: -8 },
    { prefab: "walls/wall_2_se", rotation: 270, x: 7, y: -8 },
    { prefab: "walls/wall_2_se", rotation: 180, x: 7, y: -3 },
    { prefab: "walls/wall_2_sew", rotation: 180, x: 2, y: -3 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -2, y: -3 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 1, y: -3 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 6, y: -3 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: -3 },
    { prefab: "floor_tile", rotation: 0, x: 5, y: -3 },
    { prefab: "floor_tile", rotation: 0, x: -1, y: -3 },
    { prefab: "floor_tile", rotation: 0, x: 0, y: -3 },
    { prefab: "floor_tile", rotation: 0, x: -6, y: -3 },
    { prefab: "floor_tile", rotation: 0, x: -5, y: -3 },
    { prefab: "workstation_1", rotation: 0, x: 4, y: 4 },
    { prefab: "arcade", rotation: 0, x: 5, y: 1 },
    { prefab: "lounge", rotation: 0, x: 2, y: 1 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 2, y: 7 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 3, y: 7 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 4, y: 7 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 5, y: 7 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 6, y: 7 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 7, y: 6 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 7, y: 5 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 7, y: 4 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 7, y: 3 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 7, y: 2 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 7, y: 1 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 6, y: 0 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 5, y: 0 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 4, y: 0 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 3, y: 0 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 2, y: 0 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 1, y: 1 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 1, y: 2 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 1, y: 4 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 1, y: 5 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: 1, y: 6 },
    { prefab: "walls/wall_2_se", rotation: 180, x: 7, y: 7 },
    { prefab: "walls/wall_2_se", rotation: 270, x: 7, y: 0 },
    { prefab: "walls/wall_2_se", rotation: 0, x: 1, y: 0 },
    { prefab: "floor_tile", rotation: 0, x: 2, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: 1, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: 5, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: 6, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: 2 },
    { prefab: "cactus", rotation: 0, x: 4, y: 1 },
    { prefab: "floor_tile", rotation: 0, x: 2, y: 4 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: 4 },
    { prefab: "entrance", rotation: 0, x: -8, y: 4 },
    { prefab: "trees/tree_2", rotation: 0, x: -8, y: 3 },
    { prefab: "trees/tree_2", rotation: 0, x: -7, y: 3 },
    { prefab: "trees/tree_2", rotation: 0, x: -6, y: 3 },
    { prefab: "trees/tree_2", rotation: 0, x: -5, y: 3 },
    { prefab: "trees/tree_2", rotation: 0, x: -5, y: 4 },
    { prefab: "trees/tree_1", rotation: 0, x: -5, y: 7 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -8, y: 2 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -7, y: 2 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -6, y: 2 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -5, y: 2 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -4, y: 3 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -4, y: 4 },
    { prefab: "floor_tile", rotation: 0, x: -4, y: -2 },
    { prefab: "floor_tile", rotation: 0, x: -3, y: -1 },
    { prefab: "floor_tile", rotation: 0, x: -3, y: 0 },
    { prefab: "floor_tile", rotation: 0, x: -3, y: 1 },
    { prefab: "floor_tile", rotation: 0, x: -2, y: -1 },
    { prefab: "floor_tile", rotation: 0, x: -1, y: -2 },
    { prefab: "floor_tile", rotation: 0, x: 0, y: -2 },
    { prefab: "floor_tile", rotation: 0, x: 1, y: -2 },
    { prefab: "floor_tile", rotation: 0, x: 0, y: -1 },
    { prefab: "floor_tile", rotation: 0, x: -1, y: -1 },
    { prefab: "floor_tile", rotation: 0, x: -1, y: 2 },
    { prefab: "floor_tile", rotation: 0, x: -2, y: 1 },
    { prefab: "floor_tile", rotation: 0, x: -2, y: 2 },
    { prefab: "floor_tile", rotation: 0, x: -2, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: -3, y: 2 },
    { prefab: "floor_tile", rotation: 0, x: -3, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: -3, y: 4 },
    { prefab: "floor_tile", rotation: 0, x: -3, y: 6 },
    { prefab: "floor_tile", rotation: 0, x: -4, y: 6 },
    { prefab: "floor_tile", rotation: 0, x: -4, y: 5 },
    { prefab: "floor_tile", rotation: 0, x: -5, y: 5 },
    { prefab: "floor_tile", rotation: 0, x: -5, y: 6 },
    { prefab: "floor_tile", rotation: 0, x: 0, y: 4 },
    { prefab: "floor_tile", rotation: 0, x: 0, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: 0, y: 2 },
    { prefab: "floor_tile", rotation: 0, x: -1, y: 3 },
    { prefab: "floor_tile", rotation: 0, x: -1, y: 4 },
    { prefab: "floor_tile", rotation: 0, x: -2, y: 5 },
    { prefab: "floor_tile", rotation: 0, x: -2, y: 4 },
    { prefab: "floor_tile", rotation: 0, x: -3, y: 5 },
    { prefab: "floor_tile", rotation: 0, x: 1, y: -1 },
    { prefab: "floor_tile", rotation: 0, x: 2, y: -1 },
    { prefab: "floor_tile", rotation: 0, x: 2, y: -2 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: -2 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: -1 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: -2 },
    { prefab: "floor_tile", rotation: 0, x: 5, y: -2 },
    { prefab: "floor_tile", rotation: 0, x: 4, y: -1 },
    { prefab: "floor_tile", rotation: 0, x: 5, y: -1 },
    { prefab: "walls/wall_2_sew", rotation: 180, x: 1, y: 7 },
    { prefab: "floor_tile", rotation: 0, x: -3, y: -2 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 3, y: -3 },
    { prefab: "floor_tile", rotation: 0, x: -2, y: -2 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -3, y: 7 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -2, y: 7 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -1, y: 7 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: 0, y: 7 },
    { prefab: "walls/wall_2_se", rotation: 90, x: -4, y: 7 },
    { prefab: "floor_tile", rotation: 0, x: -5, y: 0 },
    { prefab: "floor_tile", rotation: 0, x: -4, y: 0 },
    { prefab: "lounge", rotation: 0, x: -1, y: 5 },
    { prefab: "lounge", rotation: 0, x: 6, y: -2 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -8, y: -3 },
    { prefab: "walls/wall_2_se", rotation: 90, x: -8, y: -2 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -7, y: -2 },
    { prefab: "walls/wall_2_ns", rotation: 90, x: -6, y: -2 },
    { prefab: "walls/wall_2_se", rotation: 270, x: -5, y: -2 },
    { prefab: "floor_tile", rotation: 0, x: -4, y: -3 },
    { prefab: "walls/wall_2_se", rotation: 90, x: -5, y: -1 },
    { prefab: "lounge", rotation: 0, x: -1, y: 0 },
    { prefab: "workstation_1", rotation: 0, x: -8, y: -1 },
    { prefab: "cactus", rotation: 0, x: -7, y: -3 },
    { prefab: "floor_tile", rotation: 0, x: -2, y: 6 },
    { prefab: "floor_tile", rotation: 0, x: -2, y: 0 },
    { prefab: "walls/wall_2_se", rotation: 90, x: -3, y: -3 },
    { prefab: "walls/wall_2_se", rotation: 270, x: -4, y: -1 },
    { prefab: "walls/wall_2_sew", rotation: 270, x: -4, y: 2 },
    { prefab: "walls/wall_2_ns", rotation: 0, x: -4, y: 1 },
    { prefab: "floor_tile", rotation: 0, x: -5, y: 1 },
    { prefab: "white_board_ns", rotation: 0, x: 2, y: 5 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: 5 },
    { prefab: "floor_tile", rotation: 0, x: 3, y: 6 },
  ],
};
