import { Easing, Tween } from "@tweenjs/tween.js";
import { MathUtils, Mesh, MeshBasicMaterial, Object3D, Vector3 } from "three";
import { IScenePiece, ScenePieceRotation } from "../core/Document";
import { IEditorTool } from "../core/Editor";
import {
  getPrefabRoot,
  normalizeRotation,
  NULL_POINT,
  screenToCamera,
  snapObjectToGrid,
  TILE_SIZE,
} from "../core/Scene";
import { SceneEditor } from "./SceneEditor";
import { RotatePrefabAction } from "./SceneEditorActions";

export abstract class SceneEditorTool implements IEditorTool {
  constructor(public editor: SceneEditor | null = null) {}
  abstract name: string;
  abstract tooltip: string;
  abstract icon: string;
  private static SELECT_MATERIAL = new MeshBasicMaterial({
    color: 0xff8000,
    opacity: 0.5,
    transparent: true,
  });

  escapeTool(): boolean {
    return true;
  }
  activateTool(editor: SceneEditor): boolean {
    this.editor = editor;
    return !!this.editor;
  }
  onMouseMove(event: PointerEvent): void {
    return;
  }
  onMouseUp(event: PointerEvent): void {
    return;
  }
  onMouseDown(event: PointerEvent): void {
    return;
  }
  onMouseWheel(event: WheelEvent): void {
    // const delta: number = event.detail || event.deltaY;
    // if (!this.editor) {
    //   return;
    // }
    // const { camera } = this.editor.document;
    // const move: number = camera.zoom / 10;
    // camera.zoom += delta > 0 ? move : -move;
    // camera.updateProjectionMatrix();
    return;
  }
  onKeyDown(event: KeyboardEvent): boolean {
    return false;
  }
  onKeyUp(event: KeyboardEvent): boolean {
    return false;
  }
  /** Find the world point under the cursor. */
  hitPointFromEvent(event: PointerEvent): [number, number] {
    if (!this.editor) {
      throw new Error("Invalid editor!");
    }
    const [cameraX, cameraY] = screenToCamera(
      this.editor.host.container(),
      event.offsetX,
      event.offsetY
    );
    const hits = this.editor.pick(cameraX, cameraY);
    const hit = hits[0];
    if (!hit) {
      return NULL_POINT;
    }
    return [hit.point.x, hit.point.z];
  }
  /** Find the world point under the cursor. */
  gridPointFromEvent(event: PointerEvent): [number, number] {
    const [x, y] = this.hitPointFromEvent(event);
    const gridX = Math.floor(x / TILE_SIZE);
    const gridY = Math.floor(y / TILE_SIZE);
    return [gridX, gridY];
  }

  /** Find an object in the scene that is under the pointer. */
  objectFromEvent(event: PointerEvent, returnRoot = true): Object3D | null {
    if (!this.editor) {
      throw new Error("Invalid editor!");
    }
    const [cameraX, cameraY] = screenToCamera(
      this.editor.host.container(),
      event.offsetX,
      event.offsetY
    );
    const hits = this.editor
      .pick(cameraX, cameraY)
      .filter((h) => h.object.name !== "Grid");
    const hit = hits[0];
    if (!hit) {
      return null;
    }
    if (!returnRoot) {
      return hit.object;
    }
    const root = getPrefabRoot(hit.object);
    if (!root || !root.userData.prefab) {
      return null;
    }
    return root;
  }

  /** Create a prefab for the given world coordinates. */
  createFor(worldX: number, worldZ: number): IScenePiece {
    if (!this.editor?.currentPrefab) {
      throw new Error("invalid prefab");
    }
    const template = this.editor.currentPrefab.clone();
    const instance = template.object.clone();
    instance.visible = true;
    const point = new Vector3(worldX, 0, worldZ);
    snapObjectToGrid(instance, point, template.width, template.height);
    const gridX = Math.floor(point.x / TILE_SIZE) * TILE_SIZE;
    const gridY = Math.floor(point.z / TILE_SIZE) * TILE_SIZE;
    const piece = this.editor.document.addPiece(
      template,
      instance,
      gridX,
      gridY,
      0
    );
    return piece;
  }

  //
  // Animations
  //
  private _duration = 200;

  /** Lift an object above the grid one unit to manipulate it. */
  lift(target: Object3D, y = 1): Promise<Object3D> {
    return new Promise((resolve) => {
      new Tween(target.position)
        .onComplete(() => resolve(target))
        .easing(Easing.Elastic.Out)
        .to({ y }, this._duration)
        .start();
    });
  }

  /** Return a lifted object to the grid surface. */
  drop(target: Object3D): Promise<Object3D> {
    return new Promise((resolve) => {
      new Tween(target.position)
        .easing(Easing.Elastic.Out)
        .to({ y: 0 }, this._duration)
        .onComplete(() => resolve(target))
        .start();
    });
  }

  /** Replace the materials on the given object with the selection material */
  select(
    target: Object3D,
    targetMaterial = SceneEditorTool.SELECT_MATERIAL
  ): void {
    if (target.userData.saveMaterials) {
      // Don't select already selected objects
      return;
    }
    target.userData.saveMaterials = {};
    target.traverseVisible((child: Object3D) => {
      if (child instanceof Mesh) {
        target.userData.saveMaterials[child.id] = child.material;
        child.material = targetMaterial;
      }
    });
  }

  /** Replace the materials selection material on the given object with the original materials */
  unselect(target: Object3D): void {
    if (target.userData.saveMaterials) {
      const materials = target.userData.saveMaterials;
      target.traverseVisible((child: Object3D) => {
        if (child instanceof Mesh && materials[child.id]) {
          child.material = target.userData.saveMaterials[child.id];
        }
      });
      delete target.userData.saveMaterials;
    }
  }

  //
  // Document actions
  //

  /** Rotate an object in the document and return the rotated angle. */
  rotate(target: Object3D, backwards = false): Promise<ScenePieceRotation> {
    return new Promise((resolve, reject) => {
      if (!this.editor) {
        return reject("invalid editor");
      }
      // For non-rectangular pieces, only rotate 180deg at a time (so the
      // grid alignment doesn't get jacked because of object center origin)
      let newDegrees = this.editor.document.isSquare(target) ? 90 : 180;
      newDegrees *= backwards ? -1 : 1;
      let currentAngle = MathUtils.radToDeg(target.rotation.y);
      currentAngle -= currentAngle % Math.abs(newDegrees);
      const targetDeg = normalizeRotation(
        currentAngle + newDegrees
      ) as ScenePieceRotation;
      const targetRot = MathUtils.degToRad(targetDeg);
      new Tween(target.rotation)
        .easing(Easing.Elastic.Out)
        .to({ y: targetRot }, this._duration)
        .onComplete(() => {
          // Execute rotate action
          if (this.editor) {
            const prefabAction = new RotatePrefabAction(
              target,
              currentAngle as ScenePieceRotation,
              targetDeg,
              this.editor.document
            );
            this.editor.actions.executeAction(prefabAction);
          }
          resolve(targetDeg);
        })
        .start();
    });
  }
}
