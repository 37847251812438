import { Sprite, SpriteMaterial } from "three";
import { TEXTURE_LOADER } from "../core/Prefabs";
import { ILoadable } from "../core/Loadable";

export class Avatar extends Sprite implements ILoadable<Avatar> {
  constructor(public imgSource: string = "/img/avatar.png") {
    super(
      new SpriteMaterial({
        depthTest: false,
      })
    );
  }

  public load(): Promise<Avatar> {
    return new Promise((resolve, reject) => {
      this.material.map = TEXTURE_LOADER.load(
        this.imgSource,
        () => resolve(this),
        undefined,
        reject
      );
      this.scale.set(0.5, 0.5, 0.5);
    });
  }

  public unload(): Promise<Avatar> {
    this.geometry?.dispose();
    this.material?.dispose();
    return Promise.resolve(this);
  }
}
