import { render } from "./App.vue?vue&type=template&id=b1583d42"
import script from "./App.vue?vue&type=script&lang=ts"
export * from "./App.vue?vue&type=script&lang=ts"

import "./App.vue?vue&type=style&index=0&id=b1583d42&lang=css"
script.render = render

export default script
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QToolbar,QToolbarTitle,QTabs,QTab,QBtn,QTooltip,QBtnDropdown,QList,QItem,QItemSection,QItemLabel,QPageContainer,QPopupEdit});qInstall(script, 'directives', {ClosePopup});
