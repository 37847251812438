import { Text } from "troika-three-text";
import { Object3D } from "three";
import { ILoadable } from "../core/Loadable";

export class RoomLabel extends Object3D implements ILoadable<RoomLabel> {
  public text: Text = new Text();
  constructor(public label: string) {
    super();
    this.text.text = this.label;
    this.text.fontSize = 0.3;
    this.text.color = 0x000000;
    this.text.outlineWidth = 0.01;
    this.text.anchorX = "center";
    this.text.anchorY = "middle";
    this.text.outlineColor = 0xffffff;
    this.text.outlineOpacity = 1;
    this.add(this.text);
  }

  load(): Promise<RoomLabel> {
    return new Promise((resolve) => {
      this.text.sync(() => resolve(this));
    });
  }
  unload(): Promise<RoomLabel> {
    this.text.dispose();
    return Promise.resolve(this);
  }
}
