import {
  EMPTY_MAP_SMALL,
  EMPTY_MAP_MEDIUM,
  EMPTY_MAP_LARGE,
} from "./EmptyMaps";
import { TEST_MAP } from "./TestMap";
import { WALLS_MAP } from "./WallsMap";
import { ISLAND_MAP } from "./IslandMap";
import { SMALL_OFFICE_WATER } from "./SmallLakeOffice";
import { STRESS_TEST_MAP, STRESS_TEST_MAP_SMALL } from "./StressTestMap";
import { SMALL_CONFERENCE_MAP } from "./SmallConference";

export const MAPS = [
  WALLS_MAP,
  TEST_MAP,
  ISLAND_MAP,
  SMALL_OFFICE_WATER,
  SMALL_CONFERENCE_MAP,
  EMPTY_MAP_SMALL,
  EMPTY_MAP_MEDIUM,
  EMPTY_MAP_LARGE,
  STRESS_TEST_MAP,
  STRESS_TEST_MAP_SMALL,
];
