
import PrefabThumbnail from "@/components/PrefabThumbnail.vue";
import Toolbox from "@/components/Toolbox.vue";
import OfficeView from "@/tabs/OfficeView.vue";
import PrefabEditor from "@/tabs/PrefabView.vue";
import SceneView from "@/tabs/SceneView.vue";
import { ISceneSerialized } from "@/welo/core/Document";
import { EditorTypes } from "@/welo/core/Editor";
import { ENVIRONMENT_MAPS, IEnvironmentMap } from "@/welo/core/EnvironmentMaps";
import { PieceTypes, PREFAB_TYPES, TILE_TYPES } from "@/welo/core/Prefabs";
import { Settings } from "@/welo/core/Settings";
import {
  EDITOR_DOCUMENT,
  OFFICE_DOCUMENT,
  SCENE_EDITOR,
} from "@/welo/core/Singletons";
import { FloodFillTool } from "@/welo/editor/tools/FloodFillTool";
import { PencilTool } from "@/welo/editor/tools/PencilTool";
import { PrefabTool } from "@/welo/editor/tools/PrefabTool";
import { MAPS } from "@/welo/maps";
import Dialog from 'quasar/src/plugins/Dialog.js';;
import { computed, defineComponent, provide, ref } from "vue";

/** Tools that require the palette to be shown */
const PALETTE_TOOLS = [PrefabTool.NAME, PencilTool.NAME, FloodFillTool.NAME];

export default defineComponent({
  name: "LayoutDefault",
  components: {
    OfficeView,
    SceneView,
    PrefabEditor,
    PrefabThumbnail,
    Toolbox,
  },
  methods: {
    setEnvironmentMap(envMap: IEnvironmentMap) {
      if (this.currentEnvMap === envMap.id) {
        return;
      }
      if (this.office) {
        this.office.setEnvironmentMap(envMap.id);
        this.currentEnvMap = envMap.id;
      }
    },
    setCurrentMap(map: ISceneSerialized) {
      if (this.viewMap.name === map.name) {
        return;
      }
      const finalizeChange = () => {
        this.editor.document.load(map).then(() => {
          this.editor.actions.clear();
          this.viewMap = map;
        });
      };
      if (!this.editor.actions.isDirty()) {
        return finalizeChange();
      }
      Dialog.create({
        title: "Discard Current Changes?",
        message:
          "Loading a new map now will discard your current changes and cannot be undone.",
        cancel: true,
        persistent: true,
      }).onOk(finalizeChange);
    },
    toggleShadows() {
      const old = this.currentShadows;
      const newValue = !old;
      this.currentShadows = newValue;
      this.office.setShadows(newValue);
    },
    setPrefabType(ofType: PieceTypes) {
      this.createPrefab = ofType;
      if (!this.activeTool || PALETTE_TOOLS.indexOf(this.activeTool) === -1) {
        this.activeTool = PrefabTool.NAME;
        this.editor.setActiveTool(PrefabTool.NAME);
      }
    },
  },
  beforeUnmount() {
    if (this.office) {
      this.office.renderer.dispose();
    }
    if (this.editor) {
      this.editor.document.renderer.dispose();
    }
  },
  setup() {
    const mapName = Settings.readSetting(Settings.ACTIVE_MAP, MAPS[0].name);
    let map: ISceneSerialized | undefined = MAPS.find(
      (m) => m.name === mapName
    );
    if (!map) {
      console.warn(`Couldn't find: ${mapName}. Using default: ${MAPS[0].name}`);
      map = MAPS[0];
    }
    const createPrefab = ref<PieceTypes>(PREFAB_TYPES[0]);
    EDITOR_DOCUMENT.load(map);
    const currentTab = ref<EditorTypes>(
      Settings.readSetting(Settings.ACTIVE_TAB, "office")
    );
    const currentEnvMap = ref(
      Settings.readSetting(Settings.ACTIVE_ENV_MAP, "")
    );
    const currentShadows = ref<boolean>(
      parseInt(Settings.readSetting(Settings.USE_SHADOWS, "0"), 10) > 0
    );
    const viewMap = ref<ISceneSerialized>(map);
    const drawerOpen = ref(false);
    const activeTool = ref(SCENE_EDITOR.getActiveToolName());
    provide("viewMap", viewMap);
    provide("drawerOpen", drawerOpen);
    provide("activeTool", activeTool);
    provide("office", OFFICE_DOCUMENT);
    provide("editor", SCENE_EDITOR);
    provide("createPrefab", createPrefab);
    provide("currentEnvMap", currentEnvMap);
    provide("currentShadows", currentShadows);
    const prefabTypes = computed(() => {
      if (currentTab.value === "scene") {
        if (activeTool.value === PrefabTool.NAME) {
          return PREFAB_TYPES;
        } else if (activeTool.value === PencilTool.NAME) {
          return TILE_TYPES;
        } else if (activeTool.value === FloodFillTool.NAME) {
          return TILE_TYPES;
        } else {
          return [];
        }
      }
      const result: any[] = [];
      return result.concat(PREFAB_TYPES, TILE_TYPES);
    });

    return {
      currentEnvMap,
      currentShadows,
      allMaps: MAPS,
      envMaps: ENVIRONMENT_MAPS,
      activeTool,
      editor: SCENE_EDITOR,
      office: OFFICE_DOCUMENT,
      viewMap,
      currentTab,
      drawerOpen,
      prefabTypes,
      createPrefab,
    };
  },
  watch: {
    currentTab() {
      if (this.currentTab === "office") {
        this.drawerOpen = false;
      } else if (this.currentTab === "prefab") {
        this.drawerOpen = true;
      }
      Settings.writeSetting(Settings.ACTIVE_TAB, this.currentTab);
    },
    viewMap() {
      Settings.writeSetting(Settings.ACTIVE_MAP, this.viewMap.name);
    },
    activeTool() {
      if (this.activeTool && PALETTE_TOOLS.indexOf(this.activeTool) !== -1) {
        this.drawerOpen = true;
      }
    },
    currentEnvMap() {
      Settings.writeSetting(Settings.ACTIVE_ENV_MAP, this.currentEnvMap);
    },
    currentShadows() {
      Settings.writeSetting(
        Settings.USE_SHADOWS,
        this.currentShadows ? "1" : "0"
      );
    },
  },
});
