export class Settings {
  static ACTIVE_TAB = "welo_ed_tab";
  static ACTIVE_MAP = "welo_ed_map";
  static ACTIVE_ENV_MAP = "welo_ed_env_map";
  static USE_SHADOWS = "welo_ed_shadows";

  /** Read a locally persistent setting */
  static readSetting<T = string>(key: string, defaultValue: T): T {
    return ((localStorage.getItem(key) as unknown) as T) || defaultValue;
  }
  /** Write a locally persistent setting */
  static writeSetting(key: string, value: string): void {
    return localStorage.setItem(key, value);
  }
}
