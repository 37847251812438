import { Object3D } from "three";
import { BaseDocument, ISceneSerialized } from "../core/Document";
import { OfficeDocument } from "../office/OfficeDocument";
import { Avatar } from "../objects/Avatar";
import { Seat } from "../objects/Seat";

function onLoad(doc: BaseDocument): Promise<void> {
  if (!(doc instanceof OfficeDocument)) {
    return Promise.resolve();
  }
  const seats: Seat[] = [];
  for (const piece of doc.pieces) {
    for (const seat of piece.seats.children) {
      if (seat instanceof Seat) {
        seats.push(seat);
      }
    }
  }
  return Promise.all(
    doc.config.avatars.children.map((a: Object3D) => {
      if (a instanceof Avatar) {
        a.unload();
      }
    })
  ).then(() => {
    doc.config.avatars.clear();
    return Promise.all(
      seats.map((seat) => {
        const av = new Avatar();
        seat.getWorldPosition(av.position);
        av.position.x;
        av.position.z;
        return av.load();
      })
    ).then((avatars: Avatar[]) => {
      doc.config.avatars.add(...avatars);
    });
  });
}

export const STRESS_TEST_MAP: ISceneSerialized = {
  name: "Stress Test Seats (32x32)",
  args: { backgroundColor: 15790335, gridSize: 32 },
  onLoad,
  rooms: [],
  pieces: [
    { prefab: "water_cooler", rotation: 0, x: 15, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: 15, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: 14, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: 13, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: 12, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: 11, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: 10, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: 9, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: -10, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: -11, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: -12, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: -13, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: -14, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: -16 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: -15, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: 15 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: 14 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: 13 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: 12 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: 11 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: 10 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: 9 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: -10 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: -11 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: -12 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: -13 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: -14 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: -15 },
    { prefab: "water_cooler", rotation: 0, x: -16, y: -16 },
  ],
};
export const STRESS_TEST_MAP_SMALL: ISceneSerialized = {
  name: "Stress Test Seats (18x18)",
  args: { backgroundColor: 15790335, gridSize: 18 },
  onLoad,
  rooms: [],
  pieces: [
    { prefab: "water_cooler", rotation: 0, x: 5, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 5, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 4, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 3, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 2, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 1, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 0, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -1, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -2, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -3, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -4, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -5, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -6, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -7, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -8, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: -9, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 6, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -9 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 7, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 8 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 7 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 6 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 5 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 4 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 3 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 2 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 1 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: 0 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -1 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -2 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -3 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -4 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -5 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -6 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -7 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -8 },
    { prefab: "water_cooler", rotation: 0, x: 8, y: -9 },
  ],
};
