import { ISceneSerialized } from "../core/Document";

export const EMPTY_MAP_SMALL: ISceneSerialized = {
  name: "Empty Map (12x12)",
  args: { gridSize: 12 },
  pieces: [],
  rooms: [],
};
export const EMPTY_MAP_MEDIUM: ISceneSerialized = {
  name: "Empty Map (18x18)",
  args: { gridSize: 18 },
  pieces: [],
  rooms: [],
};
export const EMPTY_MAP_LARGE: ISceneSerialized = {
  name: "Empty Map (32x32)",
  args: { gridSize: 32 },
  pieces: [],
  rooms: [],
};
